import React, { useContext } from "react";
import '../../../../css/StockOrderDetails.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { PendingBillContent } from "../store_order_pendingBill";

axios.defaults.baseURL = API;

function UpdateStoreProductOrder(props) {

    const {PurchaseOrderDetail, setPurchaseOrderDetail, errorMessage, setErrorMessage, setShowAlert} = useContext(PendingBillContent);

    function currencyFormat(num) {
        if (num !== "") {
            return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }

    async function updateStorePurchaseOrderProduct(StoreOrderDetailID, StoreOrderID, Quantity) {
        let token = localStorage.getItem("token");
        var data = {
            Quantity: Number(Quantity) + Number(1),
        }
        await axios.put(`/updateStorePurchaseOrderDetail/${StoreOrderDetailID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          getPendingBill(StoreOrderID);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function addOrder(StoreOrderDetailID, StoreOrderID, Quantity, ProductID, Barcode) {
        if(ProductID > 0) {
            await axios.get(`/checkInventory/${ProductID}`).then((response) => {
                let Data = response?.data;
                if(Data[0].Quantity > 0){
                    if(Data[0].Quantity >= Number(Quantity) + Number(1)){
                        updateStorePurchaseOrderProduct(StoreOrderDetailID, StoreOrderID, Quantity)
                    } else {
                        setErrorMessage("ຈຳນວນສິນຄ້າໃນສາງມີບໍ່ພຽງພໍ (ມີ: " + Data[0].Quantity +' )');
                        setShowAlert(true);
                    }  
                } else {
                    setErrorMessage("ຈຳນວນສິນຄ້າໃນສາງມີບໍ່ພຽງພໍ (ມີ: " + Data[0].Quantity +' )');
                    setShowAlert(true);
                }
            })
                .catch((error) => {
                console.log(error);
            });
        }
    }
    
    async function minusOrder(StoreOrderDetailID, StoreOrderID, Quantity) {
        let token = localStorage.getItem("token");
        if(Number(Quantity) - Number(1) >= 1){
            var data = {
                Quantity: Number(Quantity) - Number(1),
            }
            await axios.put(`/updateStorePurchaseOrderDetail/${StoreOrderDetailID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
              getPendingBill(StoreOrderID);
            }).catch((err) => {
                console.log(err);
            });
        } else {
            await axios.put(`/deleteStorePurchaseOrderDetail/${StoreOrderDetailID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
              getPendingBill(StoreOrderID);
            }).catch((err) => {
                console.log(err);
            });
        }
        
    };
    
    async function getPendingBill(StoreOrderID) {
        await axios
        .get(`/getStorePurchaseOrderProduct/${StoreOrderID}`)
        .then((response) => {
          let Data = response?.data;
          setPurchaseOrderDetail(Data);
        })
        .catch((error) => {
          console.log(error);
        });
      }

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="PurchaseOrderDetailForm">
            <div className="content">
                <div className="Header">
                    <span>ລາຍລະອຽດສິນຄ້າ</span>
                </div>
                <div className="Body">
                <div className="TempContent">
                    <table className="styled-table">
                        <thead>
                        <tr key={1}>
                            <th>ລ/ດ</th>
                            <th>Barcode</th>
                            <th>ປະເພດສິນຄ້າ</th>
                            <th>ຊື່ພາສາລາວ</th>
                            <th>ລາຄາຊື້</th>
                            <th>ຈຳນວນ</th>
                            <th>ລວມ</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            PurchaseOrderDetail && PurchaseOrderDetail.map((x, y) => {
                                return (
                                <tr key={y}>
                                    <td >{y + 1}</td>
                                    <td >{x.Barcode}</td>
                                    <td >{x.ProductType}</td>
                                    <td >{x.LaoName}</td>
                                    <td >{currencyFormat(x.BuyPrice)}</td>
                                    <td >{currencyFormat(x.Quantity)}</td>
                                    <td >{currencyFormat(Number(x.Quantity) * Number(x.BuyPrice))}</td>
                                    <td >
                                        <button className="btn btn-primary" type="button" onClick={() => addOrder(x.StoreOrderDetailID, x.StoreOrderID, x.Quantity, x.ProductID, x.Barcode)}>+</button>
                                        <button className="btn btn-danger" type="button" onClick={() => minusOrder(x.StoreOrderDetailID, x.StoreOrderID, x.Quantity)}>-</button>
                                    </td>
                                </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="footer">
                    <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                        ກັບຄືນ
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateStoreProductOrder;
