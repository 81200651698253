
function GiftCardValidate(values) {

    let errors = {};

    if (!values.GiftCardNO)
        errors.GiftCardNO = 'GiftCardNO is required';
    else if (values.CheckGiftCardNO !== "")
        errors.GiftCardNO = 'GiftCardNO Already Exit';

    if (!values.Price)
        errors.Price = 'Price is required';


    return errors;
};
export default GiftCardValidate;
