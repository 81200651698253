import React, { useState, useEffect, useRef, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../../css/form.css';
import '../../../../css/StockSaleProductTransactionReport.css';
import '../../../../css/datepicker.css';
import DatePicker from "react-datepicker";
import axios from "axios";
import { API } from "../../../../services/api";
import { useReactToPrint } from 'react-to-print';
import { UserContext } from "../../../../App";
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import moment from 'moment';

axios.defaults.baseURL = API;

class PrintDetailReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      const today = Date.now();
      let Quantity = 0;
      let SubTotal = 0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.StartedDate));
      const EndDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.EndedDate));
      return (
          <div className="SaleproductBillContent">
              <div className="ReportHeader">
                    <img className="profile-img" src={logo} />
                  <div className="ReportTitle">
                      <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>**********</span>
                      </div>
                      <div className="titleHeader3">
                          <span>ລາຍງານ ຂາຍເຄື່ອງສາງ</span>
                      </div>
                      
                  </div>
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                  <span className="CountNumber">NO.</span>
                      <span className="Barcode">ລະຫັດ</span>
                      <span className="LaoName">ຊື່ສິນຄ້າ</span>
                      <span className="ProductType">ປະເພດສິນຄ້າ</span>
                      <span className="Category">ໝວດສິນຄ້າ</span>
                      <span className="Brand">ຫຍີ່ຫໍ້ສິນຄ້າ</span>
                      <span className="Size">ຂະໜາດ</span>
                      <span className="BuyPrice">ລາຄາ</span>
                      <span className="Quantity">ຈຳນວນ</span>
                      <span className="SubTotal">ຍອດລວມ</span>
                      
                  </div>
                  <div className="HeadLine">
                    <div className="header-line"></div>
                  </div>
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                            Quantity =  Number(x.Quantity) +  Number(Quantity);
                            SubTotal =  Number(Number(x.Quantity) * Number(x.BuyPrice)) +  Number(SubTotal);
                            return (
                                <div className="Items">
                                    <div className="Detail">
                                        <div className="mds">
                                            <span className="NO">{(y + 1) }</span>
                                            <span className="Barcode">{x.Barcode}</span>
                                            <span className="LaoName">{x.LaoName}</span>
                                            <span className="ProductType">{x.ProductType}</span>
                                            <span className="Category">{x.Category}</span>
                                            <span className="Brand">{x.Brand}</span>
                                            <span className="Size">{x.Size}</span>
                                            <span className="BuyPrice">{currencyFormat(x.BuyPrice)}</span>
                                            <span className="Quantity">{currencyFormat(x.Quantity)}</span>
                                            <span className="SubTotal">{currencyFormat(Number(x.Quantity) * Number(x.BuyPrice))}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                      }
                  </div>
                   <div className="HeadLine">
                   <div className="header-line"></div>
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="Quantity">{currencyFormat(Quantity)}</span>
                      <span className="SubTotal">{currencyFormat(SubTotal)}</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ກວດກາ</span>
                      <span className="StockAdmin"></span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}

class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      function getDate(gdate) {
        const today = gdate;
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
      }
    
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      let SubTotal = 0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.StartedDate));
      const EndDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.EndedDate));
      return (
          <div className="StockSaleProductReportContent">
              <div className="ReportHeader">
                    <img className="profile-img" src={logo} />
                  <div className="ReportTitle">
                      <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>**********</span>
                      </div>
                      <div className="titleHeader3">
                          <span>ລາຍງານ ສະຫລຸບການຂາຍ</span>
                      </div>
                      
                  </div>
                  <div className="PrintDate">
                      <span>ແຕ່ວັນທີ # {StartDate}</span>
                  </div>
                  <div className="PrintDate">
                      <span>ຫາວັນທີ: {EndDate}</span> 
                  </div>
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                  <span className="CountNumber">NO.</span>
                      <span className="BillNo">ເລກບິນ</span>
                      <span className="PaymentType">ປະເພດຈ່າຍ</span>
                      <span className="SubTotal">ຍອດລວມ</span>
                      <span className="CreatedDate">ມື້ສ້າງ</span>
                  </div>
                  <div className="HeadLine">
                    <div className="footer-line"/>
                  </div>
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                            SubTotal = Number(x.SubTotal) +  Number(SubTotal);
                            return (
                                <div className="Items">
                                    <div className="Detail">
                                        <div className="mds">
                                            <span className="NO">{(y + 1) }</span>
                                            <span className="BillNo">{x.SaleProductID}</span>
                                            <span className="PaymentType">{x.PaymentType}</span>
                                            <span className="SubTotal">{currencyFormat(x.SubTotal)}</span>
                                            <span className="CreatedDate">{moment(x.CreatedDate).format('DD/MM/YYYY')}</span>
                                            
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                      }
                  </div>
                   <div className="HeadLine">
                    <div className="footer-line"></div>
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="SubTotal">{currencyFormat(SubTotal)}</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ກວດກາ</span>
                      <span className="StockAdmin"></span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}


function StockSaleProductReport() {
  function getDate(gdate) {
    const today = gdate;
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    return `${year}-${month}-${date} ${time}`;
  }
  const {LocationID, LocationName} = useContext(UserContext);
  const [StartedDate, setStartedDate] = useState(null); 
  const [EndedDate, setEndedDate] = useState(null); 
  const componentRef = useRef(); 
  const componentRefPrinDatail = useRef(); 
  const [DataPrint, setDataPrint] = useState([]);
  const [BillNO, setBillNO] = useState([]);

  const [showData, setShowData] = useState([]);

  const filterPassedTime = time => {
    const selectedDate = new Date(time);
    return selectedDate.getTime();
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintDetail = useReactToPrint({
    content: () => componentRefPrinDatail.current,
  });

  const handleChangeStartedDate = event => {
    if (event)
        setStartedDate(event);
    else 
        setStartedDate(null);
}

  const handleChangeEndedDate = event => {
      if (event)
          setEndedDate(event);
      else 
          setEndedDate(null);
  }

  async function SearchTransaction() {
      var StatusID = 1;
      await axios.get(`/getSaleProductBillByDate/${getDate(StartedDate)}/${getDate(EndedDate)}/${StatusID}`).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
  };

  async function PrintSaleReport(SaleProductID) {
    var StatusID = 1;
    if(SaleProductID){
      setBillNO(SaleProductID);
      await axios.get(`/getSaleProductBillDetail/${StatusID}/${SaleProductID}`).then((response) => {
          let Data = response?.data;
          setDataPrint(Data);
          handlePrintDetail();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setDataPrint([]);
      setBillNO([]);
    }
  }

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ລາຍງານບິນຂາຍ</span>
          </div>
          <div className="txtSearch">
            <div className="StartedDate">
                <label>ແຕ່ວັນທີ: </label>
                <DatePicker className="date"
                    selected={StartedDate}
                    onChange={(e) => handleChangeStartedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="EndedDate">
                <label >ຫາວັນທີ: </label>
                <DatePicker className="date"
                    selected={EndedDate}
                    onChange={(e) => handleChangeEndedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <button className="btnSearch btn btn-primary" onClick={() => SearchTransaction()}>
                ຄົ້ນຫາ
            </button>
            <button className="btnSearch btn btn-success" onClick={() => handlePrint()}>
                ພິມລາຍງານບິນ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
              <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ປະເພດສິນຄ້າ</th>
                <th>ຍອດລວມ</th>
                <th>ມື້ສ້າງ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                      <td >{y + 1}</td>
                      <td >{x.SaleProductID}</td>
                      <td >{x.PaymentType}</td>
                      <td >{x.SubTotal}</td>
                      <td >{x.CreatedDate}</td>
                      <td >
                          <button className="btn btn-warning" type="button" onClick={() => PrintSaleReport(x.SaleProductID)}>ລາຍລະອຽດບິນ</button>
                        </td>
                    </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
                    BillDetails={{"StartedDate": StartedDate, "EndedDate": EndedDate }} 
            />
            <PrintDetailReport ref={componentRefPrinDatail}
                    TemProductList={DataPrint}
                    BillDetails={{"LocationName": LocationName, "BillNO":BillNO}} 
            />
        </div>
      </div>
  );
};

export default StockSaleProductReport;
