import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateSubMenu from "./create/createSubMenu";
import UpdateMenu from "./update/updateMenu";
import Alert from "../../../components/alert";
import { AlertContext } from "../../../App";
import axios from "axios";
import { API } from "../../../services/api";

axios.defaults.baseURL = API;
const subMenuContext = createContext();

function SubMenu() {

  const {setShowAlert, setErrorMessage} = useContext(AlertContext);

  const [SubMenuList, setSubMenuList] = useState([]);
  const [MenuList, setMenuList] = useState([]);
  const [showData, setShowData] = useState([]);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { value } = event.target;
    if(value){
      var results = [];
      results = SubMenuList.filter((data) => {
          return data.ProductType.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
      setShowData(results);
      else 
      setShowData(SubMenuList);
    } else 
    setShowData(SubMenuList);
  }


  async function CancleMenu (SubMenuID, ProductTypeID, CategoryID) {
    let token = localStorage.getItem("token");
    var data = {
        CategoryID: CategoryID,
        ProductTypeID: ProductTypeID,
        StatusID: 2
    }
    await axios.put(`/UpdateSubMenu/${SubMenuID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      getSubMenu();
    }).catch((err) => {
        console.log(err);
    });
  };

  async function getSubMenu() {
    axios
      .get("/getsubmenu")
      .then((response) => {
        let Data = response?.data;
        setSubMenuList(Data);
        setShowData(Data);
      })
      .catch((error) => {
        console.log(error);
    });
  }

  async function getMenu() {
    axios
      .get("/getmenu")
      .then((response) => {
        let Data = response?.data;
        setMenuList(Data);
      })
      .catch((error) => {
        console.log(error);
    });
  }
  
  useEffect(() => {
    getMenu();
    getSubMenu();
  }, []);

  return (
    <subMenuContext.Provider value={{MenuList, setMenuList, SubMenuList, setSubMenuList, setShowData, setShowAlert, setErrorMessage, data}}>
      <div className="formContent">
        <CreateSubMenu show={show} onClick={handleClose}/>
        <Alert/> 
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ເມນູຍ່ອຍ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເມນູ</th>
                <th>ເມນູຍ່ອຍ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.ProductType}</td>
                        <td >{x.Category}</td>
                        <td >
                          {/* <button className="btn btn-secondary" value={x.SubMenuID} type="button" onClick={() => openUpdate(x.SubMenuID, x.ProductType, x.ProductTypeID, x.CategoryID, x.Category)}>ແກ້ໄຂ</button>
                          &#160; */}
                          <button className="btn btn-danger" value={x.SubMenuID} type="button" onClick={() => CancleMenu(x.SubMenuID, x.ProductTypeID, x.CategoryID)}>ຍົກເລີກ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </subMenuContext.Provider>
  );
};

export {subMenuContext};
export default SubMenu;
