
function UpdateOrderValidate(values) {

    let errors = {};

    if (!values.Quantity)
        errors.Quantity = 'Quantity is required';

    return errors;
};

export default UpdateOrderValidate;
