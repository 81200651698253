function loginValidate(values) {
    let errors = {};
    if (!values.UserName)
        errors.UserName = 'UserName is required';

    if (!values.Password) 
        errors.Password = 'Password is required';
    else if (values.Password.length < 6) 
        errors.Password = 'Password must be 6 or more characters';

    return errors;
};

export default loginValidate;