import React, { useContext, useEffect, useState } from "react";
import useForm from "../../../services/UseForm";
import validate from '../../../validations/admin/pos/registertableValidate';
import '../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../services/api";
import { POSContent } from "./pos";
import { UserContext } from "../../../App";

axios.defaults.baseURL = API;

function Selectserver(props) {

    const { TableID, show, LocationID, setShow, setTableList, setShowOrder, setServerName} = useContext(POSContent);
    const {userID} = useContext(UserContext);
    const [userList, setUserList] = useState([]);

    const handleSelectUserName = (e) => {
        var data = userList.filter(function (item) {
            return item.Name == e.target.value
        })
        if (data.length > 0) {
            handleChange("UserName", data[0].Name);
            handleChange("EmployeeID", data[0].EmployeeID);
        } else {
            handleChange("UserName", "");
            handleChange("EmployeeID", "");
        }
    };

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            CreateByID: values.EmployeeID,
            ServerID: values.EmployeeID,
            StatusID: 2
        }
        await axios.put(`/UpdateTableForSelectServer/${TableID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            setServerName(values.UserName);
            getAllTableNumber();
            ClearData();
            setShowOrder(true);
            setShow(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllTableNumber() {
        let token = localStorage.getItem("token");
        await axios.get(`/getAlltableNumberForPos/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setTableList(temOrder);
          } else {
            setTableList([]);
          }
        }).catch((err) => {
            setTableList([]);
            console.log(err);
        });
    }

    const ClearData = () => {
        handleChange("UserNameID", "");
        handleChange("UserName", "");
    }

    async function getUserByLocationID() {
        let token = localStorage.getItem("token");
        await axios.get(`/getUserByLocationID/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setUserList(temOrder);
          } else {
            setUserList([]);
          }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getUserByLocationID();
    }, []);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ເລືອກພະນັກງານເສີບ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <label>ພະນັກງານ: </label>
                                <select name="EmployeeID" onChange={(e) => handleSelectUserName(e)}
                                    className={`input form-control ${errors.EmployeeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກພະນັກງານ'}
                                    </option>
                                    {   userList && userList.map(item => (
                                        <option
                                            key={item.EmployeeID}
                                            value={item.Name}
                                        >
                                            {item.Name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => setShow(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Selectserver;
