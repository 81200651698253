import React, { useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/product/promoItemValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { ProductContext } from "../promotionItemInfo";

axios.defaults.baseURL = API;

function CreatePromoItem(props) {

    const {ProductTypeList, setProductList, setShowData, showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(ProductContext);
    const [ProductID, setProductID] = useState([]);
    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        if(name === "Barcode")
            SearchProductByBarcode(value);
    };

      async function SearchProductByBarcode (value) {
        let token = localStorage.getItem("token");
        if(value){
            var data = {
                Barcode: value
            }
            await axios.post("/getProductByBarcodeForPromoItem", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                let productData = response?.data;
                if(productData.length > 0){
                    for (var i=0; i < productData.length; i++) {
                        handleChange("LaoName", productData[i].LaoName);
                        handleChange("EngName", productData[i].EngName);
                        handleChange("CheckBarcode", "");
                        setProductID(productData[i].ProductID);
                    } 
                } else {
                    handleChange("CheckBarcode", value);
                    handleChange("LaoName", "");
                    handleChange("EngName", "");
                    setProductID();
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            handleChange("CheckBarcode", "");
            handleChange("LaoName", "");
            handleChange("EngName", "");
            setProductID();
        }
    }


    const handelInputNumber = (e) => {
        const { name, value } = e.target;
          const re = /^[0-9\b]+$/;
          if (value) {
              if (value === '' || re.test(value.split(',').join(''))) {
                  handleChange(name, value.split(',').join(''));
              }  
          } else {
              handleChange(name, 0);
          }
    }

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            ProductID: ProductID,
            Point: values.Point,
        }
        await axios.post("/CreatePromoItemInfo", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllProductLimitTop10();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllProductLimitTop10() {
        axios.get("/getAllPromoItem").then((response) => {
          let Data = response?.data;
          setProductList(Data);
          setShowData(Data);
        }).catch((error) => {
          console.log(error);
        });
      }

    const ClearData = () => {
        handleChange("Barcode", "");
        handleChange("LaoName", "");
        handleChange("EngName", "");
        handleChange("Point", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງສິນຄ້າ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>Barcode</span>
                                <input className={`input form-control ${errors.Barcode && 'is-invalid'}`} type="text" name="Barcode" onChange={(e) => handleInputChange(e)} value={values.Barcode || ''} required />
                                {errors.Barcode && (
                                    <p className="invalid-feedback">{errors.Barcode}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ຊື່ພາສາລາວ</span>
                                <input className={`input form-control ${errors.LaoName && 'is-invalid'}`} type="text" name="LaoName" onChange={(e) => handleInputChange(e)} value={values.LaoName || ''} disabled required />
                                {errors.LaoName && (
                                    <p className="invalid-feedback">{errors.LaoName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ຊື່ພາສາອັງກິດ</span>
                                <input className={`input form-control ${errors.EngName && 'is-invalid'}`} type="text" name="EngName" onChange={(e) => handleInputChange(e)} value={values.EngName || ''} disabled required />
                                {errors.EngName && (
                                    <p className="invalid-feedback">{errors.EngName}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>Point</span>
                                <input className={`input form-control ${errors.Point && 'is-invalid'}`} type="text" name="Point" onChange={(e) => handelInputNumber(e)} value={values.Point || ''} required />
                                {errors.BuyPrice && (
                                    <p className="invalid-feedback">{errors.Point}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreatePromoItem;
