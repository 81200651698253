import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import useForm from "../../../services/UseForm";
import '../../../css/CreateIngredient.css';
import axios from "axios";
import Alert from "../../../components/alert";
import { API } from "../../../services/api";
import { AlertContext } from "../../../App";
axios.defaults.baseURL = API;

function CreateIngredient() {
    const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);
    const [ServiceList, setServiceList] = useState([]);
    const [TempOrder, setTempOrder] = useState([]);
    const [PackageList, setPackageList] = useState([]);

    function currencyFormat(num) {
        if (num !== "" && num > 0) {
            return Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }
    
    const handleSelectPackage = (e) => {
        const { value } = e.target;
        setTempOrder([]);
        if(value){
            handleChange("PackageID", value);
            handleChange("PackagePrice", e.target.children[e.target.selectedIndex].getAttribute('data-id'));
            getCreateIngredientByPackageID(value);
        } else {
            handleChange("PackageName", "");
            handleChange("PackageID", "");
            handleChange("PackagePrice", 0);
            setTempOrder([]);
            localStorage.removeItem('tempServiceForPackage');
            getSubtotal();
        }
    };

    async function addOrder(ServiceID, ServiceNameLao, ServicePrice, hours) {
        var c =0;
        for (var i=0; i < TempOrder.length; i++) {
            if (TempOrder[i].ServiceID == ServiceID) {
                if(Number(TempOrder[i].Hours) + Number(hours) > 0)
                {
                    TempOrder[i].Hours = Number(TempOrder[i].Hours) + Number(hours);
                } else {
                    TempOrder.splice(i, 1);
                }
                c = 1;
                break;
            }
        }  
        if(c == 0){
            var datalist = {
                ServiceID: ServiceID,
                ServiceNameLao: ServiceNameLao,
                ServicePrice: ServicePrice,
                Hours: hours,
            }
            TempOrder.push(datalist);
        }
        localStorage.setItem("tempServiceForPackage", JSON.stringify(TempOrder));
        getSubtotal();
    }

    const getSubtotal = () => {
        let userData = localStorage.getItem("tempServiceForPackage");
        if (userData) {
          let userRole = JSON.parse(userData);
          setTempOrder(userRole);
        } else{
            setTempOrder([]);
        }
    }

    async function CreateCreateIngredients() {
        
        if(values.PackageID > 0 && TempOrder.length > 0)
        {
            let token = localStorage.getItem("token");
            if(TempOrder.length > 0) {
                for (var i=0; i < TempOrder.length; i++) {
                    var list;
                    list ={
                        PackageID: values.PackageID,
                        ServiceID: TempOrder[i].ServiceID,
                        Hours: TempOrder[i].Hours,
                    };
                    await axios.post("/CreateCreateIngredients", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                        setTempOrder([]);
                    }).catch((err) => {
                        console.log(err);
                    });
                } 
            }
        } else {
            setErrorMessage("ກະລຸນາເລືອກ Package ຫລື ເລືອກລາຍການນວດກ່ອນ");
            setShowAlert(true);
        }
    };

    async function getAllPackage() {
        axios
          .get("/getAllPackage")
          .then((response) => {
            let Data = response?.data;
            setPackageList(Data);
          })
          .catch((error) => {
            console.log(error);
          });
    }

    async function getCreateIngredientByPackageID(value) {
        console.log(value)
        let token = localStorage.getItem("token");
        await axios.get(`/getCreateIngredientByPackageID/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let tem = response?.data;
            for (var i=0; i < TempOrder.length; i++) {
                TempOrder.splice(i, 1);
            }  
          if(tem.length > 0){
                for (var i=0; i < tem.length; i++) {
                    var datalist = {
                        ServiceID: tem[i].ServiceID,
                        ServiceNameLao: tem[i].ServiceNameLao,
                        ServicePrice: tem[i].ServicePrice,
                        Hours: tem[i].Hours,
                    }
                    TempOrder.push(datalist);
                }  
                localStorage.setItem("tempServiceForPackage", JSON.stringify(TempOrder));
                getSubtotal();
            }
        }).catch((err) => {
            setTempOrder([]);
            localStorage.removeItem("tempServiceForPackage");
            getSubtotal();
            console.log(err);
        });
    }

    async function getAllServiceLimitTop10() {
        axios.get("/getAllServiceLimit10").then((response) => {
          let Data = response?.data;
          setServiceList(Data);
        }).catch((error) => {
          console.log(error);
        });
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm();

  useEffect(() => {
    // getAllServiceLimitTop10();
    // getAllPackage();
  }, []);

  return (
      <div className="CreateIngredientForm">
        <Alert/> 
        <div className="CreateIngredientHeader">
          <div className="title">
              <span>ສ້າງລາຍລະອຽດສ່ວນພະສົມ</span>
          </div>
          <div className="headersearch">
            <div className="Package">
                <select name="PackageID" onChange={(e) => handleSelectPackage(e)}
                    className={`input form-control ${errors.PackageID && 'is-invalid'}`}>
                    <option
                        key={""}
                        value={""}
                    >
                        {'ເລືອກ Package'}
                    </option>
                    {   PackageList && PackageList.map(item => (
                        <option
                            key={item.PackageID}
                            data-id={item.PackagePrice}
                            value={item.PackageID}
                        >
                            {item.PackageName}
                        </option>
                    ))}
                </select>
            </div>
            <div className="Price">
                <p className="name">ລາຄາ Package: {currencyFormat(values.PackagePrice) || 0} </p>
            </div>
            <button className="btn btn-primary btnSave" onClick={(e) => CreateCreateIngredients(e)} >ບັນທຶກ</button>
          </div>
        </div>
        <div className="CreateIngredientContent">
          <div className="CreateIngredientLeft">
            <div className="serviceDetail">
                {
                    ServiceList && ServiceList.map((x, y) => {
                        return (
                            <div key={y} className="serviceitem" onClick={() => addOrder(x.ServiceID, x.ServiceNameLao, x.ServicePrice, 1)}>
                                    <p>{x.ServiceNameLao}</p>
                                    <p className="price">{currencyFormat(x.ServicePrice)}</p>
                            </div>
                        )
                    })
                }
            </div>
          </div>
            <div className="CreateIngredientRight">
                <div className="serviceCreateIngredient">
                    {
                        TempOrder && TempOrder.map((x, y) => {
                            return (
                                <div key={y} className="orderContent">
                                    <div className="orderFooter">
                                        <div className="price">
                                            <p>{currencyFormat(x.ServicePrice)}</p>
                                        </div>
                                        <div className="plus" onClick={() => addOrder(x.ServiceID, x.ServiceNameLao, x.ServicePrice, 0.5)}>
                                            +
                                        </div>
                                        <div className="quantity">
                                            <p>{currencyFormat(x.Hours)}</p>
                                        </div>
                                        <div className="minus" onClick={() => addOrder(x.ServiceID, x.ServiceNameLao, x.ServicePrice, -0.5)}>
                                            -
                                        </div>
                                    </div>
                                    <div className="orderBody">
                                        <div className="detail">
                                            <div className="productName">
                                                <span>{y+1}. {x.ServiceNameLao }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
      </div>
  );
};

export default CreateIngredient;
