
function BrandValidate(values) {

    let errors = {};

    if (!values.Brand)
        errors.Brand = 'Brand is required';
    else if (values.CheckBrand !== "")
        errors.Brand = 'Brand Already Exit';

    if (!values.CategoryID)
        errors.CategoryID = 'CategoryID is required';

    if (!values.ProductTypeID)
        errors.ProductTypeID = 'ProductTypeID is required';

    return errors;
};

export default BrandValidate;
