
function ProductValidate(values) {

    let errors = {};

    if (!values.Barcode)
        errors.Barcode = 'Barcode is required';
    else if (values.CheckBarcode !== "")
        errors.Barcode = 'Barcode Already Exit';

    if (!values.LaoName)
        errors.LaoName = 'LaoName is required';

    if (!values.EngName)
        errors.EngName = 'EngName is required';

    if (!values.BuyPrice)
        errors.BuyPrice = 'BuyPrice is required';

    if (!values.Retail_Price)
        errors.Retail_Price = 'Retail_Price is required';

    if (!values.Wholesale_Price)
        errors.Wholesale_Price = 'Wholesale_Price is required';

    if (!values.Size)
        errors.Size = 'Size is required';

    if (!values.CategoryID)
        errors.CategoryID = 'CategoryID is required';

    if (!values.ProductTypeID)
        errors.ProductTypeID = 'ProductTypeID is required';

    if (!values.BrandID)
        errors.BrandID = 'BrandID is required';

    if (!values.SizeTypeID)
        errors.SizeTypeID = 'SizeTypeID is required';

    return errors;
};

export default ProductValidate;
