
function CreatePurchaseOrderValidate(values) {

    let errors = {};

    if (!values.PaymentTypeID)
        errors.PaymentTypeID = 'PaymentTypeID is required';

    if (!values.SupplierID)
        errors.SupplierID = 'SupplierID is required';

    return errors;
};

export default CreatePurchaseOrderValidate;
