
function SizeTypeValidate(values) {

    let errors = {};
    if (!values.SizeType)
        errors.SizeType = 'SizeType is required';
    else if (values.CheckSizeType != "")
        errors.SizeType = 'SizeType Already Exit';

    return errors;
};

export default SizeTypeValidate;
