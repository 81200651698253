import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";

axios.defaults.baseURL = API;

function CancelBill() {

  const {userID, LocationID, UserName, LocationName} = useContext(UserContext);
  const [showData, setShowData] = useState([]);
  const [NetTotal, setNetTotal] = useState(0);
  const [PaidAmount, setPaidAmount] = useState(0);
  const [MemberNewAmount, setMemberNewAmount] = useState(0);
  const [MemberNewPoint, setMemberNewPoint] = useState(0);

  async function HandleCancelBill (TransactionID) {
    let token = localStorage.getItem("token");
    await axios.put(`/cancelBill/${TransactionID}/${userID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      getMemberIDFromTransaction(TransactionID);  
      getTransactionDetail(TransactionID);
        ClearData();
    }).catch((err) => {
        console.log(err);
    });
  }

  async function getMemberIDFromTransaction (TransactionID) {
    await axios.get(`/getMemberIDFromTransaction/${TransactionID}`).then((response) => {
      let Data = response?.data;
      if(Data.length > 0){
        if(Data[0].MemberID){
          setNetTotal(Data[0].NetTotal);
          setPaidAmount(Data[0].PaidAmount);
          getMemberByMemberID( Data[0].MemberID);
        } else {
          setNetTotal(0);
        }
      } 
    }).catch((error) => {
      console.log(error);
    });
  }

  async function getMemberByMemberID(MemberID) {
    let token = localStorage.getItem("token");
    await axios.get(`/getMemberByMemberID/${MemberID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let memberData = response?.data;
        if(memberData.length > 0){
            CalculatePoint(memberData[0].Point, memberData[0].Amount, MemberID);
        } else {
          setMemberNewAmount(0);
          setMemberNewPoint(0);
        }
    }).catch((err) => {
        console.log(err);
    });
};

const CalculatePoint = (Point, Amount, MemberID) => {
  if(NetTotal >= 5000){
    var gPoint = ((Number(NetTotal) / Number(5000)) * 2).toFixed(0);
    setMemberNewPoint(Number(Point) - Number(gPoint));
    setMemberNewAmount(Number(Amount) + Number(PaidAmount));
    UpdateMember(MemberID);
  } else {
    setMemberNewPoint(Point);
    setMemberNewAmount(Amount);
  }
}


async function UpdateMember(MemberID) {
  let token = localStorage.getItem("token");
  var data = {
      MemberID: MemberID,
      Amount: MemberNewAmount,
      Point: MemberNewPoint,
  }
  await axios.post("/usemember", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
  }).catch((err) => {
      console.log(err);
  });
};


  async function getTransactionDetail (TransactionID) {
    await axios.get(`/getTransactionDetail/${TransactionID}`).then((response) => {
      let Data = response?.data;
      if(Data.length > 0){
        for (var i=0; i < Data.length; i++) {
          getStoreInventory(Data[i].ProductID, Data[i].Quantity);
        } 
      } 
    }).catch((error) => {
      console.log(error);
    });
  }

  async function getStoreInventory (ProductID, Quantity) {
    let token = localStorage.getItem("token");
    var data = {
        ProductID: ProductID,
        LocationID: LocationID,
    }
    await axios.post("/getStoreInventory",data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let inventoryData = response?.data;
        var gQuantity = 0;
        if(inventoryData.length > 0){
            gQuantity = Number(inventoryData[0].Quantity) + Number(Quantity);
            PlusInventory(inventoryData[0].StoreInventoryID, gQuantity);
        } 
    }).catch((err) => {
        console.log(err);
    });
}

async function PlusInventory (StoreInventoryID, Quantity) {
    let token = localStorage.getItem("token");
    var data = {
        StoreInventoryID: StoreInventoryID,
        Quantity: Quantity,
    }
    await axios.post("/PosUpdateStoreInventory", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
    }).catch((err) => {
        console.log(err);
    });
}

  const ClearData = () => {
    setShowData([]);
  }

  async function SearchTransaction(e) {
    const { value } = e.target;
    if(value){
      await axios.get(`/SearchTransaction/${value}`).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
    } else {
      setShowData([]);
    }
  };

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ຍົກເລີກບິນ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາເລກບິນ" onChange={(e) => SearchTransaction(e)} />
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ຍອດລວມ</th>
                <th>ສ່ວນຫລຸດ</th>
                <th>ຍອດທີ່ຕ້ອງຈ່າຍ</th>
                <th>ຍອດຈ່າຍ</th>
                <th>ຈ່າຍເພີ່ມ</th>
                <th>ປະເພດຈ່າຍ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.TransactionID}</td>
                        <td >{x.SubTotal}</td>
                        <td >{x.Discount}</td>
                        <td >{x.NetTotal}</td>
                        <td >{x.PaidAmount}</td>
                        <td >{x.PaidMoreAmount}</td>
                        <td >{x.PaymentType}</td>
                        <td >
                          <button className="btn btn-danger" value={x.TransactionID} type="button" onClick={() => HandleCancelBill(x.TransactionID,)}>ຍົກເລີກບິນ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default CancelBill;
