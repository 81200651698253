
function PromoItemValidate(values) {

    let errors = {};

    if (!values.Barcode)
        errors.Barcode = 'Barcode is required';
    else if (values.CheckBarcode !== "")
        errors.Barcode = 'Barcode Already Exit';

    if (!values.LaoName)
        errors.LaoName = 'LaoName is required';

    if (!values.EngName)
        errors.EngName = 'EngName is required';

    if (!values.Point)
        errors.Point = 'Point is required';

    return errors;
};

export default PromoItemValidate;
