
function statusValidate(values) {

    let errors = {};
    if (!values.Status)
        errors.Status = 'Status is required';
    else if (values.CheckStatus != "")
        errors.Status = 'Status Already Exit';

    return errors;
};

export default statusValidate;
