
function PaymentTypeValidate(values) {

    let errors = {};
    if (!values.PaymentTypeID)
        errors.PaymentTypeID = 'PaymentTypeID is required';

    return errors;
};

export default PaymentTypeValidate;
