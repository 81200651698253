import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/stock/createPurchaseOrderValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { PurchaseOrderContext } from "../purchaseOrder";
import { UserContext } from "../../../../App";

axios.defaults.baseURL = API;

function CreatePurchaseOrder(props) {
    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
    }

    const {showData, setShowData, SupplierList, PaymentTypeList} = useContext(PurchaseOrderContext);
    const {userID, LocationID} = useContext(UserContext);

    const handleSelectSuppler = (e) => {
        var data = SupplierList.filter(function (item) {
            return item.SupplierName == e.target.value
        })
        if (data.length > 0) {
            handleChange("SupplierName", data[0].SupplierName);
            handleChange("SupplierID", data[0].SupplierID);
        } else {
            handleChange("SupplierName", "");
            handleChange("SupplierID", "");
        }
    };

    const handleSelectPaymentType = (e) => {
        var data = PaymentTypeList.filter(function (item) {
            return item.PaymentType == e.target.value
        })
        if (data.length > 0) {
            handleChange("PaymentType", data[0].PaymentType);
            handleChange("PaymentTypeID", data[0].PaymentTypeID);
        } else {
            handleChange("PaymentType", "");
            handleChange("PaymentTypeID", "");
        }
    };

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            PaymentTypeID: values.PaymentTypeID,
            SupplierID: values.SupplierID,
            CreatedDate: getDate(),
            CreatedByID: userID,
            StatusID: 2,
            LocationID: LocationID,
        }
        await axios.post("/createPurchaseOrder", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            CreatePurchaseOrderDetail(response?.data?.insertId);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function CreatePurchaseOrderDetail(PurchaseOrderID) {
        let token = localStorage.getItem("token");
        if(showData.length > 0) {
            for (var i=0; i < showData.length; i++) {
                var list;
                list ={
                    PurchaseOrderID: PurchaseOrderID,
                    ProductID: showData[i].ProductID,
                    Quantity: showData[i].Quantity,
                    BuyPrice: showData[i].BuyPrice,
                    SubTotal: showData[i].SubTotal,
                };
                await axios.post("/createPurchaseOrderDetails", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                }).catch((err) => {
                    console.log(err);
                });
            } 
            ClearData();
            props.onClick(false);
        }
    }

    const ClearData = () => {
        localStorage.removeItem('purchaseOrderData');
        handleChange("PaymentType", "");
        handleChange("PaymentTypeID", "");
        handleChange("SupplierName", "");
        handleChange("SupplierID", "");
        setShowData([]);
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງໃບສັ່ງຊື້</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <label>ຜູ້ສະໜອງ: </label>
                                <select name="SupplierID" onChange={(e) => handleSelectSuppler(e)}
                                    className={`input form-control ${errors.SupplierID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກຜູ້ສະໜອງ'}
                                    </option>
                                    {   SupplierList && SupplierList.map(item => (
                                        <option
                                            key={item.SupplierID}
                                            value={item.SupplierName}
                                        >
                                            {item.SupplierName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ປະເພດຈ່າຍ: </label>
                                <select name="PaymentTypeID" onChange={(e) => handleSelectPaymentType(e)}
                                    className={`input form-control ${errors.PaymentTypeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກໝວດສິນຄ້າ'}
                                    </option>
                                    {   PaymentTypeList && PaymentTypeList.map(item => (
                                        <option
                                            key={item.PaymentTypeID}
                                            value={item.PaymentType}
                                        >
                                            {item.PaymentType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreatePurchaseOrder;
