import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import Alert from "../../../components/alert";
import TempTakeOutProductByBarcode from "./create/tempTakeOutProductByBarcode";
import TempTakeOutProductByName from "./create/tempTakeOutProductByName";
import CreateStoreTakeOutProduct from "./create/createStoreTakeOutProduct";
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext, AlertContext } from "../../../App";

const StoreTakeOutProductContext = createContext();
axios.defaults.baseURL = API;

function StoreTakeOutProduct() {

  const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);

  const {userID, LocationID} = useContext(UserContext);

  const [dataList, setDataList] = useState([]);
  const [showData, setShowData] = useState([]);
  const [ShowByName, setShowByName] = useState(false);
  const [TakOutStatusList, setTakeOutStatusList] = useState([]);
  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [SubTotal, setSubTotal] = useState();
  const handleCloseCreate = () => setShowCreate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowByName = () => setShowByName(true);
  const handleCloseByName = () => setShowByName(false);

  function currencyFormat(num) {
    if (num !== "") {
        return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  }

  const minusOrder = (Barcode) => {
    for (var i=0; i < showData.length; i++) {
      if (showData[i].Barcode == Barcode) {
          if(Number(showData[i].Quantity) - 1 > 0)
          {
            showData[i].Quantity = Number(showData[i].Quantity) - 1;
            showData[i].SubTotal = Number(showData[i].Quantity) * Number(showData[i].BuyPrice);
            setSubTotal(Number(showData[i].Quantity) * Number(showData[i].BuyPrice));
            localStorage.setItem("storeTakeOutProductData", JSON.stringify(showData));
          } else {
            showData.splice(i, 1);
            localStorage.setItem("storeTakeOutProductData", JSON.stringify(showData));
          }
          break;
      }
    }  
    setOrderList();
  }

  async function addOrder(ProductID, Barcode) {
    if(ProductID > 0) {
        await axios.get(`/checkInventory/${ProductID}`).then((response) => {
            let Data = response?.data;
            if(Data[0].Quantity > 0){
                for (var i=0; i < showData.length; i++) {
                    if (showData[i].Barcode == Barcode) {
                        if(Data[0].Quantity >= Number(showData[i].Quantity) + Number(1)){
                            showData[i].Quantity = Number(showData[i].Quantity) + Number(1);
                            showData[i].SubTotal = Number(showData[i].Quantity) * Number(showData[i].BuyPrice);
                            setSubTotal(Number(showData[i].Quantity) * Number(showData[i].BuyPrice));
                            localStorage.setItem("storeTakeOutProductData", JSON.stringify(showData));
                            break;
                        } else {
                            setErrorMessage("ຈຳນວນສິນຄ້າໃນສາງມີບໍ່ພຽງພໍ (ມີ: " + Data[0].Quantity +' )');
                            setShowAlert(true);
                            break;
                        }  
                    }
                }  
                setOrderList();
            } else {
                setErrorMessage("ຈຳນວນສິນຄ້າໃນສາງມີບໍ່ພຽງພໍ (ມີ: " + Data[0].Quantity +' )');
                setShowAlert(true);
            }
        })
            .catch((error) => {
            console.log(error);
        });
    }
}

  const setOrderList = () => {
    let userData = localStorage.getItem("storeTakeOutProductData");
    if (userData) {
        let userRole = JSON.parse(userData);
        setShowData(userRole);
    } else
        setShowData([]);
  }

  const handleCreate = () => {
    if(showData.length > 0){
      setShowCreate(true);
    }
  }  
  async function getAllTakeOutProductStatus() {
    axios.get("/getAllTakeOutProductStatus").then((response) => {
        let Data = response?.data;
        setTakeOutStatusList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(() => {
    getAllTakeOutProductStatus();
    let purchaseOrderData = localStorage.getItem("storeTakeOutProductData");
    if (purchaseOrderData) {
      let userRole = JSON.parse(purchaseOrderData);
      setShowData(userRole);
    }

  }, []);

  return (
    <StoreTakeOutProductContext.Provider value={{SubTotal, setSubTotal, dataList, setDataList, showData, setShowData, TakOutStatusList, showAlert, setShowAlert, errorMessage, setErrorMessage}}>
      <div className="formContent">
        <Alert/> 
        <TempTakeOutProductByBarcode show={show} onClick={handleClose}/>
        <TempTakeOutProductByName show={ShowByName} onClick={handleCloseByName}/>
        <CreateStoreTakeOutProduct show={showCreate} onClick={handleCloseCreate}/>
        <div className="Header">
          <div className="title">
              <span>ນຳເຄື່ອງອອກສາງ</span>
          </div>
          <div className="txtSearch">
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມດ້ວຍລະຫັດ</button>
            <button className="btn btn-secondary" onClick={handleShowByName} >ເພີ່ມດ້ວຍຊື່</button>
            <button className="btn btn-success" onClick={handleCreate} >ສ້າງບິນຂາຍ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>Barcode</th>
                <th>ປະເພດສິນຄ້າ</th>
                <th>ຊື່ພາສາລາວ</th>
                <th>ຊື່ພາສາອັງກິດ</th>
                <th>ລາຄາຊື້</th>
                <th>ຈຳນວນ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.Barcode}</td>
                        <td >{x.ProductType}</td>
                        <td >{x.LaoName}</td>
                        <td >{x.EngName}</td>
                        <td >{currencyFormat(x.BuyPrice)}</td>
                        <td >{currencyFormat(x.Quantity)}</td>
                        <td >
                          <button className="btn btn-primary" type="button" onClick={() => addOrder(x.ProductID ,x.Barcode)}>+</button>
                            &nbsp;&nbsp;&nbsp;
                          <button className="btn btn-danger" type="button" onClick={() => minusOrder(x.Barcode)}>-</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </StoreTakeOutProductContext.Provider>
  );
};

export {StoreTakeOutProductContext};
export default StoreTakeOutProduct;
