import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreatePromoItem from "./create/createPromoItem";
import UpdatePromoItem from "./update/updatePromoItem";
import { API } from "../../../services/api";
import Alert from "../../../components/alert";
import { AlertContext, UserContext } from "../../../App";
import axios from "axios";
axios.defaults.baseURL = API;

const ProductContext = createContext();

function PromotionItemInfo() {

  const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);

  const [ProductList, setProductList] = useState([]);
  const [showData, setShowData] = useState([]);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function HandleSearchProductByBarcode (event) {
    const {value } = event.target;
    if(value){
      let token = localStorage.getItem("token");
      await axios.put(`/SearchPromoItemByBarcode/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
          setShowData(temOrder);
        } else {
          setShowData("");
        }
      }).catch((err) => {
          setShowData(ProductList);
          console.log(err);
      });
    } else {
      setShowData(ProductList);
    }
  }

  const openUpdate = (ProductID, Barcode, LaoName, EngName, Point) => {
    var list = [];
    list.push({
      ProductID: ProductID,
      Barcode: Barcode,
      LaoName: LaoName, 
      EngName: EngName, 
      Point: Point,
    });
    setData(list);
    setShowUpdate(true);
  };

  async function getAllProductLimitTop10() {
    axios.get("/getAllPromoItem").then((response) => {
      let Data = response?.data;
      setProductList(Data);
      setShowData(Data);
    }).catch((error) => {
      console.log(error);
    });
  }


  useEffect(() => {
    getAllProductLimitTop10();
  }, []);

  return (
    <ProductContext.Provider value={{ProductList, setProductList, setShowData, data, showAlert, setShowAlert, errorMessage, setErrorMessage}}>
      <div className="formContent">
        <Alert/> 
        <CreatePromoItem show={show} onClick={handleClose}/>
        <UpdatePromoItem show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ສິນຄ້າເພື່ອແລກ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາດ້ວຍ Barcode" name="Barcode" onChange={(e) => HandleSearchProductByBarcode(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>Barcode</th>
                <th>ຊື່ ພາສາລາວ</th>
                <th>ຊື່ ພາສາອັງກິດ</th>
                <th>ຄະແນນ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.Barcode}</td>
                        <td >{x.LaoName }</td>
                        <td >{x.EngName}</td>
                        <td >{x.Point}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.ProductID} type="button" onClick={() => openUpdate(x.ProductID, x.Barcode, x.LaoName, x.EngName, x.Point)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </ProductContext.Provider>
  );
};

export {ProductContext};
export default PromotionItemInfo;
