import React, { useState, useEffect, useContext } from "react";
import '../../css/admin.css';

function customer_home() {
    return (
        <div className="admin_container">
            <div className="sidebar">
                <div className="sidebar-content">
                <ul className="a">
                    <li>Coffee</li>
                    <li>Tea</li>
                    <li>Coca Cola</li>
                </ul>
                </div>
            </div>

            <div className="content">

            </div>
        </div>
    )
}

export default customer_home;