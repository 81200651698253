import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/product/productTypeValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { ProductTypeContext } from "../productType";

axios.defaults.baseURL = API;

function CreateProductType(props) {

    const {dataList, setDataList, setShowData} = useContext(ProductTypeContext);

    const handleInputPaymentTypeChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.ProductType.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckProductType", results[0].ProductType);
        else
            handleChange("CheckProductType", "");
    }

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            ProductType: values.ProductType,
        }
        await axios.post("/createProductType", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllProductType();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllProductType() {
        axios
          .get("/getAllProductType")
          .then((response) => {
            let Data = response?.data;
            setDataList(Data);
            setShowData(Data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

    const ClearData = () => 
        handleChange("ProductType", "");

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງປະເພດສິນຄ້າ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ປະເພດສິນຄ້າ</span>
                                <input className={`input form-control ${errors.ProductType && 'is-invalid'}`} type="text" name="ProductType" onChange={(e) => handleInputPaymentTypeChange(e)} value={values.ProductType || ''} required />
                                {errors.ProductType && (
                                    <p className="invalid-feedback">{errors.ProductType}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateProductType;
