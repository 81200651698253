import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/statusValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { StatusContext } from "../status";

axios.defaults.baseURL = API;

function UpdateStatus(props) {

    const {StatusList, setStatusList, StatusData} = useContext(StatusContext);

    const handleInputStatusChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = StatusList.filter((data) => {
            return data.Status.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckStatus", results[0].Status);
        else
            handleChange("CheckStatus", "");
    }

    async function updateStatus() {
        let token = localStorage.getItem("token");
        var data = {
            Status: values.Status
        }
        await axios.put(`/updateStatus/${values.StatusID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {

            for (var i=0; i < StatusList.length; i++) {
                if (StatusList[i].StatusID == values.StatusID) {
                    StatusList[i].Status = values.Status;
                    localStorage.setItem("statusData", JSON.stringify(StatusList));
                    break;
                }
            }  
            setStatusList(StatusList);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("StatusID", "");
        handleChange("Status", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(updateStatus, validate);

    useEffect(() => {
        StatusData.forEach(value => {
            handleChange("StatusID", value.StatusID);
            handleChange("Status", value.Status);
            handleChange("CheckStatus", "");
        });
    }, [StatusData]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂສະຖານະ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ສະຖານະ</span>
                                <input className={`input form-control ${errors.Status && 'is-invalid'}`} type="text" name="Status" onChange={(e) => handleInputStatusChange(e)} value={values.Status || ''} required />
                                {errors.Status && (
                                    <p className="invalid-feedback">{errors.Status}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateStatus;
