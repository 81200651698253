
function CreateSaleProductValidate(values) {

    let errors = {};

    if (!values.PaymentTypeID)
        errors.PaymentTypeID = 'PaymentTypeID is required';

    return errors;
};

export default CreateSaleProductValidate;
