
function ReceiveProductValidate(values) {

    let errors = {};

    if (!values.EmployeeID)
        errors.EmployeeID = 'EmployeeID is required';

    return errors;
};

export default ReceiveProductValidate;
