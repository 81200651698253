import React, { useState, useEffect, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateRole from "./create/createRole";
import UpdateRole from "./update/updateRole";

const RoleContext = createContext();

function UserRole() {

  const [RoleList, setRoleList] = useState([]);
  const [ShowRole, setShowRole] = useState([]);
  const [RoleData, setRoleData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { name, value } = event.target;
    if(value){
      var results = [];
      results = RoleList.filter((data) => {
          return data.Role.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
      setShowRole(results);
      else 
      setShowRole(RoleList);
    } else 
      setShowRole(RoleList);
  }

  const openUpdate = (RoleID, Role) => {
    var list = [];
    list.push({
      RoleID: RoleID,
      Role: Role
    });
    setRoleData(list);
    setShowUpdate(true);
  };
  
  useEffect(() => {
    let userData = localStorage.getItem("roleData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setRoleList(userRole);
      setShowRole(userRole);
    }
  }, []);

  return (
    <RoleContext.Provider value={{RoleList, setRoleList, RoleData, setRoleData}}>
      <div className="formContent">
        <CreateRole show={show} onClick={handleClose}/>
        <UpdateRole show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ສິດນຳໃຊ້</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ສິດນຳໃຊ້</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                ShowRole && ShowRole.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.Role}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.RoleID} type="button" onClick={() => openUpdate(x.RoleID, x.Role)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </RoleContext.Provider>
  );
};

export {RoleContext};
export default UserRole;
