import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/product/menuValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { MenuContext } from "../menu";

axios.defaults.baseURL = API;

function UpdateMenu(props) {

    const {MenuList, setMenuList, setShowData, ProductType, setShowAlert, setErrorMessage, data} = useContext(MenuContext);

    const handleSelectProductType = (e) => {
        var data = ProductType.filter(function (item) {
            return item.ProductType == e.target.value
        })
        if (data.length > 0) {
            handleChange("ProductType", data[0].ProductType);
            handleChange("ProductTypeID", data[0].ProductTypeID);
            check(data[0].ProductType);
        } else {
            handleChange("ProductType", "");
            handleChange("ProductTypeID", "");
        }
    };

    const check = (params) => {
        const results = MenuList.filter((data) => {
            return data.ProductType.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckProductType", results[0].ProductType);
    }

    async function update() {
        if(values.CheckProductType == ""){
            let token = localStorage.getItem("token");
            var data = {
                ProductTypeID: values.ProductTypeID,
                StatusID: 1
            }
            await axios.put(`/UpdateMenu/${values.MenuID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                getMenu();
                ClearData();
                props.onClick(false);
            }).catch((err) => {
                console.log(err);
            });
        } else {
            setErrorMessage("ປະເພດສິນຄ້າແມ່ນມີຢູ່ແລ້ວ");
            setShowAlert(true);
        }
    };

    async function getMenu() {
        axios
          .get("/getmenu")
          .then((response) => {
            let Data = response?.data;
            setMenuList(Data);
            setShowData(Data);
            let DataString = JSON.stringify(Data);
            localStorage.setItem("menuData", DataString);
          })
          .catch((error) => {
            console.log(error);
        });
      }

    const ClearData = () => {
        handleChange("ProductTypeID", "");
        handleChange("ProductType", "");
        handleChange("CheckProductType", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(update, validate);

    useEffect(() => {
        data.forEach(value => {
            handleChange("ProductTypeID", value.ProductTypeID);
            handleChange("ProductType", value.ProductType);
            handleChange("MenuID", value.MenuID);
            handleChange("CheckProductType", "");
        });
    }, [data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂ ເມນູ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <label>ເມນູ: </label>
                                <select name="ProductTypeID" onChange={(e) => handleSelectProductType(e)}
                                    className={`input form-control ${errors.ProductTypeID && 'is-invalid'}`}>
                                    { ProductType && ProductType.map((x, y) => {
                                        if (x.ProductType == values.ProductType) {
                                            return (
                                                <option key={x.ProductTypeID} value={x.ProductType} selected>
                                                    {x.ProductType}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.ProductTypeID} value={x.ProductType}>
                                                    {x.ProductType}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateMenu;
