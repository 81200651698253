import React, { useContext } from "react";
import '../../../../css/StockOrderDetails.css';
import { ReceivedBillContent } from "../receivedBill";

function ReceivedBillDetail(props) {

    const {PurchaseOrderDetails} = useContext(ReceivedBillContent);

    function currencyFormat(num) {
        if (num !== "") {
            return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="PurchaseOrderDetailForm">
            <div className="content">
                <div className="Header">
                    <span>ລາຍລະອຽດສິນຄ້າ</span>
                </div>
                <div className="Body">
                <div className="TempContent">
                    <table className="styled-table">
                        <thead>
                        <tr key={1}>
                            <th>ລ/ດ</th>
                            <th>Barcode</th>
                            <th>ປະເພດສິນຄ້າ</th>
                            <th>ຊື່ພາສາລາວ</th>
                            <th>ລາຄາຊື້</th>
                            <th>ຈຳນວນ</th>
                            <th>ລວມ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            PurchaseOrderDetails && PurchaseOrderDetails.map((x, y) => {
                                return (
                                <tr key={y}>
                                    <td >{y + 1}</td>
                                    <td >{x.Barcode}</td>
                                    <td >{x.ProductType}</td>
                                    <td >{x.LaoName}</td>
                                    <td >{currencyFormat(x.BuyPrice)}</td>
                                    <td >{currencyFormat(x.Quantity)}</td>
                                    <td >{currencyFormat(x.SubTotal)}</td>
                                </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="footer">
                    <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                        ກັບຄືນ
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReceivedBillDetail;
