import React, { useEffect, useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/pos/updateOrderValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { ChangeTableContent } from "../editOrder";

axios.defaults.baseURL = API;

function UpdateOrder(props) {

    const {data, setShowData} = useContext(ChangeTableContent);

    function currencyFormat(num) {
        if (num !== "" && num > 0) {
            return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        } else {
            return 0;
        }
      }

    const handelInputNumber = (e) => {
        const { name, value } = e.target;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (value === '' || re.test(value.split(',').join(''))) {
                handleChange(name, value.split(',').join(''));
                CalculateChangeAmount(value.split(',').join(''))
            }  
        } else {
            handleChange(name, 0);
            CalculateChangeAmount(0);
        }
    }

    const CalculateChangeAmount = (Quantity) => {
        if (Quantity > 0) {
            var subTotal = (Number(Quantity) * Number(values.Retail_Price));
            handleChange("SubTotal", subTotal);
        } else {
            handleChange("SubTotal", 0);
        }
    }


    async function update() {
        let token = localStorage.getItem("token");
        var data = {
            TemOrderID: values.TemOrderID,
            Quantity: values.Quantity,
        }
        await axios.post(`/UpdateTemOrder`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getTemOrder();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getTemOrder () {
        let token = localStorage.getItem("token");
        var data = {
            TableID: values.TableID,
            LocationID: values.LocationID,
        }
        await axios.post("/getTemOrder",data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let tempData = response?.data;
            if(tempData.length > 0){ 
                setShowData(tempData);
            } else {
                setShowData([]);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const ClearData = () => {
        handleChange("TemOrderID", "");
        handleChange("LaoName", "");
        handleChange("Retail_Price", "");
        handleChange("Quantity", "");
        handleChange("SubTotal", "");
        handleChange("TableID", "");
        handleChange("LocationID","");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(update, validate);

    useEffect(() => {
        data.forEach(value => {
            handleChange("TemOrderID", value.TemOrderID);
            handleChange("LaoName", value.LaoName);
            handleChange("Retail_Price", value.Retail_Price);
            handleChange("Quantity", value.Quantity);
            handleChange("SubTotal", value.SubTotal);
            handleChange("TableID", value.TableID);
            handleChange("LocationID", value.LocationID);
        });

    }, [data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂໝວດສິນຄ້າ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ຊື່ພາສາລາວ</span>
                                <input className={`input form-control`} type="text" name="LaoName" value={values.LaoName } required disabled />
                            </div>
                            <div className="col form-group">
                                <span>ລາຄາ</span>
                                <input className={`input form-control`} type="text" name="Retail_Price" value={currencyFormat(values.Retail_Price)} required disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>ຈຳນວນ</span>
                                <input className={`input form-control ${errors.Quantity && 'is-invalid'}`} type="text" name="Quantity" onChange={(e) => handelInputNumber(e)} value={values.Quantity || ''} required />
                                {errors.Quantity && (
                                    <p className="invalid-feedback">{errors.Quantity}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ລວມ</span>
                                <input className={`input form-control`} type="text" name="SubTotal" value={currencyFormat(values.SubTotal)} disabled />
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateOrder;
