import React, { useEffect, useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/product/productValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { ProductContext } from "../product";

axios.defaults.baseURL = API;

function UpdateProduct(props) {

    const {setProductList, setShowData, data, ProductTypeList, CategoryList, BrandList, SizeTypeList} = useContext(ProductContext);
    const [categoryData, setCategoryData] = useState([]);
    const [BrandData, setBrandData] = useState([]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
    };

    const handelInputNumber = (e) => {
        const { name, value } = e.target;
          const re = /^[0-9\b]+$/;
          if (value) {
              if (value === '' || re.test(value.split(',').join(''))) {
                  handleChange(name, value.split(',').join(''));
              }  
          } else {
              handleChange(name, 0);
          }
      }

    const handleSelectProductType = (e) => {
        const { value } = e.target;
        if(value){
            getCategoryByProductTypeID(e.target.children[e.target.selectedIndex].getAttribute('data-id'));
            handleChange("ProductType", value);
            handleChange("ProductTypeID", e.target.children[e.target.selectedIndex].getAttribute('data-id'));
        } else {
            handleChange("ProductType", "");
            handleChange("ProductTypeID", "");
            handleChange("Category", "");
            handleChange("CategoryID", "");
            handleChange("Brand", "");
        }
        
    };

    async function getCategoryByProductTypeID(value) {
        let token = localStorage.getItem("token");
        await axios.get(`/getCategoryByProductTypeID/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setCategoryData(temOrder);
          } else {
            setCategoryData([]);
          }
        }).catch((err) => {
            setCategoryData([]);
            console.log(err);
        });
    }

    const handleSelectCategory = (e) => {
        const { value } = e.target;
        if(value){
            getBrandByCategoryID(e.target.children[e.target.selectedIndex].getAttribute('data-id'));
            handleChange("Category", value);
            handleChange("CategoryID", e.target.children[e.target.selectedIndex].getAttribute('data-id'));
        } else {
            setBrandData([]);
            handleChange("Category", "");
            handleChange("CategoryID", "");
            handleChange("Brand", "");
            handleChange("BrandID", "");
        }
    };

    async function getBrandByCategoryID(value) {
        let token = localStorage.getItem("token");
        await axios.get(`/getBrandByCatetoryID/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setBrandData(temOrder);
          } else {
            setBrandData([]);
          }
        }).catch((err) => {
            setBrandData([]);
            console.log(err);
        });
    }

    const handleSelectBrand = (e) => {
        var data = BrandData.filter(function (item) {
            return item.Brand == e.target.value
        })
        if (data.length > 0) {
            handleChange("Brand", data[0].Brand);
            handleChange("BrandID", data[0].BrandID);
        } else {
            handleChange("Brand", "");
            handleChange("BrandID", "");
        }
    };

    const handleSelectSizeType = (e) => {
        var data = SizeTypeList.filter(function (item) {
            return item.SizeType == e.target.value
        })
        if (data.length > 0) {
            handleChange("SizeType", data[0].SizeType);
            handleChange("SizeTypeID", data[0].SizeTypeID);
        } else {
            handleChange("SizeType", "");
            handleChange("SizeTypeID", "");
        }
    };

    async function update() {
        let token = localStorage.getItem("token");
        var data = {
            ProductTypeID: values.ProductTypeID,
            CategoryID: values.CategoryID,
            BrandID: values.BrandID,
            SizeTypeID: values.SizeTypeID,
            Barcode: values.Barcode,
            LaoName: values.LaoName,
            EngName: values.EngName,
            BuyPrice: values.BuyPrice,
            Retail_Price: values.Retail_Price,
            Wholesale_Price: values.Wholesale_Price,
            Color: values.Color,
            Size: values.Size,
        }
        await axios.put(`/updateProduct/${values.ProductID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllProductLimitTop10();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllProductLimitTop10() {
        axios.get("/getAllProductLimit10").then((response) => {
          let Data = response?.data;
          setProductList(Data);
          setShowData(Data);
        }).catch((error) => {
          console.log(error);
        });
    }

    const ClearData = () => {
        handleChange("Barcode", "");
        handleChange("LaoName", "");
        handleChange("EngName", "");
        handleChange("BuyPrice", "");
        handleChange("Retail_Price", "");
        handleChange("Wholesale_Price", "");
        handleChange("Color", "");
        handleChange("Size", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(update, validate);

    useEffect(() => {
        data.forEach(value => {
            handleChange("ProductTypeID", value.ProductTypeID);
            handleChange("ProductType", value.ProductType);
            handleChange("CategoryID", value.CategoryID);
            handleChange("Category", value.Category);
            handleChange("BrandID", value.BrandID);
            handleChange("Brand", value.Brand);
            handleChange("SizeTypeID", value.SizeTypeID);
            handleChange("SizeType", value.SizeType);
            handleChange("ProductID", value.ProductID);
            handleChange("Barcode", value.Barcode);
            handleChange("LaoName", value.LaoName);
            handleChange("EngName", value.EngName);
            handleChange("BuyPrice", value.BuyPrice);
            handleChange("Retail_Price", value.Retail_Price);
            handleChange("Wholesale_Price", value.Wholesale_Price);
            handleChange("Color", value.Color);
            handleChange("Size", value.Size);
            handleChange("CheckBarcode", "");
        });

    }, [data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂໝວດສິນຄ້າ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>Barcode</span>
                                <input className={`input form-control ${errors.Barcode && 'is-invalid'}`} type="text" name="Barcode" value={values.Barcode || ''} disabled />
                                {errors.Barcode && (
                                    <p className="invalid-feedback">{errors.Barcode}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ຊື່ພາສາລາວ</span>
                                <input className={`input form-control ${errors.LaoName && 'is-invalid'}`} type="text" name="LaoName" onChange={(e) => handleInputChange(e)} value={values.LaoName || ''} required />
                                {errors.LaoName && (
                                    <p className="invalid-feedback">{errors.LaoName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ຊື່ພາສາອັງກິດ</span>
                                <input className={`input form-control ${errors.EngName && 'is-invalid'}`} type="text" name="EngName" onChange={(e) => handleInputChange(e)} value={values.EngName || ''} required />
                                {errors.EngName && (
                                    <p className="invalid-feedback">{errors.EngName}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>ລາຄາຊື້</span>
                                <input className={`input form-control ${errors.BuyPrice && 'is-invalid'}`} type="text" name="BuyPrice" onChange={(e) => handelInputNumber(e)} value={values.BuyPrice || ''} required />
                                {errors.BuyPrice && (
                                    <p className="invalid-feedback">{errors.BuyPrice}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ລາຄາຂາຍ</span>
                                <input className={`input form-control ${errors.Retail_Price && 'is-invalid'}`} type="text" name="Retail_Price" onChange={(e) => handelInputNumber(e)} value={values.Retail_Price || ''} required />
                                {errors.Retail_Price && (
                                    <p className="invalid-feedback">{errors.Retail_Price}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ລາຄາຂາຍສົ່ງ</span>
                                <input className={`input form-control ${errors.Wholesale_Price && 'is-invalid'}`} type="text" name="Wholesale_Price" onChange={(e) => handelInputNumber(e)} value={values.Wholesale_Price || ''} required />
                                {errors.Wholesale_Price && (
                                    <p className="invalid-feedback">{errors.Wholesale_Price}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <label>ປະເພດສິນຄ້າ: </label>
                                <select name="ProductTypeID" onChange={(e) => handleSelectProductType(e)}
                                    className={`input form-control ${errors.ProductTypeID && 'is-invalid'}`}>
                                    { ProductTypeList && ProductTypeList.map((x, y) => {
                                        if (x.ProductType == values.ProductType) {
                                            return (
                                                <option 
                                                key={x.ProductTypeID}
                                                data-id={x.ProductTypeID}
                                                value={x.ProductTypeID} selected>
                                                    {x.ProductType}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.ProductTypeID}
                                                data-id={x.ProductTypeID}
                                                value={x.ProductTypeID}>
                                                    {x.ProductType}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ໜວດສິນຄ້າ: </label>
                                <select name="CategoryID" onChange={(e) => handleSelectCategory(e)}
                                    className={`input form-control ${errors.CategoryID && 'is-invalid'}`}>
                                    { categoryData && categoryData.map((x, y) => {
                                        if (x.Category == values.Category ) {
                                            return (
                                                <option 
                                                key={x.CategoryID}
                                                data-id={x.CategoryID}
                                                value={x.Category} selected>
                                                    {x.Category}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.CategoryID}
                                                data-id={x.CategoryID}
                                                value={x.Category}>
                                                    {x.Category}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ຫຍີ່ຫໍ້: </label>
                                <select name="BrandID" onChange={(e) => handleSelectBrand(e)}
                                    className={`input form-control ${errors.BrandID && 'is-invalid'}`}>
                                    { BrandData && BrandData.map((x, y) => {
                                        if (x.Brand == values.Brand ) {
                                            return (
                                                <option key={x.BrandID} value={x.Brand} selected>
                                                    {x.Brand}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.BrandID} value={x.Brand}>
                                                    {x.Brand}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <label>ປະເພດຂະໜາດ: </label>
                                <select name="SizeTypeID" onChange={(e) => handleSelectSizeType(e)}
                                    className={`input form-control ${errors.SizeTypeID && 'is-invalid'}`}>
                                    { SizeTypeList && SizeTypeList.map((x, y) => {
                                        if (x.SizeType == values.SizeType ) {
                                            return (
                                                <option key={x.SizeTypeID} value={x.SizeType} selected>
                                                    {x.SizeType}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.SizeTypeID} value={x.SizeType}>
                                                    {x.SizeType}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="col form-group">
                                <span>ຂະໜາດ</span>
                                <input className={`input form-control ${errors.Size && 'is-invalid'}`} type="text" name="Size" onChange={(e) => handleInputChange(e)} value={values.Size || ''} required />
                                {errors.Size && (
                                    <p className="invalid-feedback">{errors.Size}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ສີ</span>
                                <input className={`input form-control ${errors.Color && 'is-invalid'}`} type="text" name="Color" onChange={(e) => handleInputChange(e)} value={values.Color || ''} required />
                                {errors.Color && (
                                    <p className="invalid-feedback">{errors.Color}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateProduct;
