import React, { useState, useEffect, createContext, useContext,useRef  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import '../../../css/TakeOutProductReport.css';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext, AlertContext } from "../../../App";
import { useReactToPrint } from 'react-to-print';
import logo from '../../../img/logo/ManeeCafeLogo.jpg';
import { format } from "date-fns";

axios.defaults.baseURL = API;

class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      const today = Date.now();
      let SubTotal = 0;
      let Quantity = 0;
      let i =0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date()));
      return (
          <div className="PurchaseOrderReportContent">
              <div className="ReportHeader">
                <div className="ReportHeaderBody">
                      <img className="profile-img" src={logo} />
                      <div className="ReportTitle">
                          <div className="titleHeader1">
                            <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                          </div>
                          <div className="titleHeader2">
                              <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                          </div>
                          <div className="titleHeader2">
                              <span>**********</span>
                          </div>
                          <div className="titleHeader3">
                              <span>ລາຍງານ ນຳສິນຄ້າອອກສາງ</span>
                          </div>
                      </div>
                </div>
                <div className="ReportDate">
                      <span>ວັນທີ # {StartDate}</span>
                  </div>

                  <div className="ReportBill">
                      <span>ເລກບິນ: {billDetails.BillNO}</span>
                  </div>
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                        <span className="CountNumber">NO.</span>
                        <span className="Barcode">Barcode</span>
                        <span className="ProductType">ປະເພດສິນຄ້າ</span>
                        <span className="Category">ໝວດສິນຄ້າ</span>
                        <span className="Brand">ຫຍີ່ຫໍ້</span>
                        <span className="SizeType">Size Type</span>
                        <span className="Size">Size/ຈຳນວນ</span>
                        <span className="Retail_Price">ລາຄາ/ລວມ</span>
                  </div>
                  <div className="HeadLine">
                      <div className="footer-line"/>
                    </div>
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                            SubTotal =  Number(x.SubTotal) +  Number(SubTotal);
                            Quantity =  Number(x.Quantity) +  Number(Quantity);
                      if(y < 22){
                        return (
                          <div className="Items">
                              <div className="Detail">
                                  <div className="mds">
                                      <span className="NO">{(y + 1) }</span>
                                      <span className="Barcode">{x.Barcode.length > 20 ? x.Barcode.substring(0, 20) + '...' : x.Barcode}</span>
                                      <span className="ProductType">{x.ProductType.length > 25 ? x.ProductType.substring(0, 25) + '...' : x.ProductType}</span>
                                      <span className="Category">{x.Category.length > 25 ? x.Category.substring(0, 25) + '...' : x.Category}</span>
                                      <span className="Brand">{x.Brand.length > 12 ? x.Brand.substring(0, 12) + '...' : x.Brand}</span>
                                      <span className="SizeType">{x.SizeType.length > 10 ? x.SizeType.substring(0, 10) + '...' : x.SizeType}</span>
                                      <span className="Size">{x.Size}</span>
                                      <span className="Retail_Price">{currencyFormat(x.BuyPrice)}</span>
                                  </div>
                              </div>
                              <div className="ProductName">
                                      <span className="LaoName">{x.LaoName}</span>
                                      <span className="Quantity">{currencyFormat(x.Quantity)}</span>
                                      <span className="sTotal">{currencyFormat(x.SubTotal)}</span>
                                  </div>
                                <div className="p-line"/>
                          </div>
                        )
                      } else if (i == 0) {
                        i++;
                        return (
                          <div className="Items">
                              <div className="Detail">
                              <div className="newline"></div>
                                    <div className="mds">
                                      <span className="NO">{(y + 1) }</span>
                                      <span className="Barcode">{x.Barcode.length > 20 ? x.Barcode.substring(0, 20) + '...' : x.Barcode}</span>
                                      <span className="ProductType">{x.ProductType.length > 25 ? x.ProductType.substring(0, 25) + '...' : x.ProductType}</span>
                                      <span className="Category">{x.Category.length > 25 ? x.Category.substring(0, 25) + '...' : x.Category}</span>
                                      <span className="Brand">{x.Brand.length > 12 ? x.Brand.substring(0, 12) + '...' : x.Brand}</span>
                                      <span className="SizeType">{x.SizeType}</span>
                                      <span className="Size">{x.Size}</span>
                                      <span className="Retail_Price">{currencyFormat(x.BuyPrice)}</span>
                                  </div>
                              </div>
                              <div className="ProductName">
                                      <span className="LaoName">{x.LaoName}</span>
                                      <span className="Quantity">{currencyFormat(x.Quantity)}</span>
                                      <span className="sTotal">{currencyFormat(x.SubTotal)}</span>
                              </div>
                              <div className="p-line"/>
                          </div>
                        )
                      } else {
                          i++;
                          if(i== 28)
                              i=0;
                          return (
                            <div className="Items">
                                <div className="Detail">
                                  <div className="mds">
                                        <span className="NO">{(y + 1) }</span>
                                        <span className="Barcode">{x.Barcode.length > 20 ? x.Barcode.substring(0, 20) + '...' : x.Barcode}</span>
                                        <span className="ProductType">{x.ProductType.length > 25 ? x.ProductType.substring(0, 25) + '...' : x.ProductType}</span>
                                        <span className="Category">{x.Category.length > 25 ? x.Category.substring(0, 25) + '...' : x.Category}</span>
                                        <span className="Brand">{x.Brand.length > 12 ? x.Brand.substring(0, 12) + '...' : x.Brand}</span>
                                        <span className="SizeType">{x.SizeType}</span>
                                        <span className="Size">{x.Size}</span>
                                        <span className="Retail_Price">{currencyFormat(x.BuyPrice)}</span>
                                    </div>
                                </div>
                                <div className="ProductName">
                                      <span className="LaoName">{x.LaoName}</span>
                                      <span className="Quantity">{currencyFormat(x.Quantity)}</span>
                                      <span className="sTotal">{currencyFormat(x.SubTotal)}</span>
                                </div>
                                <div className="p-line"/>
                            </div>
                          )
                      }
                      })
                    }
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="sQuantity">{currencyFormat(Quantity)}</span>
                      <span className="SubTotal">{currencyFormat(SubTotal)}</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ຈັດການ</span>
                      <span className="StockAdmin">ຜູ້ກວດກາ</span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}

function TakeOutProductBill() {
  function getDate(gdate) {
    var date = new Date(gdate);
    return format(date, "dd-MM-yyyy HH:mm");
  }
  const componentRef = useRef(); 
  const {userID, LocationID, LocationName} = useContext(UserContext);
  const [dataList, setDataList] = useState([]);
  const [PurchaseOrderDetail, setPurchaseOrderDetail] = useState([]);
  const [showData, setShowData] = useState([]);
  const [BillNO, setBillNO] = useState(null);



  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function onClickPrint(TakeOutProductID) {
    await axios.get(`/getTakeOutProductBillDetail/${TakeOutProductID}`).then((response) => {
      let Data = response?.data;
      
      setShowData(Data);
      setBillNO(TakeOutProductID);
      if(Data)
        handlePrint();
    }).catch((error) => {
      console.log(error);
    });
  }

  async function getTakeOutProductBill() {
    await axios.get(`/getTakeOutProductBill`).then((response) => {
        let Data = response?.data;
        setDataList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getTakeOutProductBillByTakeOutID(e) {
    const { name, value } = e.target;
    if(value){
      await axios.get(`/getTakeOutProductBillByTakeOutID/${value}`).then((response) => {
        let Data = response?.data;
        setDataList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      getTakeOutProductBill();
    }
  }

  useEffect(() => {
    getTakeOutProductBill();
  }, []);

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ບິນນຳເຄື່ອງອອກສາງ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາດ້ວຍ ເລກບິນ" name="BillNO" onChange={(e) => getTakeOutProductBillByTakeOutID(e)}/>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ສາເຫດ</th>
                <th>ມື້ສ້າງ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                dataList && dataList.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.TakeOutProductID}</td>
                        <td >{x.Status}</td>
                        <td >{getDate(x.CreatedDate)}</td>
                        <td >
                          <button className="btn btn-secondary" type="button" onClick={() => onClickPrint(x.TakeOutProductID)}>ພິມລາຍງານ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
                    BillDetails={{"BillNO": BillNO,"LocationName": LocationName}} 
            />
        </div>
      </div>
  );
};

export default TakeOutProductBill;
