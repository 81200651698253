import React, { useContext } from "react";
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { PendingBillContent } from "../pendingBill";

axios.defaults.baseURL = API;

function UpdateProductOrder(props) {

    const {PurchaseOrderDetail, setPurchaseOrderDetail} = useContext(PendingBillContent);

    function currencyFormat(num) {
        if (num !== "") {
            return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }

    async function addOrder(ID, PurchaseOrderID, Quantity, BuyPrice) {
        let token = localStorage.getItem("token");
        var data = {
            Quantity: Number(Quantity) + Number(1),
            SubTotal: (Number(Quantity) + Number(1)) * Number(BuyPrice)
        }
        await axios.put(`/updatePurchaseOrderDetail/${ID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          getPendingBill(PurchaseOrderID);
        }).catch((err) => {
            console.log(err);
        });
    };
    
    async function minusOrder(ID, PurchaseOrderID, Quantity, BuyPrice) {
        let token = localStorage.getItem("token");
        if(Quantity > 1){
            var data = {
                Quantity: Number(Quantity) - Number(1),
                SubTotal: (Number(Quantity) - Number(1)) * Number(BuyPrice)
            }
            await axios.put(`/updatePurchaseOrderDetail/${ID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
              getPendingBill(PurchaseOrderID);
            }).catch((err) => {
                console.log(err);
            });
        } else {
            await axios.put(`/deletePurchaseOrderDetail/${ID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
              getPendingBill(PurchaseOrderID);
            }).catch((err) => {
                console.log(err);
            });
        }
        
    };

    async function getPendingBill(PurchaseOrderID) {
        await axios
        .get(`/getPurchaseOrderProduct/${PurchaseOrderID}`)
        .then((response) => {
          let Data = response?.data;
          setPurchaseOrderDetail(Data);
        })
        .catch((error) => {
          console.log(error);
        });
      }

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ລາຍລະອຽດສິນຄ້າ</span>
                </div>
                <div className="Body">
                    <table className="styled-table">
                        <thead>
                        <tr key={1}>
                            <th>ລ/ດ</th>
                            <th>Barcode</th>
                            <th>ປະເພດສິນຄ້າ</th>
                            <th>ຊື່ພາສາລາວ</th>
                            <th>ລາຄາຊື້</th>
                            <th>ຈຳນວນ</th>
                            <th>ລວມ</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            PurchaseOrderDetail && PurchaseOrderDetail.map((x, y) => {
                                return (
                                <tr key={y}>
                                    <td >{y + 1}</td>
                                    <td >{x.Barcode}</td>
                                    <td >{x.ProductType}</td>
                                    <td >{x.LaoName}</td>
                                    <td >{currencyFormat(x.BuyPrice)}</td>
                                    <td >{currencyFormat(x.Quantity)}</td>
                                    <td >{currencyFormat(x.SubTotal)}</td>
                                    <td >
                                        <button className="btn btn-primary" type="button" onClick={() => addOrder(x.ID, x.PurchaseOrderID, x.Quantity, x.BuyPrice)}>+</button>
                                            &nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-danger" type="button" onClick={() => minusOrder(x.ID, x.PurchaseOrderID, x.Quantity, x.BuyPrice)}>-</button>
                                    </td>
                                </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                        ກັບຄືນ
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateProductOrder;
