
function ProductTypeValidate(values) {

    let errors = {};
    if (!values.ProductType)
        errors.ProductType = 'ProductType is required';
    else if (values.CheckProductType !== "")
        errors.ProductType = 'ProductType Already Exit';

    return errors;
};

export default ProductTypeValidate;
