import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/stock/createTakeOutProductValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { StoreTakeOutProductContext } from "../StoreTakeOutProduct";
import { UserContext } from "../../../../App";

axios.defaults.baseURL = API;

function CreateStoreTakeOutProduct(props) {
    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
    }

    const {showData, setShowData, TakOutStatusList} = useContext(StoreTakeOutProductContext);
    const {userID, LocationID} = useContext(UserContext);

    const handleSelectTakeOutStatus = (e) => {
        var data = TakOutStatusList.filter(function (item) {
            return item.Status == e.target.value
        })
        if (data.length > 0) {
            handleChange("Status", data[0].Status);
            handleChange("TakeOutStatusID", data[0].TakeOutStatusID);
        } else {
            handleChange("Status", "");
            handleChange("TakeOutStatusID", "");
        }
    };

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            StoreTakeOutStatusID: values.TakeOutStatusID,
            CreatedDate: getDate(),
            CreatedByID: userID,
        }
        await axios.post("/CreateStoreTakeOutProduct", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            CreateTakeOutProductDetail(response?.data?.insertId);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function CreateTakeOutProductDetail(StoreTakeOutProductID) {
        let token = localStorage.getItem("token");
        if(showData.length > 0) {
            for (var i=0; i < showData.length; i++) {
                var list;
                list ={
                    StoreTakeOutProductID: StoreTakeOutProductID,
                    ProductID: showData[i].ProductID,
                    Quantity: showData[i].Quantity,
                    BuyPrice: showData[i].BuyPrice,
                    SubTotal: showData[i].SubTotal,
                };
                await axios.post("/CreateStoreTakeOutProductDetail", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                    getStoreInventory(showData[i].ProductID, showData[i].Quantity);
                }).catch((err) => {
                    console.log(err);
                });
            } 
            ClearData();
            props.onClick(false);
        }
    }


    async function getStoreInventory (ProductID, Quantity) {
        let token = localStorage.getItem("token");
        var data = {
            ProductID: ProductID,
            LocationID: LocationID,
        }
        await axios.post("/getStoreInventory",data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let Data = response?.data;
            if(Data.length > 0){
                var qty = Number(Data[0].Quantity) - Number(Quantity);
                UpdateInventory(Data[0].StoreInventoryID, qty);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    
    async function UpdateInventory (StoreInventoryID, Quantity) {
        let token = localStorage.getItem("token");
        var data = {
            StoreInventoryID: StoreInventoryID,
            Quantity: Quantity,
        }
        await axios.post("/PosUpdateStoreInventory", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    }

    const ClearData = () => {
        localStorage.removeItem('storeTakeOutProductData');
        handleChange("Status", "");
        handleChange("TakeOutStatusID", "");
        setShowData([]);
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ນຳເຄື່ອງອອກສາງ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <label>ສາເຫດນຳເຄື່ອງອອກ: </label>
                                <select name="TakeOutStatusID" onChange={(e) => handleSelectTakeOutStatus(e)}
                                    className={`input form-control ${errors.TakeOutStatusID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກໝວດສິນຄ້າ'}
                                    </option>
                                    {   TakOutStatusList && TakOutStatusList.map(item => (
                                        <option
                                            key={item.TakeOutStatusID}
                                            value={item.Status}
                                        >
                                            {item.Status}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateStoreTakeOutProduct;
