import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";
import ChangeTable from "./changetable";
import UpdateOrder from "./create/updateOrder";

axios.defaults.baseURL = API;
const ChangeTableContent = createContext();

function EditOrder() {

  function currencyFormat(num) {
    if (num !== "" && num > 0) {
        return Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  }

  const [showChangeTable, setShowChangeTable] = useState(false);
  const {userID, LocationID, UserName, LocationName} = useContext(UserContext);
  const [showData, setShowData] = useState([]);
  const [TableID, setTableID] = useState();
  const [TableNumer, setTableNumber] = useState();
  const [data, setData] = useState([]);
  const [showUpdate, setShowUpdate] = useState(false);
  const [TempOrder, setTempOrder] = useState([]);

  const handleCloseUpdate = () => setShowUpdate(false);

  const handleInputChange = event => {
    const {value } = event.target;
    if (value) {
      console.log(value)
      setTableNumber(value);
      getTemOrder(value)
      getTem(value);
    } else {
      setTableNumber();
      getTemOrder();
      getTem();
    }
};

  async function getTemOrder (tbNumber) {
    if(tbNumber){
        let token = localStorage.getItem("token");
        var data = {
            TableNumber: tbNumber,
            LocationID: LocationID,
        }
        await axios.post("/getTemOrderByTableNumber",data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let Data = response?.data;
            if(Data.length > 0){
              for (var i=0; i < Data.length; i++) {
                setTableID(Data[i].TableID);
              }  
              setShowData(Data);
            } else {
              setTableID();
              setShowData([]);
            }
        }).catch((err) => {
            console.log(err);
        });
    } else {
      setShowData([]);
      setTableID();
    }
}

async function getTem (TableID) {
  let userData = localStorage.getItem(TableID);
  if (userData) {
      let userRole = JSON.parse(userData);
      setTempOrder(userRole);
  } else {
    setTempOrder([]);
  }
}

async function DeleteTemOrder (TemOrderID, ProductID) {
  let token = localStorage.getItem("token");
  var data = {
    TemOrderID: TemOrderID,
  }
  await axios.post("/DeleteTemOrderByTempID", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
    getTemOrder(TableNumer);
    deletem(ProductID, -1);
  }).catch((err) => {
      console.log(err);
  });
}

async function deletem(ProductID, qty) {
  for (var i=0; i < TempOrder.length; i++) {
      if (TempOrder[i].ProductID == ProductID) {
          if(Number(TempOrder[i].Quantity) + Number(qty) > 0)
          {
              TempOrder[i].Quantity = Number(TempOrder[i].Quantity) + Number(qty);
          } else {
              TempOrder.splice(i, 1);
          }
          break;
      }
  }  
  localStorage.setItem(TableID, JSON.stringify(TempOrder));
}

const openChangeTable = () => {
  if(TableID > 0){
    setShowChangeTable(true);
  }
};

  return (
    <ChangeTableContent.Provider value={{data, TableID, setTableID, setShowData, showChangeTable, setShowChangeTable, }}>
      <div className="formContent">
        <ChangeTable show={showChangeTable}/>
        <UpdateOrder show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ແກ້ໄຂລາຍການອາຫານ</span>
          </div>
          <div className="txtSearch">
            <div className="Location">
              <input placeholder="ຄົ້ນຫາເລກໂຕະ" onChange={(e) => handleInputChange(e)} />
            </div>
            <button className="btn btn-success" onClick={e => openChangeTable(e)} >
                ປ່ຽນເລກໂຕ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ຊື່</th>
                <th>ລາຄາ</th>
                <th>ຈຳນວນ</th>
                <th>ລວມ</th>
                <th>ສະຖານະ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                  if(x.KitchenStatus === 1){
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.LaoName}</td>
                        <td >{currencyFormat(x.Retail_Price)}</td>
                        <td >{currencyFormat(x.Quantity)}</td>
                        <td >{currencyFormat(x.SubTotal)}</td>
                        <td >{}</td>
                        <td >
                          <button className="btn btn-danger" value={x.TemOrderID} type="button" onClick={() => DeleteTemOrder(x.TemOrderID, x.ProductID)}>ລົບ</button>
                        </td>
                      </tr>
                    )
                  } else if (x.KitchenStatus === 2){
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.LaoName}</td>
                        <td >{currencyFormat(x.Retail_Price)}</td>
                        <td >{currencyFormat(x.Quantity)}</td>
                        <td >{currencyFormat(x.SubTotal)}</td>
                        <td >{"Cooking"}</td>
                        <td></td>
                      </tr>
                    )
                  } else if (x.KitchenStatus === 3){
                      return (
                        <tr key={y}>
                          <td >{y + 1}</td>
                          <td >{x.LaoName}</td>
                          <td >{currencyFormat(x.Retail_Price)}</td>
                          <td >{currencyFormat(x.Quantity)}</td>
                          <td >{currencyFormat(x.SubTotal)}</td>
                          <td >{"Finish"}</td>
                          <td></td>
                        </tr>
                      )
                  }
                    
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </ChangeTableContent.Provider>
  );
};
export {ChangeTableContent};
export default EditOrder;
