function SellGiftCardSchema(values) {
    let errors = {};

    if (!values.GiftCardNO)
        errors.GiftCardNO = 'GiftCardNO is required';

    if (!values.Price)
        errors.Price = 'Price is required';

    if (!values.PaidAmount)
        errors.PaidAmount = 'PaidAmount is required';

    if (values.PaidAmount < values.TopUpAmount)
        errors.PaidAmount = 'PaidAmout not enough';

    if (!values.PaymentTypeID)
        errors.PaymentTypeID = 'PaymentTypeID is required';

    return errors;
};

export default SellGiftCardSchema;
