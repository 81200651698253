import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/locationValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { LocationContext } from "../location";

axios.defaults.baseURL = API;

function UpdateLocation(props) {

    const {LocationList, setLocationList, LocationData} = useContext(LocationContext);

    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
    };

    const handleInputLocationNameChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = LocationList.filter((data) => {
            return data.LocationName.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckLocationName", results[0].LocationName);
        else
            handleChange("CheckLocationName", "");
    }

    async function updateLocation() {
        let token = localStorage.getItem("token");
        var data = {
            LocationName: values.LocationName,
            Tell: values.Tell,
            Address: values.Address,
        }
        await axios.put(`/updateLocation/${values.LocationID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {

            for (var i=0; i < LocationList.length; i++) {
                if (LocationList[i].LocationID == values.LocationID) {
                    LocationList[i].LocationName = values.LocationName;
                    LocationList[i].Tell = values.Tell;
                    LocationList[i].Address = values.Address;
                    localStorage.setItem("locationData", JSON.stringify(LocationList));
                    break;
                }
            }  
            setLocationList(LocationList);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("LocationName", "");
        handleChange("Tell", "");
        handleChange("Address", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(updateLocation, validate);

    useEffect(() => {
        LocationData.forEach(value => {
            handleChange("LocationID", value.LocationID);
            handleChange("LocationName", value.LocationName);
            handleChange("Tell", value.Tell);
            handleChange("Address", value.Address);
            handleChange("CheckLocationName", "");
        });
    }, [LocationData]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂສາຂາ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ຊື່ສາຂາ</span>
                                <input className={`input form-control ${errors.LocationName && 'is-invalid'}`} type="text" name="LocationName" onChange={(e) => handleInputLocationNameChange(e)} value={values.LocationName || ''} required />
                                {errors.LocationName && (
                                    <p className="invalid-feedback">{errors.LocationName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ເບີໂທ</span>
                                <input className={`input form-control ${errors.Tell && 'is-invalid'}`} type="text" name="Tell" onChange={(e) => handleInputChange(e)} value={values.Tell || ''} required />
                                {errors.Phone && (
                                    <p className="invalid-feedback">{errors.Tell}</p>
                                )}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ທີ່ຢູ່</span>
                                <textarea className={`input form-control ${errors.Address && 'is-invalid'}`} type="text" name="Address" onChange={(e) => handleInputChange(e)} value={values.Address || ''} required />
                                {errors.Address && (
                                    <p className="invalid-feedback">{errors.Address}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateLocation;
