import React, { useState, useEffect, useRef, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../../css/form.css';
import '../../../../css/MemberAmountReport.css';
import '../../../../css/datepicker.css';
import DatePicker from "react-datepicker";
import axios from "axios";
import { API } from "../../../../services/api";
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import { useReactToPrint } from 'react-to-print';

axios.defaults.baseURL = API;

class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const productList = this.props.TemProductList;
      const today = Date.now();
      let MemberAmount = 0;
      let Point = 0;
      return (
          <div className="MemberReportContent">
              <div className="ReportHeader">
                    <img className="profile-img" src={logo} />
                  <div className="ReportTitle">
                      <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>**********</span>
                      </div>
                      <div className="titleHeader3">
                          <span>ລາຍງານ ຍອດເຫຼືອບັດ</span>
                      </div>
                  </div>
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                      <span className="CountNumber">NO.</span>
                      <span className="MemberNO">ລະຫັດບັດ</span>
                      <span className="Amount">ຍອດເງິນເຫຼືອ</span>
                      <span className="Point">ຄະແນນ</span>
                  </div>
                  <div className="HeadLine">
                    <div className="footer-line"></div>
                  </div>
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                            MemberAmount = Number(x.Amount) +  Number(MemberAmount);
                            Point =  Number(x.Point) +  Number(Point);
                            return (
                                <div className="Items">
                                    <div className="Detail">
                                        <div className="mds">
                                            <span className="NO">{(y + 1) }</span>
                                            <span className="MemberNO">{x.MemberNO}</span>
                                            <span className="Amount">{currencyFormat(x.Amount)}</span>
                                            <span className="Point">{currencyFormat(x.Point)}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                      }
                  </div>
                   <div className="HeadLine">
                    <div className="footer-line"></div>
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="MemberAmount">{currencyFormat(MemberAmount)}</span>
                      <span className="MemberPoint">{currencyFormat(Point)}</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ກວດກາ</span>
                      <span className="StockAdmin"></span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}


function MemberPointReport() {

  const componentRef = useRef(); 

  const [showData, setShowData] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function currencyFormat(num) {
    if (num !== "") {
        return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
}

  async function SearchTransaction() {
    let token = localStorage.getItem("token");
      await axios.get(`/getAllMember`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
  };

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ລາຍງານຍອດເຫຼືອບັດ Member</span>
          </div>
          <div className="txtSearch">
            <button className="btnSearch btn btn-primary" onClick={() => SearchTransaction()}>
                ຄົ້ນຫາ
            </button>
            <button className="btnSearch btn btn-success" onClick={() => handlePrint()}>
                ພິມລາຍງານ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ລະຫັດບັດ</th>
                <th>ຍອດເງິນເຫຼືອ</th>
                <th>ຄະແນນ</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.MemberNO}</td>
                        <td >{currencyFormat(x.Amount)}</td>
                        <td >{currencyFormat(x.Point)}</td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
            />
        </div>
      </div>
  );
};

export default MemberPointReport;
