import React, { useState, createContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './css/admin.css';
import { API } from "./services/api";
import axios from "axios";
import logo from './img/logo/ManeeCafeLogo.jpg';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// route v6 replace  switch to Routes


// import components
// import NavbarComponent from './components/navbar';

// import pages

import Login from './pages/Login';
// import admin page

import Employee from './pages/admin/basicInfo/employee';
import PaymentType from './pages/admin/basicInfo/paymentType';
import Currency from './pages/admin/basicInfo/currency';
import Location from './pages/admin/basicInfo/location';
import UserRole from './pages/admin/basicInfo/role';
import SizeType from './pages/admin/basicInfo/sizeType';
import Status from './pages/admin/basicInfo/status';
import TableNumber from './pages/admin/basicInfo/tableNumber';
import Supplier from './pages/admin/basicInfo/supplier';

// import product 
import ProductType from './pages/admin/product/productType';
import PromotionItemInfo from './pages/admin/product/promotionItemInfo';
import Category from './pages/admin/product/category';
import Brand from './pages/admin/product/brand';
import Product from './pages/admin/product/product';
import Menu from './pages/admin/product/menu';
import SubMenu from './pages/admin/product/subMenu';
import UploadImage from './pages/admin/product/uploadImgProduct';
import ProductReport from './pages/admin/product/ProductReport';
import CreateIngredient from './pages/admin/product/createIngredient';

// import Stock
import PurchaseOrder from './pages/admin/stock/purchaseOrder';
import PendingBill from './pages/admin/stock/pendingBill';
import OrderBill from './pages/admin/stock/orderBill';
import ReceivedBill from './pages/admin/stock/receivedBill';
import InventoryReport from './pages/admin/stock/report/InventoryReport';
import StoreOrderPendingBill from './pages/admin/stock/store_order_pendingBill';
import Store_order_Order_ReciveBill from './pages/admin/stock/store_order_Order_ReciveBill';
import SaleProduct from './pages/admin/stock/SaleProduct';
import StockSaleProductReport from './pages/admin/stock/report/StockSaleProductReport';
import StockSaleProductCashReport from './pages/admin/stock/report/StockSaleProductCashReport';
import StoreOrderReport from './pages/admin/stock/report/StoreOrderReport';
import TakeOutProduct from './pages/admin/stock/TakeOutProduct';
import TakeOutProductBill from './pages/admin/stock/TakeOutProductBill';

// import Store Stock
import StorePurchaseOrder from './pages/admin/store_stock/store_purchaseOrder';
import StorePendingBill from './pages/admin/store_stock/store_pendingBill';
import StoreOrderBill from './pages/admin/store_stock/store_orderBill';
import StoreReceivedBill from './pages/admin/store_stock/store_receivedBill';
import StoreInventoryReport from './pages/admin/store_stock/report/store_inventoryReport';
import StoreTakeOutProduct from './pages/admin/store_stock/StoreTakeOutProduct';
import StoreTakeOutProductBill from './pages/admin/store_stock/StoreTakeOutProductBill';

// Import Member
import Member from './pages/admin/member/member';
import TopUpReport from './pages/admin/member/report/topupreport';
import BillReport from './pages/admin/member/report/BillReport';
import CancelTopUpBillReport from './pages/admin/member/report/CancelTopUpBillReport';
import MemberTransactionReport from './pages/admin/member/report/MemberTransactionReport';
import MemberPointReport from './pages/admin/member/report/MemberPointReport';


// Import Member
import RewardCard from './pages/admin/member/rewardCard';
import TopUp from './pages/admin/member/topup';
import CancelTopUpBill from './pages/admin/member/cancelTopUpBill';

//Import GiftCard 
import GiftCard from './pages/admin/member/giftCard';
import SellGiftCard from './pages/admin/member/sellgiftcard';


// import POS
import POS from './pages/admin/pos/pos';
import CancelBill from './pages/admin/pos/cancelBill';
import PromoItem from './pages/admin/pos/promoItem';
import TransactionReport from './pages/admin/pos/report/TransactionReport';
import CancelBillReport from './pages/admin/pos/report/CancelBillReport';
import CashReport from './pages/admin/pos/report/CashReport';
import SaleProductReport from './pages/admin/pos/report/SaleProductReport';
import TaxReport from './pages/admin/pos/report/TaxReport';
import ExchangeReport from './pages/admin/pos/report/ExchangeReport';
import EditOrder from './pages/admin/pos/editOrder';
import EditBillPayment from './pages/admin/pos/EditBillPayment';


// import RegisterTable from './pages/admin/pos/registertable';

import KitchenOrder from './pages/admin/pos/KitchenOrder';

// import customer page
import Customer_home from './pages/customer/customer_home';

const UserContext = createContext();
const AlertContext = createContext();
const ProgressBarContext = createContext();

function App() {
  const [ShowProgressBar, setShowProgressBar] = useState(false);
  const [Progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [login, setLogin] = useState(false);
  const [userID, setUserID] = useState();
  const [UserName, setUserName] = useState();
  const [LocationName, setLocationName] = useState();
  const [Role, setRole] = useState();
  const [LocationID, setLocationID] = useState();
  const [SubMenuList, setSubMenuList] = useState([]);
  const [KitchenList, setKitchenList] = useState();

  useEffect(() => {
    let token = localStorage.getItem("token");
    let userData = localStorage.getItem("data");
    if (token) {
      let data = { token: `MR_ADMIN ${token}` };
      axios
        .post(`${API}/verify-token`, data).then((response) => {
          if (response?.status === 200) {
            setLogin(true);
            let userRole = JSON.parse(userData);
            setUserID(userRole.EmployeeID);
            setUserName(userRole.Name);
            setLocationName(userRole.LocationName);
            setRole(userRole.Role);
            setLocationID(userRole.LocationID)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  if (!login) {
    return <Login setLogin={setLogin} setUserID={setUserID} setLocationID={setLocationID} setLocationName={setLocationName} setUserName={setUserName} setRole={setRole} />;
  } 

  const navbarMenuList = ["ຂໍ້ມູນພື້ນຖ້ານ", "ຂໍ້ມູນສິນຄ້າ", "ຂໍ້ມູນສາງ", "ຂໍ້ມູນສາງຮ້ານ", "Member", "POS","SignOut"];
  const sideBardMenuList = [
    { location: 'ສາຂາ', UserRole: 'ສິດນຳໃຊ້', status: 'ສະຖານະ', supplier: 'ຜູ້ສະໜອງ', employee: 'ພະນັກງານ', paymentType: 'ປະເພດຈ່າຍ', sizeType: 'ປະເພດຂະໜາດ', TableNo: 'ສ້າງເລກໂຕະ'},
    { ProductType: "ປະເພດສິນຄ້າ", Category: "ໝວດສິນຄ້າ", Brand: "ຫຍີ່ຫໍ້ສິນຄ້າ", Menu: "ສ້າງເມນູຂາຍ", SubMenu: "ສ້າງເມນູຂາຍຍ່ອຍ", Product: "ສ້າງຂໍ້ມູນສິນຄ້າ", ProductReport:"ລາຍງານຂໍ້ມູນສິນຄ້າ", PromotionItemInfo: "ສ້າງຂໍ້ມູນແລກສິນຄ້າ", CreateIngredients: "ສ້າງສ່ວນພະສົມ", UploadImage: "ເພີ່ມຮູບສິນຄ້າ"},
    { PurchaseOrder: 'ສ້າງໃບສັ່ງຊື້', PendingBill: 'ບິນລໍຖ້າສັ່ງຊື້', OrderBill: 'ບິນທີ່ສັ່ງຊື້', ReceivedBill: 'ບິນທີ່ຮັບເຄື່ອງເຂົ້າສາງ', InventoryReport: 'ລາຍງານຈຳນວນສິນຄ້າ', StoreOrderPendingBill: "ບິນຮ້ານເບີກເຄື່ອງ", Store_order_Order_ReciveBill:"ບິນຮ້ານທີ່ຮັບເຄື່ອງ", TakeOutProduct: "ນຳເຄື່ອງອອກສາງ", TakeOutProductBill: "ບິນນຳເຄື່ອງອອກສາງ", StoreOrderReport: "ລາຍງານຮ້ານເບີກເຄື່ອງ"},
    { StorePurchaseOrder: 'ສ້າງໃບເບີກເຄື່ອງ', StorePendingBill: 'ບິນລໍຖ້າເບີກເຄື່ອງ', StoreOrderBill: 'ບິນລໍຖ້າຮັບເຄື່ອງ', StoreReceivedBill: 'ບິນທີ່ຮັບເຄື່ອງເຂົ້າຮ້ານ',StoreTakeOutProduct: "ນຳເຄື່ອງອອກສາງ", StoreInventoryReport: 'ລາຍງານຈຳນວນສິນຄ້າ', StoreTakeOutProductBill: 'ລາຍງານນຳເຄື່ອງອອກສາງຮ້ານ'},
    { Member: 'ສ້າງ Member', TopUp: 'ເຕີມບັດ Member', CancelTopUpBill: 'ຍົກເລີກບິນເຕີມບັດ', TopUpReport: 'ລາຍງານເຕີມບັດ', BillReport: 'ລາຍງານບິນເຕີມບັດ', CancelTopUpBillReport: 'ລາຍງານບິນເຕີມບັດທີ່ຍົກເລີກ', MemberTransactionReport: 'ລາຍງານໃຊ້ບັດ', MemberPointReport: "ລາຍງານຍອດເຫຼືອບັດ"},
    { POS: 'ຂາຍເຄື່ອງ', EditOrder: 'ແກ້ໄຂລາຍການອາຫານ', CancelBill: 'ຍົກເລີກບິນ', EditBillPayment: "ແກ້ໄຂປະເພດຈ່າຍບິນ", PromoItem: "ແລກເຄື່ອງ", TransactionReport: 'ລາຍງານບິນຂາຍ', CancelBillReport: 'ລາຍງານບິນຍົກເລີກ', CashReport: 'ລາຍງານສະຫລຸບເງິນ', SaleProductReport: "ລາຍງານຂາຍສິນຄ້າ", TaxReport:'ລາຍງານອາກອນ', ExchangeReport: "ລາຍງານແລກເຄື່ອງ", KitchenOrder: "ບິນຄົວ"},
  ];

  const handleClick = (e) => {
    setSubMenuList(sideBardMenuList[e]);
    if(e === 6)
      signout();
  }

  const signout = () => {
     localStorage.removeItem('data');
     localStorage.removeItem('userData');
     localStorage.removeItem('token');
     localStorage.removeItem('productData');
     localStorage.removeItem('paymentTypeData');
     localStorage.removeItem('productTypeData');
     localStorage.removeItem('categoryData');
     localStorage.removeItem('currencyData');
     localStorage.removeItem('brandData');
     localStorage.removeItem('sizeTypeData');
     localStorage.removeItem('supplierData');
     localStorage.removeItem('locationData');
     localStorage.removeItem('statusData');
     localStorage.removeItem('ProductTypeData');
     localStorage.removeItem('purchaseOrderData');
     localStorage.removeItem('roleData');
     localStorage.removeItem('tableNumberData');
     localStorage.removeItem('memberData');
     localStorage.removeItem('discountData');
     localStorage.removeItem('productBySubMenuData');
     localStorage.removeItem('rewardcardData');
     localStorage.removeItem('subMenuData');
     localStorage.removeItem('menuData');
     setLogin(false);
  };

  if(Role === "Admin" || Role ==="Cashier" || Role === "StoreStock"){
    return (
      <AlertContext.Provider value={{showAlert, setShowAlert, errorMessage, setErrorMessage}}>
        <UserContext.Provider value={{ UserName, userID, LocationID, KitchenList, LocationName, login, setShowAlert, setErrorMessage, Role}}>
          <ProgressBarContext.Provider value={{ShowProgressBar, setShowProgressBar, Progress, setProgress}}>
            <div className="admin_container">
                <div className="navbarContent">
                  {
                    navbarMenuList && navbarMenuList.map((value, key) =>
                      {
                        if(Role === "Admin"){
                          if(LocationName === "ສາງໃຫຍ່"){
                            return (
                            <div key={key} className="navItem" onClick={() => handleClick(key)} tabIndex={key}>
                              {value}
                            </div>)
                          } else {
                              if(value !== "ຂໍ້ມູນສາງ")
                              {
                                return (<div key={key} className="navItem" onClick={() => handleClick(key)} tabIndex={key}>
                                {value}
                                </div>)
                              }
                          }
                        } else if(Role === "StoreStock") {
                          if(value === "ຂໍ້ມູນສາງຮ້ານ" || value === "SignOut")
                          {
                            return (<div key={key} className="navItem" onClick={() => handleClick(key)} tabIndex={key}>
                            {value}
                            </div>)
                          }
                        } else {
                          if(LocationName ==="BIC"){
                            if( value === "Member" ||value ==="POS" || value === "SignOut")
                            {
                              return (<div key={key} className="navItem" onClick={() => handleClick(key)} tabIndex={key}>
                              {value}
                                </div>)
                            }
                          } else {
                            if(value === "Member" || value ==="POS" || value === "SignOut")
                            {
                              return (<div key={key} className="navItem" onClick={() => handleClick(key)} tabIndex={key}>
                              {value}
                                </div>)
                            }
                          }
                        }
                      }
                    )
                  }
                </div>
                <div className="sidebarContent">
                  <div className="imglogo">
                    <img className="logo" src={logo} />
                  </div>
                  <div className="sideBarBody">
                    {
                      SubMenuList && Object.keys(SubMenuList).map((x, key) =>
                        {
                          if(Role === "Admin"){
                              return (
                                <Link key={key} className="link" to={{ pathname: "/" + x }} >
                                  <div className="sideBarItem" tabIndex={key}>
                                    <div className="submenutext">
                                      {SubMenuList[x]}
                                    </div>
                                  </div>
                                </Link>
                              )
                          } else {
                            if(LocationName ==="BIC"){
                              if(SubMenuList[x] !== "ບິນຄົວ" && SubMenuList[x] !== "ລາຍງານແລກເຄື່ອງ" && SubMenuList[x] !== "ແລກເຄື່ອງ" && SubMenuList[x] !== "ຍົກເລີກບິນເຕີມບັດ" && SubMenuList[x] !== "ລາຍງານບິນເຕີມບັດທີ່ຍົກເລີກ" && SubMenuList[x] !=="ຍົກເລີກບິນ" && SubMenuList[x] !== "ລາຍງານບິນຍົກເລີກ" )
                              {
                                return (
                                  <Link key={key} className="link" to={{ pathname: "/" + x }} >
                                    <div className="sideBarItem" tabIndex={key}>
                                      <div className="submenutext">
                                        {SubMenuList[x]}
                                      </div>
                                    </div>
                                  </Link>
                                )
                              }
                            } else {
                              if(SubMenuList[x] !== "ຍົກເລີກບິນເຕີມບັດ" && SubMenuList[x] !== "ລາຍງານບິນເຕີມບັດທີ່ຍົກເລີກ" && SubMenuList[x] !=="ຍົກເລີກບິນ" && SubMenuList[x] !== "ລາຍງານບິນຍົກເລີກ" && SubMenuList[x] !== "ລາຍງານອາກອນ")
                              {
                                return (
                                  <Link key={key} className="link" to={{ pathname: "/" + x }} >
                                    <div className="sideBarItem" tabIndex={key}>
                                      <div className="submenutext">
                                        {SubMenuList[x]}
                                      </div>
                                    </div>
                                  </Link>
                                )
                              }
                            }
                          }
                        }
                      )
                    }
                  </div>
                </div>
                <div className="appContent">
                    <Routes>
                        <Route exact path='/employee' element={<Employee/>}/>
                        <Route exact path='/paymentType' element={<PaymentType/>}/>
                        <Route exact path='/Currency' element={<Currency/>}/>
                        <Route exact path='/location' element={<Location/>}/>
                        <Route exact path='/UserRole' element={<UserRole/>}/>
                        <Route exact path='/sizeType' element={<SizeType/>}/>
                        <Route exact path='/status' element={<Status/>}/>
                        <Route exact path='/TableNo' element={<TableNumber/>}/>
                        <Route exact path='/supplier' element={<Supplier/>}/>

                        <Route exact path='/ProductType' element={<ProductType/>}/>
                        <Route exact path='/Category' element={<Category/>}/>
                        <Route exact path='/Brand' element={<Brand/>}/>
                        <Route exact path='/Menu' element={<Menu/>}/>
                        <Route exact path='/SubMenu' element={<SubMenu/>}/>
                        <Route exact path='/Product' element={<Product/>}/>
                        <Route exact path='/PromotionItemInfo' element={<PromotionItemInfo/>}/>
                        <Route exact path='/ProductReport' element={<ProductReport/>}/>
                        <Route exact path='/UploadImage' element={<UploadImage/>}/>
                        <Route exact path='/CreateIngredients' element={<CreateIngredient/>}/>
                        

                        <Route exact path='/PurchaseOrder' element={<PurchaseOrder/>}/>
                        <Route exact path='/PendingBill' element={<PendingBill/>}/>
                        <Route exact path='/OrderBill' element={<OrderBill/>}/>
                        <Route exact path='/ReceivedBill' element={<ReceivedBill/>}/>
                        <Route exact path='/InventoryReport' element={<InventoryReport/>}/>
                        <Route exact path='/StoreOrderReport' element={<StoreOrderReport/>}/>
                        
                        <Route exact path='/StoreOrderPendingBill' element={<StoreOrderPendingBill/>}/>
                        <Route exact path='/Store_order_Order_ReciveBill' element={<Store_order_Order_ReciveBill/>}/>
                        <Route exact path='/SaleProduct' element={<SaleProduct/>}/>
                        <Route exact path='/StockSaleProductReport' element={<StockSaleProductReport/>}/>
                        <Route exact path='/StockSaleProductCashReport' element={<StockSaleProductCashReport/>}/>
                        <Route exact path='/TakeOutProduct' element={<TakeOutProduct/>}/>
                        <Route exact path='/TakeOutProductBill' element={<TakeOutProductBill/>}/>
                        
                        <Route exact path='/StorePurchaseOrder' element={<StorePurchaseOrder/>}/>
                        <Route exact path='/StorePendingBill' element={<StorePendingBill/>}/>
                        <Route exact path='/StoreOrderBill' element={<StoreOrderBill/>}/>
                        <Route exact path='/StoreReceivedBill' element={<StoreReceivedBill/>}/>
                        <Route exact path='/StoreInventoryReport' element={<StoreInventoryReport/>}/>
                        <Route exact path='/StoreTakeOutProduct' element={<StoreTakeOutProduct/>}/>
                        <Route exact path='/StoreTakeOutProductBill' element={<StoreTakeOutProductBill/>}/>
                      
                        <Route exact path='/Member' element={<Member/>}/>
                        <Route exact path='/TopUp' element={<TopUp/>}/>
                        <Route exact path='/CancelTopUpBill' element={<CancelTopUpBill/>}/>
                        
                        <Route exact path='/TopUpReport' element={<TopUpReport/>}/>
                        <Route exact path='/BillReport' element={<BillReport/>}/>
                        <Route exact path='/CancelTopUpBillReport' element={<CancelTopUpBillReport/>}/> 
                        <Route exact path='/MemberTransactionReport' element={<MemberTransactionReport/>}/> 
                        <Route exact path='/MemberPointReport' element={<MemberPointReport/>}/> 
                        
                        <Route exact path='/RewardCard' element={<RewardCard/>}/>
                        <Route exact path='/GiftCard' element={<GiftCard/>}/>
                        <Route exact path='/SellGiftCard' element={<SellGiftCard/>}/>

                        <Route exact path='/POS' element={<POS/>}/>
                        <Route exact path='/EditOrder' element={<EditOrder/>}/>
                        <Route exact path='/CancelBill' element={<CancelBill/>}/>
                        <Route exact path='/PromoItem' element={<PromoItem/>}/>
                        <Route exact path='/TransactionReport' element={<TransactionReport/>}/>
                        <Route exact path='/CancelBillReport' element={<CancelBillReport/>}/>
                        <Route exact path='/CashReport' element={<CashReport/>}/>
                        <Route exact path='/SaleProductReport' element={<SaleProductReport/>}/>
                        <Route exact path='/TaxReport' element={<TaxReport/>}/>
                        <Route exact path='/ExchangeReport' element={<ExchangeReport/>}/>
                        <Route exact path='/KitchenOrder' element={<KitchenOrder/>}/>
                        <Route exact path='/EditBillPayment' element={<EditBillPayment/>}/>
                        
                        
                    </Routes> 
                </div>
            </div>
            </ProgressBarContext.Provider>
        </UserContext.Provider>
      </AlertContext.Provider>
    );
  } else {
    return (
      <div>
        <UserContext.Provider value={{ UserName, userID, login}}>
          <Customer_home />
        </UserContext.Provider>
      </div>
        
    );
  }
}

export {AlertContext, UserContext, ProgressBarContext};
export default App;
