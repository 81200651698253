
function CreateTakeOutProductValidate(values) {

    let errors = {};

    if (!values.TakeOutStatusID)
        errors.TakeOutStatusID = 'TakeOutStatusID is required';

    return errors;
};

export default CreateTakeOutProductValidate;
