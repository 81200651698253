import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/employeeValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";

import { DataContext } from "../employee";

axios.defaults.baseURL = API;

function UpdateEmployee(props) {

    const {dataList, setDataList, Data, LocationList, RoleList } = useContext(DataContext);

    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
    };

    const handleInputNameChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.UserName.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckUserName", results[0].UserName);
        else
            handleChange("CheckUserName", "");
    }

    const handleSelectLocation = (e) => {
        var data = LocationList.filter(function (item) {
            return item.LocationName === e.target.value
        })
        if (data.length > 0) {
            handleChange("LocationName", data[0].LocationName);
            handleChange("LocationID", data[0].LocationID);
        } else {
            handleChange("LocationName", "");
            handleChange("LocationID", "");
        }
    };

    const handleSelectRole = (e) => {
        var data = RoleList.filter(function (item) {
            return item.Role === e.target.value
        })
        if (data.length > 0) {
            handleChange("Role", data[0].Role);
            handleChange("RoleID", data[0].RoleID);
        } else {
            handleChange("RoleID", "");
            handleChange("Role", "");
        }
    };

    async function updateLocation() {
        let token = localStorage.getItem("token");
        var data = {
            Name: values.Name,
            SurName: values.SurName,
            Tell: values.Tell,
            UserName: values.UserName,
            Password: values.Password,
            LocationID: values.LocationID,
            RoleID: values.RoleID,
        }
        await axios.put(`/update-user/${values.EmployeeID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {

            for (var i=0; i < dataList.length; i++) {
                if (dataList[i].EmployeeID === values.EmployeeID) {
                    dataList[i].Name = values.Name;
                    dataList[i].SurName = values.SurName;
                    dataList[i].Tell = values.Tell;
                    dataList[i].UserName = values.UserName;
                    dataList[i].Password = values.Password;
                    dataList[i].LocationID = values.LocationID;
                    dataList[i].LocationName = values.LocationName;
                    dataList[i].RoleID = values.RoleID;
                    dataList[i].Role = values.Role;
                    localStorage.setItem("userData", JSON.stringify(dataList));
                    break;
                }
            }  
            setDataList(dataList);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("EmployeeID", "");
        handleChange("Name", "");
        handleChange("SurName", "");
        handleChange("UserName", "");
        handleChange("LocationID", "");
        handleChange("LocationName", "");
        handleChange("RoleID", "");
        handleChange("Role", "");
        handleChange("CheckUserName", "");
        handleChange("Password", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(updateLocation, validate);

    useEffect(() => {
        Data.forEach(value => {
            handleChange("EmployeeID", value.EmployeeID);
            handleChange("Name", value.Name);
            handleChange("SurName", value.SurName);
            handleChange("UserName", value.UserName);
            handleChange("LocationID", value.LocationID);
            handleChange("Tell", value.Tell);
            handleChange("LocationName", value.LocationName);
            handleChange("RoleID", value.RoleID);
            handleChange("Role", value.Role);
            handleChange("CheckUserName", "");
        });
    }, [Data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂຂໍ້ມູນພະນັກງານ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ຊື້</span>
                                <input className={`input form-control ${errors.Name && 'is-invalid'}`} type="text" name="Name" onChange={(e) => handleInputChange(e)} value={values.Name || ''} required />
                                {errors.Name && (
                                    <p className="invalid-feedback">{errors.Name}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ນາມສະກູນ</span>
                                <input className={`input form-control ${errors.SurName && 'is-invalid'}`} type="text" Name="SurName" onChange={(e) => handleInputChange(e)} value={values.SurName || ''} required />
                                {errors.SurName && (
                                    <p className="invalid-feedback">{errors.SurName}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>UserName:</span>
                                <input className={`input form-control ${errors.UserName && 'is-invalid'}`} type="text" Name="UserName" onChange={(e) => handleInputNameChange(e)} value={values.UserName || ''} required />
                                {errors.UserName && (
                                    <p className="invalid-feedback">{errors.UserName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>Password:</span>
                                <input className={`input form-control ${errors.Password && 'is-invalid'}`} type="text" Name="Password" onChange={(e) => handleInputChange(e)} value={values.Password || ''} required />
                                {errors.Password && (
                                    <p className="invalid-feedback">{errors.Password}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <label>ສາຂາ: </label>
                                <select Name="LocationID" onChange={(e) => handleSelectLocation(e)}
                                    className={`input form-control ${errors.LocationID && 'is-invalid'}`}>
                                    { LocationList && LocationList.map((x, y) => {
                                        if (x.LocationName === values.LocationName) {
                                            return (
                                                <option key={x.LocationID} value={x.LocationName} selected>
                                                    {x.LocationName}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.LocationID} value={x.LocationName}>
                                                    {x.LocationName}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ສິດນຳໃຊ້: </label>
                                <select Name="RoleID" onChange={(e) => handleSelectRole(e)}
                                    className={`input form-control ${errors.RoleID && 'is-invalid'}`}>
                                    { RoleList && RoleList.map((x, y) => {
                                        if (x.Role === values.Role) {
                                            return (
                                                <option key={x.RoleID} value={x.Role} selected>
                                                    {x.Role}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.RoleID} value={x.Role}>
                                                    {x.Role}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ເບີໂທ:</span>
                                <input className={`input form-control ${errors.Tell && 'is-invalid'}`} type="text" Name="Tell" onChange={(e) => handleInputChange(e)} value={values.Tell || ''} required />
                                {errors.Tell && (
                                    <p className="invalid-feedback">{errors.Tell}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateEmployee;
