
function MenuValidate(values) {

    let errors = {};

    if (!values.ProductTypeID)
        errors.ProductTypeID = 'ProductType is required';

    return errors;
};

export default MenuValidate;
