import React, { useContext, useEffect, useState } from "react";
import useForm from "../../../services/UseForm";
import validate from '../../../validations/admin/pos/registertableValidate';
import '../../../css/changeTable.css';
import axios from "axios";
import { API } from "../../../services/api";
import { AlertContext, UserContext } from "../../../App";
import { ChangeTableContent } from "./editOrder";

axios.defaults.baseURL = API;

function ChangeTable() {
    const {userID, LocationID} = useContext(UserContext);

    const {TableID, setTableID, showChangeTable,setShowData, setShowChangeTable} = useContext(ChangeTableContent);
    const [userList, setUserList] = useState([]);
    const [tableList, setTableList] = useState([]);

    const handleSelectUserName = (e) => {
        var data = userList.filter(function (item) {
            return item.Name == e.target.value
        })
        if (data.length > 0) {
            handleChange("UserName", data[0].Name);
            handleChange("EmployeeID", data[0].EmployeeID);
        } else {
            handleChange("UserName", "");
            handleChange("EmployeeID", "");
        }
    };
    
    const handleSelectTableNumber = (e) => {
        var data = tableList.filter(function (item) {
            return item.TableNumber == e.target.value
        })
        if (data.length > 0) {
            handleChange("TableNumber", data[0].TableNumber);
            handleChange("TableID", data[0].TableID);
        } else {
            handleChange("TableNumber", "");
            handleChange("TableID", "");
        }
    };

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            TableID: values.TableID,
            CreateByID: values.EmployeeID,
            ServerID: values.EmployeeID,
            StatusID: 2
        }
        await axios.put(`/changeTable/${TableID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            localStorage.removeItem(TableID);
            setShowData([]);
            ClearData();
            setShowChangeTable(false);
        }).catch((err) => {
            console.log(err);
        });
    };


      async function getAllTableNumber() {
        let token = localStorage.getItem("token");
        await axios.get(`/getAlltableNumberForPos/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setTableList(temOrder);
          } else {
            setTableList([]);
          }
        }).catch((err) => {
            setTableList([]);
            console.log(err);
        });
    }

    const ClearData = () => {
        handleChange("UserNameID", "");
        handleChange("UserName", "");
        handleChange("TableNumber", "");
        handleChange("TableID", "");
        setTableID();
    }

    async function getUserByLocationID() {
        let token = localStorage.getItem("token");
        await axios.get(`/getUserByLocationID/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setUserList(temOrder);
          } else {
            setUserList([]);
          }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getUserByLocationID();
        getAllTableNumber();
    }, []);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: showChangeTable ? 'flex' : 'none' }} className="changeTableForm">
            <div className="content">
                <div className="Header">
                    <span>ປ່ຽນເລກໂຕ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <label>ພະນັກງານ: </label>
                                <select name="EmployeeID" onChange={(e) => handleSelectUserName(e)}
                                    className={`input form-control ${errors.EmployeeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກພະນັກງານ'}
                                    </option>
                                    {   userList && userList.map(item => (
                                        <option
                                            key={item.EmployeeID}
                                            value={item.Name}
                                        >
                                            {item.Name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ເລກໂຕະ: </label>
                                <select name="TableID" onChange={(e) => handleSelectTableNumber(e)}
                                    className={`input form-control ${errors.TableID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກເລກໂຕະ'}
                                    </option>
                                    {   tableList && tableList.map(item => (
                                        <option
                                            key={item.TableID}
                                            value={item.TableNumber}
                                        >
                                            {item.TableNumber}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => setShowChangeTable(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeTable;
