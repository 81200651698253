import React, { useState, useEffect, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateBrand from "./create/createBrand";
import UpdateBrand from "./update/updateBrand";
import axios from "axios";
import { API } from "../../../services/api";

axios.defaults.baseURL = API;

const BrandContext = createContext();

function Brand() {

  const [dataList, setDataList] = useState([]);
  const [ProductTypeList, setProductTypeList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [showData, setShowData] = useState([]);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { value } = event.target;
    if(value){
      var results = [];
      results = dataList.filter((data) => {
          return data.Brand.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
      setShowData(results);
      else 
      setShowData(dataList);
    } else 
    setShowData(dataList);
  }

  const openUpdate = (ProductTypeID, ProductType, CategoryID, Category, BrandID, Brand) => {
    var list = [];
    list.push({
      ProductTypeID: ProductTypeID,
      ProductType: ProductType,
      CategoryID: CategoryID,
      Category: Category,
      BrandID: BrandID,
      Brand: Brand,
    });
    setData(list);
    setShowUpdate(true);
  };

  async function getAllCategory() {
    axios
      .get("/getAllCategory")
      .then((response) => {
        let Data = response?.data;
        setCategoryList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllProductType() {
    axios
      .get("/getAllProductType")
      .then((response) => {
        let Data = response?.data;
        setProductTypeList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllBrand() {
    axios
      .get("/getAllBrand")
      .then((response) => {
        let Data = response?.data;
        setDataList(Data);
        setShowData(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(() => {
    getAllBrand();
    getAllCategory();
    getAllProductType();
  }, []);

  return (
    <BrandContext.Provider value={{dataList, setDataList, ProductTypeList, CategoryList, data, setShowData}}>
      <div className="formContent">
        <CreateBrand show={show} onClick={handleClose}/>
        <UpdateBrand show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ຫຍີ່ຫໍ້</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ປະເພດສິນຄ້າ</th>
                <th>ໝວດສິນຄ້າ</th>
                <th>ຫຍີ່ຫໍ້</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.ProductType}</td>
                        <td >{x.Category}</td>
                        <td >{x.Brand}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.ProductTypeID} type="button" onClick={() => openUpdate(x.ProductTypeID, x.ProductType, x.CategoryID, x.Category, x.BrandID, x.Brand)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </BrandContext.Provider>
  );
};

export {BrandContext};
export default Brand;
