
function RewardCardValidate(values) {

    let errors = {};
    if (!values.FirstName)
        errors.FirstName = 'FirstName is required';

    if (!values.LastName)
        errors.LastName = 'LastName Type is required';

    if (!values.RewardCardNO)
        errors.RewardCardNO = 'RewardCardNO is required';
    else if (values.CheckRewardCardNO !== "")
        errors.RewardCardNO = 'RewardCardNO Already Exit';

    if (!values.Email)
        errors.Email = 'Email Type is required';

    if (!values.TEL)
        errors.TEL = 'TEL is required';
    else if (values.TEL.length < 8)
        errors.TEL = 'TEL must be 2 or more characters';
    else if (values.TEL.length > 15)
        errors.TEL = 'TEL must not exceed 15 characters';
   
    if (!values.Identification)
        errors.Identification = 'Identification is required';

    return errors;
};
export default RewardCardValidate;
