
function EmployeeValidate(values) {

    let errors = {};
    if (!values.Name)
        errors.Name = 'name is required';

    if (!values.UserName)
        errors.UserName = 'UserName is required';
    else if (values.CheckUserName !== "")
        errors.UserName = 'UserName Already Exit';

    if (!values.SurName)
        errors.SurName = 'SurName Type is required';

    if (!values.Password)
        errors.Password = 'Password Type is required';
    else if (values.Password.length < 8)
        errors.Password = 'Tell must be 6 or more characters';

    if (!values.Tell)
        errors.Tell = 'Tell is required';
    else if (values.Tell.length < 8)
        errors.Tell = 'Tell must be 2 or more characters';
    else if (values.Tell.length > 15)
        errors.Tell = 'Tell must not exceed 15 characters';
   
    if (!values.LocationID)
        errors.LocationID = 'Tell is required';

    if (!values.RoleID)
        errors.RoleID = 'RoleID is required';

    return errors;
};
export default EmployeeValidate;
