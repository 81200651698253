import React, { useState, useContext, useRef, useEffect } from "react";
import axios from "axios";
import { API } from "../../../../services/api";
import '../../../../css/posOrder.css';
import '../../../../css/BillForKitchen.css';
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import { POSContent } from "../pos";
import { useReactToPrint } from 'react-to-print';
import { UserContext } from "../../../../App";
import TableNumber from "../../basicInfo/tableNumber";

axios.defaults.baseURL = API;

class PrintFood extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const productList = this.props.TemProductList;
        const billDetails = this.props.BillDetails;
        const today = Date.now();
        const customerName = this.props.custmer;
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="KitchenBillContent">
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Manee Cafe</span>
                    </div>
                    <div className="TableNo">
                        <span>Table # {billDetails.TableNo}</span>
                    </div>
                    <div className="Cashier">
                        <span>Cashier: {billDetails.cashier}</span>
                    </div>
                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="BillTitle">
                        <span>Food</span>
                    </div>
                    <div className="BillTitle">
                        <span>{billDetails.TakeAway}</span> 
                    </div>
                </div>
                <div className="BillBody">
                    <div className="BodyHeader">
                    <span className="CountNumber">NO.</span>
                        <span className="ProductName">Price</span>
                        <span className="Qty">Qty</span>
                        <span className="Price">Total</span>
                    </div>
                    <div className="HeadLine">
                        <div className="footer-line"/>
                        <div className="footer-line"/>
                    </div>
                    <div className="BodyDetails">
                        {
                            productList && productList.map((x, y) => {
                                if(x.ProductType === "Food" || x.ProductType === "FOOD")
                                {
                                    if(x.PrintStatus === "N"){
                                        return (
                                            <div className="Items">
                                                <div className="Detail">
                                                    <div className="mds">
                                                        <span className="NO">{(y + 1) }</span>
                                                        <span className="Price">{currencyFormat(x.Retail_Price)}</span>
                                                        <span className="Qty">{currencyFormat(x.Quantity)}</span>
                                                        <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                                    </div>
                                                </div>
                                                <div className="Name">
                                                    <span className="ProductName">{x.LaoName}</span>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        if(x.QuantityPrint > 0){
                                            return (
                                                <div className="Items">
                                                    <div className="Detail">
                                                        <div className="mds">
                                                            <span className="NO">{(y + 1) }</span>
                                                            <span className="Price">{currencyFormat(x.Retail_Price)}</span>
                                                            <span className="Qty">{currencyFormat(x.QuantityPrint)}</span>
                                                            <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="Name">
                                                        <span className="ProductName">{x.LaoName}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                }
                            })
                        }
                    </div>

                </div>
            </div>
        );
    }
}

class PrintDrink extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const productList = this.props.TemProductList;
        const billDetails = this.props.BillDetails;
        const today = Date.now();
        const customerName = this.props.custmer;
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="KitchenBillContent">
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Manee Cafe</span>
                    </div>
                    <div className="TableNo">
                        <span>Table # {billDetails.TableNo}</span>
                    </div>
                    <div className="Cashier">
                        <span>Server: {billDetails.cashier}</span>
                    </div>
                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="BillTitle">
                        <span>Drink</span>
                    </div>
                    <div className="BillTitle">
                        <span>{billDetails.TakeAway}</span> 
                    </div>
                </div>
                <div className="BillBody">
                    <div className="BodyHeader">
                    <span className="CountNumber">NO.</span>
                        <span className="ProductName">Price</span>
                        <span className="Qty">Qty</span>
                        <span className="Price">Total</span>
                    </div>
                    <div className="HeadLine">
                        <div className="footer-line"/>
                        <div className="footer-line"/>
                    </div>
                    <div className="BodyDetails">
                        {
                            productList && productList.map((x, y) => {
                                if(x.ProductType == "Drink" || x.ProductType == "DRINK" || x.ProductType == "Coffee" || x.ProductType == "COFFEE" || x.ProductType == "Bakery" || x.ProductType == "BAKERY")
                                {
                                    if(x.PrintStatus === "N"){
                                        return (
                                            <div className="Items">
                                                <div className="Detail">
                                                    <div className="mds">
                                                        <span className="NO">{(y + 1) }</span>
                                                        <span className="Price">{currencyFormat(x.Retail_Price)}</span>
                                                        <span className="Qty">{currencyFormat(x.Quantity)}</span>
                                                        <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                                    </div>
                                                </div>
                                                <div className="Name">
                                                    <span className="ProductName">{x.LaoName}</span>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        if(x.QuantityPrint > 0){
                                            return (
                                                <div className="Items">
                                                    <div className="Detail">
                                                        <div className="mds">
                                                            <span className="NO">{(y + 1) }</span>
                                                            <span className="Price">{currencyFormat(x.Retail_Price)}</span>
                                                            <span className="Qty">{currencyFormat(x.QuantityPrint)}</span>
                                                            <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="Name">
                                                        <span className="ProductName">{x.LaoName}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                }
                            })
                        }
                    </div>

                </div>
            </div>
        );
    }
}

function PosOrder() {

    const {userID, LocationID, LocationName, Role} = useContext(UserContext);
    const {tableNumber, TableID, setShowPayment, showOrder, TempOrder, setTempOrder, setShowOrder, serverName, 
           subTotal, setSubTotal, setNetTotal, setTax, showAlert, setShowAlert, setTableList, errorMessage, setErrorMessage} = useContext(POSContent);

    const componentRefFood = useRef();
    const componentRefDrink = useRef();

    const [menuList, setMenuList] = useState([]);
    const [subMenuData, setSubMenuData] = useState([]);
    const [productBySubMenuData, setProductBySubMenuData] = useState([]);
    const [subMenuList, setSubMenuList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [TakeAway, setTakeAway] = useState("Dine In (ກິນຢູ່ຮ້ານ)");
    const [tw, setTw] = useState(false);

    const [Barcode, setBarcode] = useState([]);
    const [Quantity, setQuantity] = useState(1);

    const [ProductType, setProductType] = useState([]);

    function currencyFormat(num) {
        if (num !== "" && num > 0) {
            return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }

    async function handleClickMenuToGetSubmenu(MenuID) {
        let token = localStorage.getItem("token");
        await axios.get(`/getSubMenuByMenuID/${MenuID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setSubMenuList(temOrder);
            setProductList([]);
          } else {
            setSubMenuList([]);
            setProductList([]);
          }
        }).catch((err) => {
            setSubMenuList([]);
            setProductList([]);
            console.log(err);
        });
    };

    const handleInputChange = event => {
        setQuantity(event.target.value);
    };

    async function handleClickSubMenuToGetProductList (CategoryID) {
        if(CategoryID){
          let token = localStorage.getItem("token");
          await axios.put(`/getProductBySubmenu/${CategoryID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let temOrder = response?.data;
            if(temOrder.length > 0){
                setProductList(temOrder);
            } else {
                setProductList([]);
            }
          }).catch((err) => {
            setProductList([]);
            console.log(err);
          });
        } else {
            setProductList([]);
        }
      }

    async function addOrder(ProductID, ProductType, Retail_Price, LaoName, EngName, qty) {
        var c =0;
        for (var i=0; i < TempOrder.length; i++) {
            if (TempOrder[i].ProductID == ProductID) {
                if(Number(TempOrder[i].Quantity) + Number(qty) > 0)
                {
                    TempOrder[i].Quantity = Number(TempOrder[i].Quantity) + Number(qty);
                    TempOrder[i].SubTotal = Number(TempOrder[i].Quantity) * Number(TempOrder[i].Retail_Price);
                    if(TempOrder[i].PrintStatus === "Y"){
                        if(TempOrder[i].QuantityPrint > 0){
                            TempOrder[i].QuantityPrint = Number(TempOrder[i].QuantityPrint) + Number(qty);
                        } else {
                            TempOrder[i].QuantityPrint = qty;
                        }
                    } 
                } else {
                    TempOrder.splice(i, 1);
                }
                c = 1;
                break;
            }
        }  
        if(c == 0){
            var datalist = {
                SubTotal: Retail_Price,
                ProductID: ProductID,
                LaoName: LaoName,
                EngName: EngName,
                Retail_Price: Retail_Price,
                TableID: TableID,
                Quantity: Number(qty),
                StatusID : 1,
                QuantityPrint : 0,
                PrintStatus: "N",
                ProductType: ProductType,
            }
            TempOrder.push(datalist);
        }
        localStorage.setItem(TableID, JSON.stringify(TempOrder));
        getSubtotal();
    }

    async function handleSearchProduct (e) {
        let token = localStorage.getItem("token");
        setBarcode(e.target.value);
        if(e.target.value){
            var data = {
                Barcode: e.target.value
            }
            await axios.post("/getProductByBarcode", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                let productData = response?.data;
                if(productData.length > 0){
                    addOrder(productData[0].ProductID, productData[0].Retail_Price, productData[0].LaoName, productData[0].EngName, Quantity);
                    setBarcode("");
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const getSubtotal = () => {
        let userData = localStorage.getItem(TableID);
        if (userData) {
          let userRole = JSON.parse(userData);
          setTempOrder(userRole);
          if(userRole.length > 0){
                var gSubTotal = 0;
                for (var i=0; i < userRole.length; i++) {
                    gSubTotal = Number(gSubTotal) + Number(userRole[i].SubTotal);
                }  
                setSubTotal(gSubTotal);
                setNetTotal(gSubTotal);
                var tax = (Number(gSubTotal) -((Number(gSubTotal) * 100) /110)).toFixed(2);
                setTax(tax);
            } else{
                setSubTotal(0);
                setNetTotal(0);
                setTax(0);
            }
        } else{
            setTempOrder([]);
            setSubTotal(0);
            setNetTotal(0);
            setTax(0);
        }
    }

    const handleClickTakeAway = event => {
        if(tw){
            setTakeAway("Dine In (ກິນຢູ່ຮ້ານ)");
            setTw(false);
        }else {
            setTakeAway("TakeAway (ກັບບ້ານ)");
            setTw(true);
        }  
    };

    async function createTemOrderFoodAndDrint (ProductID, qty) {
        if(qty > 0){
            let token = localStorage.getItem("token");
            var data = {
                ProductID: ProductID,
                TableID: TableID,
                Quantity: qty,
                CreateByID: userID,
                LocationID: LocationID,
                TakeAway: TakeAway,
            };
            await axios.post("/createTemOrder", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                //success
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    async function createTemOrder (ProductID, qty) {
        let token = localStorage.getItem("token");
        var data = {
            ProductID: ProductID,
            TableID: TableID,
            Quantity: qty,
            CreateByID: userID,
            LocationID: LocationID,
            TakeAway: TakeAway,
        };
        await axios.post("/CreateTemOrder", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            //success
        }).catch((err) => {
            console.log(err);
        });
    }


    async function createTemOrderWhenclickclose() {
        for (var i=0; i < TempOrder.length; i++) {
            if(TempOrder[i].ProductType !== "Food" && TempOrder[i].ProductType !== "FOOD" && TempOrder[i].ProductType !== "Drink" && TempOrder[i].ProductType !== "DRINK" && TempOrder[i].ProductType !== "Coffee" && TempOrder[i].ProductType !== "COFFEE"){
               if(TempOrder[i].PrintStatus === "N"){
                TempOrder[i].PrintStatus = "Y";
                for (var n=0; n < TempOrder[i].Quantity; n++) {
                    createTemOrderFoodAndDrint(TempOrder[i].ProductID, 1);
                }
               } else {
                for (var n=0; n < TempOrder[i].QuantityPrint; n++) {
                    createTemOrderFoodAndDrint(TempOrder[i].ProductID, 1);
                }
                TempOrder[i].QuantityPrint = 0;
               }
            } 
        }  
        localStorage.setItem(TableID, JSON.stringify(TempOrder));
        getSubtotal();
        if(TempOrder.length <=0){
            UpdateTable();
        }
        localStorage.removeItem(TableID);
        setShowOrder(false)
    };

    async function UpdateTable() {
        let token = localStorage.getItem("token");

        await axios.put(`/UpdateTableForPos/${TableID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllTableNumber();
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllTableNumber() {
        let token = localStorage.getItem("token");
        await axios.get(`/getAlltableNumberForPos/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setTableList(temOrder);
          } else {
            setTableList([]);
          }
        }).catch((err) => {
            setTableList([]);
            console.log(err);
        });
    }

    const handlePrintFood = () => {
        UpdateTempOrderFoodPrintStatus();
        printFood();
    }

    const handlePrintDrink = () => {
        UpdateTempOrderDrinkPrintStatus();
        printDrink();
    }

    const printFood = useReactToPrint({
        content: () => componentRefFood.current,
    });

    const printDrink = useReactToPrint({
        content: () => componentRefDrink.current,
    });

    async function UpdateTempOrderFoodPrintStatus() {
        for (var i=0; i < TempOrder.length; i++) {
            if(TempOrder[i].ProductType === "Food" || TempOrder[i].ProductType === "FOOD"){
               if(TempOrder[i].PrintStatus === "N"){
                TempOrder[i].PrintStatus = "Y";
                for (var n=0; n < TempOrder[i].Quantity; n++) {
                    createTemOrderFoodAndDrint(TempOrder[i].ProductID, 1);
                }
               } else {
                for (var n=0; n < TempOrder[i].QuantityPrint; n++) {
                    createTemOrderFoodAndDrint(TempOrder[i].ProductID, 1);
                }
                TempOrder[i].QuantityPrint = 0;
               }
                
            } else if(TempOrder[i].ProductType !== "Food" && TempOrder[i].ProductType !== "FOOD" && TempOrder[i].ProductType !== "Drink" && TempOrder[i].ProductType !== "DRINK" && TempOrder[i].ProductType !== "Coffee" && TempOrder[i].ProductType !== "Bakery" && TempOrder[i].PrintStatus === "N") {
                createTemOrder(TempOrder[i].ProductID, TempOrder[i].Quantity);
                TempOrder[i].PrintStatus = "Y";
            }
        }  
        localStorage.setItem(TableID, JSON.stringify(TempOrder));
        getSubtotal();
    };

    async function UpdateTempOrderDrinkPrintStatus() {
        for (var i=0; i < TempOrder.length; i++) {
            if(TempOrder[i].ProductType === "Drink" || TempOrder[i].ProductType === "Coffee" || TempOrder[i].ProductType === "Bakery" && TempOrder[i].PrintStatus === "N"){
                if(TempOrder[i].PrintStatus === "N"){
                    TempOrder[i].PrintStatus = "Y";
                    for (var n=0; n < TempOrder[i].Quantity; n++) {
                        createTemOrderFoodAndDrint(TempOrder[i].ProductID, 1);
                    }
                } else {
                    for (var n=0; n < TempOrder[i].QuantityPrint; n++) {
                        createTemOrderFoodAndDrint(TempOrder[i].ProductID, 1);
                    }
                    TempOrder[i].QuantityPrint = 0;
                }
            } else if(TempOrder[i].ProductType !== "Food" && TempOrder[i].ProductType !== "FOOD" && TempOrder[i].ProductType !== "Drink" && TempOrder[i].ProductType !== "DRINK" && TempOrder[i].ProductType !== "Coffee" && TempOrder[i].ProductType !== "Bakery" && TempOrder[i].PrintStatus === "N") {
                createTemOrder(TempOrder[i].ProductID, TempOrder[i].Quantity);
                TempOrder[i].PrintStatus = "Y";
            }
        }  
        localStorage.setItem(TableID, JSON.stringify(TempOrder));
        getSubtotal();
    };

    const handleShowPayment = () => {
       if(TempOrder.length > 0){
        setShowPayment(true);
       } else{
            setErrorMessage("ກະລຸນາເລືອກສິນຄ້າກ່ອນ");
            setShowAlert(true);
       }
    }

    async function getMenu() {
        if(LocationName ==="BIC"){
            axios
            .get("/getMenuForBIC")
            .then((response) => {
              let Data = response?.data;
              setMenuList(Data);
            })
            .catch((error) => {
              console.log(error);
          });
        }else{
            axios
            .get("/getmenu")
            .then((response) => {
              let Data = response?.data;
              setMenuList(Data);
            })
            .catch((error) => {
              console.log(error);
          });
        }
      }

    useEffect(() => {
        getMenu();
        getSubtotal();
      }, []);

    return (
        <div style={{ display: showOrder ? 'flex' : 'none' }} className="formPos">
            <div className="OrderContent">
                <div className="LeftContent">
                    <div className="imgLogo">
                        <img className="logo" src={logo} />
                    </div>
                    <div className="SubMenu">
                        {
                            subMenuList && subMenuList.map((x, y) => {
                                return (
                                    <div key={y} className="SubMenuContent" onClick={() => handleClickSubMenuToGetProductList(x.CategoryID)}>
                                        <img />
                                        <p className="name">{x.Category}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="CenterContent">
                    <div className="CenterHeader">
                        <div className="user-name">{serverName}</div>
                        <div className="Search">
                            <div className="Barcode">
                                <span>Barcode</span>
                                <input type="text" className="form-control" placeholder="Search" onChange={(e) => handleSearchProduct(e)} value={Barcode} />
                            </div>
                            <div className="Qty">
                                <span>Quantity</span>
                                <input type="text" className="form-control" placeholder="1" onChange={(e) => handleInputChange(e)} value={Quantity}/>
                            </div>
                        </div>
                    </div>
                    <div className="menuContent">
                        {
                            menuList && menuList.map((x, y) => {
                                return (
                                    <div key={y} className="FoodMenu" onClick={() => handleClickMenuToGetSubmenu(x.MenuID, x.ProductType)}>
                                        <div className="FoodMenuContent" tabIndex={y}  >
                                            <div className="item-header">
                                                <img />
                                            </div>
                                            <div className="item-body">
                                                <p className="name">{x.ProductType}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="underLine">
                        <hr />
                        <span>{} Menu</span>
                    </div>
                    <div className="MenuList">
                    {
                            productList && productList.map((x, y) => {
                                return (
                                    <div key={y} className="item" onClick={() => addOrder(x.ProductID, x.ProductType, x.Retail_Price, x.LaoName, x.EngName, 1)}>
                                        <div className="item-header">
                                            <img src={x.ImgUrl} className="profile-img" />
                                        </div>
                                        <div className="item-body">
                                            <div className="name">
                                                <p>{x.LaoName}</p>
                                            </div>
                                            <p className="price">{currencyFormat(x.Retail_Price)}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="RightContent">
                    <div className="billNo">
                        <div className="ordertext"><p>Order List</p></div>
                        <div className="tableNo"><p>Table # {tableNumber} </p></div>
                        <div className="btnclose" onClick={e => createTemOrderWhenclickclose()} >X</div>
                    </div>
                    <div className="OrderHead">
                        <div className="ProductName"><p>Price</p></div>
                        <div className="Quantity">Quantity</div>
                        <div className="Price">Total</div>
                    </div>
                    <div className="posRightSidenavContent">
                        {
                            TempOrder && TempOrder.map((x, y) => {
                                return (
                                    <div key={y} className="orderContent">
                                        <div className="orderFooter">
                                            <div className="price">
                                                <p>{currencyFormat(x.Retail_Price)}</p>
                                            </div>
                                            <div className="plus" onClick={() => addOrder(x.ProductID, x.ProductType, x.Retail_Price, x.LaoName, x.EngName, 1)}>
                                                +
                                            </div>
                                            <div className="quantity">
                                                <p>{currencyFormat(x.Quantity)}</p>
                                            </div>
                                            <div className="minus" onClick={() => addOrder(x.ProductID, x.ProductType, x.Retail_Price, x.LaoName, x.EngName, -1)}>
                                                -
                                            </div>
                                            <div className="Subtotal">
                                                <p>{currencyFormat(x.Quantity * x.Retail_Price)}</p>
                                            </div>
                                        </div>
                                        <div className="orderBody">
                                            <div className="detail">
                                                <div className="productName">
                                                    <span>{y+1}. {x.LaoName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="posRightSidenavBottom">
                        <div className="footer-subtotal">
                            <div className="footer-line"></div>
                            <div className="nettotal">
                                <span>Sutotal:</span>
                                <strong>{currencyFormat(subTotal)}</strong>
                            </div>
                        </div>
                        <div className="paymentMethod">
                            <div className="btnpayment" onClick={(e) => handleShowPayment()}>
                                <span>Payment</span>
                            </div>
                            <div className="btnPrintFood" onClick={(e) =>handlePrintFood()} >
                                <span>Print Food</span>
                            </div>
                            <div className="btnPrintDrink" onClick={(e) =>handlePrintDrink()}>
                                <span>Print Drink</span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-check form-check-inline takeAway">
                                <input className="form-check-input" type="radio" name="takeAway" value="TakeAway" onClick={(e) => handleClickTakeAway(e)} checked={tw} />
                                <span className="form-check-label"> TakeAway </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'none'}}>
                <PrintFood ref={componentRefFood}
                    TemProductList = {TempOrder}
                    BillDetails={{"TableNo": tableNumber, "cashier": serverName, "TakeAway": TakeAway}}
                />
                 <PrintDrink ref={componentRefDrink}
                    TemProductList = {TempOrder}
                    BillDetails={{"TableNo": tableNumber, "cashier": serverName, "TakeAway": TakeAway}}
                />
            </div>
        </div>
    );
};

export default PosOrder;
