
function CategoryValidate(values) {

    let errors = {};

    if (!values.Category)
        errors.Category = 'Category is required';
    else if (values.CheckCategory !== "")
        errors.Category = 'Category Already Exit';

    if (!values.ProductTypeID)
        errors.ProductTypeID = 'ProductTypeID is required';

    return errors;
};

export default CategoryValidate;
