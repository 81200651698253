import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/RegisterTable.css';
import axios from "axios";
import { API } from "../../../services/api";
import Alert from "../../../components/alert";
import SelectServer from "./selectserver";
import PosOrder from "./create/posorder";
import Payment from "./create/payment";

import { AlertContext, UserContext } from "../../../App";

axios.defaults.baseURL = API;

const POSContent = createContext();


function POS() {

const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);
const {userID, LocationID} = useContext(UserContext);

  const [tableNumber, setTableNumber] = useState([]);
  const [TableID, setTableID] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [serverName, setServerName] = useState([]);
  const [subTotal, setSubTotal] = useState([]);
  const [NetTotal, setNetTotal] = useState([]);
  const [Tax, setTax] = useState(0);
  const [PaymentTypeList, setPaymentTypeList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [DiscountList, setDiscountList] = useState([]);
  const [TempOrder, setTempOrder] = useState([]);

  const [show, setShow] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [showPayment, setShowPayment] = useState(false);

    const handelclickRegisterTable = (TableID, TableNumber, StatusID, Name) => {
        if(StatusID == 1){
            setTableID(TableID);
            setTableNumber(TableNumber);
            getTemOrder(TableID);
            setShow(true);
        } else {
            setTableID(TableID);
            setTableNumber(TableNumber);
            getTemOrder(TableID);
            setShowOrder(true);
            setServerName(Name)
        }
    };


    async function getTemOrder (TableID) {
        localStorage.removeItem(TableID);
        let token = localStorage.getItem("token");
        var data = {
            TableID: TableID,
            LocationID: LocationID,
        }
        await axios.post("/getTemOrder",data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let tempData = response?.data;
            setTempOrder([]);
            if(tempData.length > 0){ 
                setTempOrder(tempData);
                localStorage.setItem(TableID, JSON.stringify(tempData));
                var gSubTotal = 0;
                for (var i=0; i < tempData.length; i++) {
                    gSubTotal = Number(gSubTotal) + Number(tempData[i].SubTotal);
                    
                }  
                setSubTotal(gSubTotal);
                setNetTotal(gSubTotal);
                var tax = (Number(gSubTotal) -((Number(gSubTotal) * 100) /110)).toFixed(2);
                setTax(tax);
            } else {
                setTempOrder([]);
                let userData = localStorage.getItem(TableID);
                if (userData) {
                    let TemData = JSON.parse(userData);
                    setTempOrder(TemData);
                    if(TemData.length > 0){
                        var gSubTotal = 0;
                        for (var i=0; i < TemData.length; i++) {
                            gSubTotal = Number(gSubTotal) + Number(TemData[i].SubTotal);
                        }  
                        setSubTotal(gSubTotal);
                        setNetTotal(gSubTotal);
                        var tax = (Number(gSubTotal) -((Number(gSubTotal) * 100) /110)).toFixed(2);
                        setTax(tax);
                    } else{
                        setSubTotal(0);
                        setNetTotal(0);
                        setTax(0);
                    }
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    async function getAllTableNumber() {
        let token = localStorage.getItem("token");
        await axios.get(`/getAlltableNumberForPos/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setTableList(temOrder);
          } else {
            setTableList([]);
          }
        }).catch((err) => {
            setTableList([]);
            console.log(err);
        });
    }

    async function getAllDiscount() {
        axios
          .get("/getAllDiscount")
          .then((response) => {
            let Data = response?.data;
            setDiscountList(Data);
          })
          .catch((error) => {
            console.log(error);
        });
      }
    
    useEffect(() => {
        getAllTableNumber();
        let paymentData = localStorage.getItem("paymentTypeData");
            if (paymentData) {
                let payment = JSON.parse(paymentData);
                setPaymentTypeList(payment);
            }
            let currencyData = localStorage.getItem("currencyData");
            if (currencyData) {
                let currency = JSON.parse(currencyData);
                setCurrencyList(currency);
            }
            getAllDiscount();
    }, []);

  return (
    <POSContent.Provider value={{tableNumber, setTableNumber, TableID, setTableID,LocationID, tableList, setTableList, PaymentTypeList, 
    TempOrder, setTempOrder, CurrencyList, DiscountList, show, setShow, showPayment, setShowPayment, 
    showOrder, setShowOrder, serverName, setServerName, subTotal, setSubTotal, NetTotal, setNetTotal, 
    Tax, setTax, showAlert, setShowAlert, errorMessage, setErrorMessage}}>
      <div className="tableFormContent">
        <Alert/> 
        <SelectServer show={show} />
        <PosOrder show={showOrder}/>
        <Payment show={showPayment}/>
        <div className="tableContent">
            <div className="tableBody">
                <div className="tableItem">
                    {
                        tableList && tableList.map((x, y) => {
                            if(x.StatusID == 1){
                                return (
                                    <div key={x.Tabe} className="item" onClick={() => handelclickRegisterTable(x.TableID, x.TableNumber, x.StatusID, x.name)} >
                                        <div className="item-body">
                                            <div className="name">
                                                <p>ເລກໂຕະ</p>
                                                <p>{x.TableNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (x.StatusID == 2){
                                return (
                                    <div key={y} className="item2" onClick={() => handelclickRegisterTable(x.TableID, x.TableNumber, x.StatusID, x.name)}>
                                        <div className="item-body2">
                                            <div className="name2">
                                                <p>ເລກໂຕະ</p>
                                                <p>{x.TableNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
      </div>
    </POSContent.Provider>
  );
};

export {POSContent};
export default POS;
