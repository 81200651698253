import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/paymentTypeValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { PaymentTypeContext } from "../paymentType";

axios.defaults.baseURL = API;

function CreatePaymentType(props) {

    const {dataList, setDataList} = useContext(PaymentTypeContext);

    const handleInputPaymentTypeChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.PaymentType.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckPaymentType", results[0].PaymentType);
        else
            handleChange("CheckPaymentType", "");
    }

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            PaymentType: values.PaymentType,
        }
        await axios.post("/createPaymentType", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            var obj = {
                PaymentTypeID: response?.data?.insertId,
                PaymentType: values.PaymentType,
            }
            dataList.push(obj);
            setDataList(dataList);
            localStorage.setItem("paymentTypeData", JSON.stringify(dataList));
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => 
        handleChange("PaymentType", "");

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງປະເພດຈ່າຍ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ປະເພດຈ່າຍ</span>
                                <input className={`input form-control ${errors.PaymentType && 'is-invalid'}`} type="text" name="PaymentType" onChange={(e) => handleInputPaymentTypeChange(e)} value={values.PaymentType || ''} required />
                                {errors.PaymentType && (
                                    <p className="invalid-feedback">{errors.PaymentType}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreatePaymentType;
