
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

!firebase.apps.length
  ? firebase.initializeApp({
    apiKey: "AIzaSyCmH8gGVxk_iKl9TcrBbKq4bqxl0rdmXGk",
    authDomain: "maneecafe-e8ef3.firebaseapp.com",
    databaseURL: "https://maneecafe-e8ef3-default-rtdb.firebaseio.com",
    projectId: "maneecafe-e8ef3",
    storageBucket: "maneecafe-e8ef3.appspot.com",
    messagingSenderId: "334059819233",
    appId: "1:334059819233:web:b8f758f59a37e527b6a7a0",
    measurementId: "G-6QTELV11LX"
  })
  : firebase.app();

const database = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { firebase, database, auth, storage };


