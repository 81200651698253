import {useContext} from 'react';
import { AlertContext } from '../App';
import '../css/alert.css';
function Alert() {
    const {showAlert, setShowAlert, errorMessage} = useContext(AlertContext);
    return (
        <div style={{ display: showAlert ? 'flex' : 'none' }} className="formAlert">
            <div className="formAlertContent">
                <div className="alert-header">
                    <img className="logo" src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2Ficon%2Falert.png?alt=media&token=adf6b252-8d84-4a71-8c48-56c61233e9a7" />
                </div>
                <div className="alert-body">
                    <h1>Warning</h1>
                    <span>{errorMessage}</span>
                </div>
                <div className="alert-footer">
                    <button type="button" onClick={(e) => setShowAlert(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                        ກັບຄືນ
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Alert;
