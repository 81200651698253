
function locationValidate(values) {

    let errors = {};
    if (!values.LocationName)
        errors.LocationName = 'LocationName is required';
    else if (values.CheckLocationName != "")
        errors.LocationName = 'LocationName Already Exit';

    if (!values.Tell)
        errors.Tell = 'Tell Type is required';
    else if (values.Tell.length < 8)
        errors.Tell = 'Address must be 8 or more characters';
    else if (values.Tell.length > 15)
        errors.Tell = 'Tell must not exceed 15 characters';

    if (!values.Address)
        errors.Address = 'Address is required';
    else if (values.Address.length < 2)
        errors.Address = 'Address must be 2 or more characters';
    else if (values.Address.length > 255)
        errors.Address = 'Address must not exceed 255 characters';

    return errors;
};

export default locationValidate;
