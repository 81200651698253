
function PaymentTypeValidate(values) {

    let errors = {};
    if (!values.PaymentType)
        errors.PaymentType = 'PaymentType is required';
    else if (values.CheckPaymentType !== "")
        errors.PaymentType = 'PaymentType Already Exit';

    return errors;
};

export default PaymentTypeValidate;
