import React, { useState, useEffect, createContext, useRef, useContext } from "react";
import { format } from "date-fns";
import '../../../css/KitchenOrder.css';
import { AlertContext, UserContext } from "../../../App";
import axios from "axios";
import { API } from "../../../services/api";

axios.defaults.baseURL = API;

function KitchenOrder() {

    function getDate(gdate) {
        var date = new Date(gdate);
        return format(date, "dd-MM-yyyy HH:mm");
    }

    const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);
    const {userID, LocationID} = useContext(UserContext);
    const [TemOrderList, setTemOrderList] = useState([]);

    function currencyFormat(num) {
        if (num !== "") {
            return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }

    async function getTemOrder () {
        let token = localStorage.getItem("token");
        var data = {
            LocationID: LocationID,
        }
        await axios.post("/getTemOrderForKitchen",data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let tempData = response?.data;
            if(tempData.length > 0){ 
                playSound(tempData.length);
                setTemOrderList(tempData);
            } else {
                setTemOrderList([]);
                localStorage.setItem("audio", 0);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    async function UpdateTempKitchenOrder(TemOrderID, KitchenStatus) {
        let token = localStorage.getItem("token");
        var data = {
            TemOrderID: TemOrderID,
            KitchenStatus: KitchenStatus,
        }
        await axios.post(`/UpdateTemOrder`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getTemOrder();
        }).catch((err) => {
            console.log(err);
        });
    };


    async function playSound(count) {
        let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/sound%2FNotification_Sound.mp3?alt=media&token=c0d6a2fb-043a-4451-aacd-2598aa541474');
        let Data = localStorage.getItem("audio");
        if (Data) {
          if(count > Data){
            localStorage.setItem("audio", count);
            audio.play();
          } else {
            localStorage.setItem("audio", count);
          }
            
        } else{
           if(count > 0){
                localStorage.setItem("audio", count);
                audio.play();
           }  
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
          getTemOrder();
        }, 5000); //set your time here. repeat every 5 seconds
      
        return () => clearInterval(interval);
      }, [TemOrderList]);

    return (
        <div className="KitchenOrderForm">
            <div className="Header">
                <div className="imglogo">
                    <img className="logo" src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2FManeeCafeLogo.jpg?alt=media&token=be4159bc-3c38-41c4-9c57-634457c3868c" />
                </div>
                <span>Manee Cafe</span>
            </div>
            <div className="KitchenOrderContent">
                {
                    TemOrderList && TemOrderList.map((x, y) => {
                        if(x.ProductType === "Food" || x.ProductType === "FOOD")
                        {
                            if(x.KitchenStatus === 1){
                                return (
                                    <div key={y} className="KitchenOrderItem" >
                                        <div className="KitchenOrderBody">
                                            <div className="TableNO">
                                                <span>{ 'ເລກໂຕະ: ' + x.TableNumber}</span>
                                            </div>
                                            <div className="productName">
                                                <span>{x.LaoName}</span>
                                            </div>
                                            <div className="TakeAway">
                                                <span>{x.TakeAway}</span>
                                            </div>
                                            <div className="CreateDate">
                                                <span>{getDate(x.CreateDate)}</span>
                                            </div>
                                            <div className="btnCooking">
                                                <button className="btn btn-warning" onClick={() => UpdateTempKitchenOrder(x.TemOrderID, 2)}>Cooking</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if(x.KitchenStatus === 2) {
                                return (
                                    <div key={y} className="KitchenOrderItem" >
                                        <div className="KitchenOrderBody">
                                            <div className="TableNO">
                                                <span>{ 'ເລກໂຕະ: ' + x.TableNumber}</span>
                                            </div>
                                            <div className="productName">
                                                <span>{x.LaoName}</span>
                                            </div>
                                            <div className="TakeAway">
                                                <span>{x.TakeAway}</span>
                                            </div>
                                            <div className="CreateDate">
                                                <span>{getDate(x.CreateDate)}</span>
                                            </div>
                                            <div className="btnFinish">
                                                <button className="btn btn-primary" onClick={() => UpdateTempKitchenOrder(x.TemOrderID, 3)}>Finish</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    })
                }
            </div>
        </div >
    );
};

export default KitchenOrder;
