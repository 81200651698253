import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";
import ReceivedBillDetail from "./update/ReceivedBillDetail";
import { format } from "date-fns";
axios.defaults.baseURL = API;

const ReceivedBillContent = createContext();

function StoreReceivedBill() {
  function getDate(gdate) {
    var date = new Date(gdate);
    return format(date, "dd-MM-yyyy HH:mm");
  }
  const {LocationID} = useContext(UserContext);
  const [dataList, setDataList] = useState([]);
  const [PurchaseOrderData, setPurchaseOrderData] = useState([]);
  const [PurchaseOrderDetails, setPurchaseOrderDetail] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function getPendingBill() {
    var StatusID = 6;
    await axios.get(`/getStorePurchaseOrderBill/${LocationID}/${StatusID}`).then((response) => {
        let Data = response?.data;
        setDataList(Data);
        setPurchaseOrderData(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function SearchPendingBill(e) {
    const {value } = e.target;
    if(value){
      var StatusID = 6;
      await axios.get(`/getStorePurchaseOrderByBillNO/${LocationID}/${StatusID}/${value}`).then((response) => {
          let Data = response?.data;
          setDataList(Data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setDataList(PurchaseOrderData);
    }
  }

  async function openProductDetail(StoreOrderID) {
    axios
      .get(`/getStorePurchaseOrderProduct/${StoreOrderID}`)
      .then((response) => {
        let Data = response?.data;
        setPurchaseOrderDetail(Data);
        handleShow();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getPendingBill();
  }, []);

  return (
    <ReceivedBillContent.Provider value={{PurchaseOrderDetails}}>
      <div className="formContent">
        <ReceivedBillDetail show={show} onClick={handleClose}/>
        <div className="Header">
          <div className="title">
              <span>ບິນທີ່ຮັບເຄື່ອງເຂົ້າຮ້ານ</span>
          </div>
          <div className="txtSearch">
            <span></span>
            <input placeholder="ຄົ້ນຫາດ້ວຍ ເລກບິນ" name="BillNO" onChange={(e) => SearchPendingBill(e)}/>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ມື້ສ້າງ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                dataList && dataList.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.StoreOrderID}</td>
                        <td >{getDate(x.CreatedDate)}</td>
                        <td >
                          <button className="btn btn-warning" type="button" onClick={() => openProductDetail(x.StoreOrderID)}>ລາຍລະອຽດ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </ReceivedBillContent.Provider>
  );
};

export {ReceivedBillContent};
export default StoreReceivedBill;
