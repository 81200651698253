import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import TemOrderByBarcode from "./create/tempOrderByBarcode";
import TemOrderByName from "./create/tempOrderByName";
import Alert from "../../../components/alert";
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext, AlertContext } from "../../../App";

const PurchaseOrderContext = createContext();

axios.defaults.baseURL = API;

function StorePurchaseOrder() {

  const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);

  const {userID, LocationID} = useContext(UserContext);
  

  const [showData, setShowData] = useState([]);
  const [ShowByName, setShowByName] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowByName = () => setShowByName(true);
  const handleCloseByName = () => setShowByName(false);

  function currencyFormat(num) {
    if (num !== "") {
        return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  }

  const minusOrder = (Barcode) => {
    for (var i=0; i < showData.length; i++) {
      if (showData[i].Barcode == Barcode) {
          if(Number(showData[i].Quantity) - 1 > 0)
          {
            showData[i].Quantity = Number(showData[i].Quantity) - 1;
            showData[i].SubTotal = Number(showData[i].Quantity) * Number(showData[i].BuyPrice);
            localStorage.setItem("storePurchaseOrderData", JSON.stringify(showData));
          } else {
            showData.splice(i, 1);
            localStorage.setItem("storePurchaseOrderData", JSON.stringify(showData));
          }
          break;
      }
    }  
    setOrderList();
  }

  async function addOrder(ProductID, Barcode) {
    if(ProductID > 0) {
        await axios.get(`/checkInventory/${ProductID}`).then((response) => {
            let Data = response?.data;
            if(Data[0].Quantity > 0){
                for (var i=0; i < showData.length; i++) {
                    if (showData[i].Barcode == Barcode) {
                        if(Data[0].Quantity >= Number(showData[i].Quantity) + Number(1)){
                            showData[i].Quantity = Number(showData[i].Quantity) + Number(1);
                            showData[i].SubTotal = Number(showData[i].Quantity) * Number(showData[i].BuyPrice);
                            localStorage.setItem("storePurchaseOrderData", JSON.stringify(showData));
                            break;
                        } else {
                            setErrorMessage("ຈຳນວນສິນຄ້າໃນສາງມີບໍ່ພຽງພໍ (ມີ: " + Data[0].Quantity +' )');
                            setShowAlert(true);
                            break;
                        }  
                    }
                }  
                setOrderList();
            } else {
                setErrorMessage("ຈຳນວນສິນຄ້າໃນສາງມີບໍ່ພຽງພໍ (ມີ: " + Data[0].Quantity +' )');
                setShowAlert(true);
            }
        })
            .catch((error) => {
            console.log(error);
        });
    }
}

  const setOrderList = () => {
    let userData = localStorage.getItem("storePurchaseOrderData");
    if (userData) {
        let userRole = JSON.parse(userData);
        setShowData(userRole);
    } else
        setShowData([]);
  }

  const handleCreate = () => {
    if(showData.length > 0){
      saveTutorial();
    }
  }  


  async function saveTutorial() {
    let token = localStorage.getItem("token");
    var data = {
        CreatedByID: userID,
        CreatedDate: new Date(),
        StatusID: 2,
        LocationID: LocationID,
    }
    await axios.post("/CreateStorePurchaseOrder", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        CreatePurchaseOrderDetail(response?.data?.insertId);
        ClearData();
    }).catch((err) => {
        console.log(err);
    });
};

async function CreatePurchaseOrderDetail(StoreOrderID) {
    let token = localStorage.getItem("token");
    if(showData.length > 0) {
        for (var i=0; i < showData.length; i++) {
            var list;
            list ={
              StoreOrderID: StoreOrderID,
                ProductID: showData[i].ProductID,
                Quantity: showData[i].Quantity,
            };
            await axios.post("/createStorePurchaseOrderDetails", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            }).catch((err) => {
                console.log(err);
            });
        } 
        ClearData();
    }
}

const ClearData = () => {
  localStorage.removeItem('storePurchaseOrderData');
  setShowData([]);
}

  
  useEffect(() => {

    let purchaseOrderData = localStorage.getItem("storePurchaseOrderData");
    if (purchaseOrderData) {
      let userRole = JSON.parse(purchaseOrderData);
      setShowData(userRole);
    }
  }, []);

  return (
    <PurchaseOrderContext.Provider value={{showData, setShowData, showAlert, setShowAlert, errorMessage, setErrorMessage}}>
      <div className="formContent">
        <Alert/> 
        <TemOrderByBarcode show={show} onClick={handleClose}/>
        <TemOrderByName show={ShowByName} onClick={handleCloseByName}/>
        <div className="Header">
          <div className="title">
              <span>ສ້າງໃບເບີກເຄື່ອງ</span>
          </div>
          <div className="txtSearch">
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມດ້ວຍລະຫັດ</button>
            <button className="btn btn-secondary" onClick={handleShowByName} >ເພີ່ມດ້ວຍຊື່</button>
            <button className="btn btn-success" onClick={handleCreate} >ສ້າງໃບເບີກເຄື່ອງ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>Barcode</th>
                <th>ປະເພດສິນຄ້າ</th>
                <th>ຊື່ພາສາລາວ</th>
                <th>ຊື່ພາສາອັງກິດ</th>
                <th>ລາຄາຊື້</th>
                <th>ຈຳນວນ</th>
                <th>ລວມ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.Barcode}</td>
                        <td >{x.ProductType}</td>
                        <td >{x.LaoName}</td>
                        <td >{x.EngName}</td>
                        <td >{currencyFormat(x.BuyPrice)}</td>
                        <td >{currencyFormat(x.Quantity)}</td>
                        <td >{currencyFormat(x.SubTotal)}</td>
                        <td >
                          <button className="btn btn-primary" type="button" onClick={() => addOrder(x.ProductID, x.Barcode)}>+</button>
                            &nbsp;&nbsp;&nbsp;
                          <button className="btn btn-danger" type="button" onClick={() => minusOrder(x.Barcode)}>-</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      </PurchaseOrderContext.Provider>
  );
};

export {PurchaseOrderContext};
export default StorePurchaseOrder;
