
import React, { useState, useEffect, useContext } from "react";
import useForm from "../../../services/UseForm";
import validate from '../../../validations/admin/product/UploadImageSchema';
import {storage} from '../../../firebase';
import axios from "axios";
import { API } from "../../../services/api";
import '../../../css/UploadImg.css';
import ProgressBar from "../../../components/ProgressBar";
import { ProgressBarContext } from "../../../App";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';

axios.defaults.baseURL = API;

function UploadImgProduct() {
    const [image , setImage] = useState(null);
    const [data, setData] = useState([])
    const [Url, setUrl] = useState(null);
    const [Barcode, setBarcode] = useState(null);
    const [ProductName, setProductName] = useState(null);
    const [ProductID, setProductID] = useState(null);
    const {setShowProgressBar, setProgress} = useContext(ProgressBarContext);
    const handleSetImage = e => {
        if(e.target.files[0]){
            setImage(e.target.files[0]);
            handleChange("Image", e.target.files[0].name);
        } else {
            setImage(null);
            handleChange("Image", "");
        }
    }

    const upload = ()=>{
      if(image){
          setShowProgressBar(true);
          const uploadTask = storage.ref(`/images/Product/${image.name}`).put(image);
          uploadTask.on("state_changed", 
          snapshot => {
              const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(progress);
          },
          error => {
              console.log(error);
          },
          () => {
              storage.ref("images/Product")
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                 setShowProgressBar(false);
                 setUrl(url);
                 UpdateProductImgUrl(url);
              })
          }
          )
      }
  }

  async function UpdateProductImgUrl (Url) {
    let token = localStorage.getItem("token");
    var data = {
        ProductTypeID: ProductID,
        ImgUrl: Url,
    }
    await axios.put(`/UploadImage/${ProductID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
    }).catch((err) => {
        console.log(err);
    });
  }

    async function handleSearchProduct (e) {
      let token = localStorage.getItem("token");
      setBarcode(e.target.value);
        if(e.target.value){
            var data = {
                Barcode: e.target.value
            }
            await axios.post("/getProductByBarcode", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                let productData = response?.data;
                if(productData.length > 0){
                    setProductID(productData[0].ProductID);
                    handleChange("ProductID", productData[0].ProductID);
                    setProductName(productData[0].LaoName);
                } else {
                  handleChange("ProductID", null);
                  setProductID(null);
                  setProductName(null);
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
          setBarcode("");
        }
    }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
} = useForm(upload, validate);
  
  useEffect(() => {
    let userData = localStorage.getItem("userData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setData(userRole);
    }
  }, []);

  return (
    
    <div className="main">
      <ProgressBar bgcolor="orange" progress='0'  height={30} />
      <div className="maincontent">
          <div className="MainUploadContent">
              <form onSubmit={handleSubmit} noValidate>
                  <div className="Header">
                      <div className="barcode">
                          <input type="text" className={`input form-control ${errors.ProductID && 'is-invalid'}`} placeholder="Search" onChange={(e) => handleSearchProduct(e)} value={Barcode || ''}/>
                          {errors.ProductID && (
                              <p className="invalid-feedback">{errors.ProductID}</p>
                          )}
                          <span>ProductName: {ProductName || "....."}</span>
                      </div>
                      <div className="upload-img">
                          <input type="file" className={`input form-control ${errors.Image && 'is-invalid'}`} onChange={(e)=> handleSetImage(e)}/>
                          <button type="submit" className="btn btn-primary">
                              Upload
                          </button>
                      </div>
                      <div className="product-img">
                          <img src={Url || "http://via.placeholder.com/400"} alt="firebase-image" />
                      </div>
                  </div>
              </form> 
          </div>
      </div>
    </div>
  );
};

export default UploadImgProduct;
