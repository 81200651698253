import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/member/memberValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";

import { MemberDataContext } from "../member";

axios.defaults.baseURL = API;

function UpdateMember(props) {

    const {dataList, setDataList, Data,setShowData } = useContext(MemberDataContext);

    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
    };

    async function updateLocation() {
        let token = localStorage.getItem("token");
        var data = {
            FirstName: values.FirstName,
            LastName: values.LastName,
            TEL: values.TEL,
            MemberNO: values.MemberNO,
            Email: values.Email,
            Identification: values.Identification,
        }
        await axios.put(`/updateMember/${values.MemberID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllMember()
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllMember() {
        axios
          .get("/getAllMember")
          .then((response) => {
            let Data = response?.data;
            setDataList(Data);
            setShowData(Data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

    const ClearData = () => {
        handleChange("MemberID", "");
        handleChange("FirstName", "");
        handleChange("LastName", "");
        handleChange("TEL", "");
        handleChange("MemberNO", "");
        handleChange("Email", "");
        handleChange("Identification", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(updateLocation, validate);

    useEffect(() => {
        Data.forEach(value => {
            handleChange("MemberID", value.MemberID);
            handleChange("FirstName", value.FirstName);
            handleChange("LastName", value.LastName);
            handleChange("MemberNO", value.MemberNO);
            handleChange("TEL", value.TEL);
            handleChange("Email", value.Email);
            handleChange("Identification", value.Identification);
            handleChange("CheckMemberNO", "");
        });
    }, [Data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂຂໍ້ມູນບັດ Member</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ຊື້</span>
                                <input className={`input form-control ${errors.FirstName && 'is-invalid'}`} type="text" name="FirstName" onChange={(e) => handleInputChange(e)} value={values.FirstName || ''} required />
                                {errors.FirstName && (
                                    <p className="invalid-feedback">{errors.FirstName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ນາມສະກູນ</span>
                                <input className={`input form-control ${errors.LastName && 'is-invalid'}`} type="text" name="LastName" onChange={(e) => handleInputChange(e)} value={values.LastName || ''} required />
                                {errors.LastName && (
                                    <p className="invalid-feedback">{errors.LastName}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>MemberNO:</span>
                                <input className={`input form-control ${errors.MemberNO && 'is-invalid'}`} type="text" name="MemberNO"  value={values.MemberNO || ''} disabled />
                                {errors.MemberNO && (
                                    <p className="invalid-feedback">{errors.MemberNO}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ເບີໂທ:</span>
                                <input className={`input form-control ${errors.TEL && 'is-invalid'}`} type="text" name="TEL" onChange={(e) => handleInputChange(e)} value={values.TEL || ''} required />
                                {errors.TEL && (
                                    <p className="invalid-feedback">{errors.TEL}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>Email:</span>
                                <input className={`input form-control ${errors.Email && 'is-invalid'}`} type="text" name="Email" onChange={(e) => handleInputChange(e)} value={values.Email || ''} required />
                                {errors.Email && (
                                    <p className="invalid-feedback">{errors.Email}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>Identification:</span>
                                <input className={`input form-control ${errors.Identification && 'is-invalid'}`} type="text" name="Identification" onChange={(e) => handleInputChange(e)} value={values.Identification || ''} required />
                                {errors.Identification && (
                                    <p className="invalid-feedback">{errors.Identification}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateMember;
