
function tablenumberValidate(values) {

    let errors = {};
    if (!values.TableNumber)
        errors.TableNumber = 'TableNumber is required';
    else if (values.CheckTableNumber != "")
        errors.TableNumber = 'TableNumber Already Exit';

    return errors;
};

export default tablenumberValidate;
