import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";
import UpdatePaymentType from "./update/updatePaymentType";

const PaymentTypeContext = createContext();

axios.defaults.baseURL = API;

function EditBillPayment() {
  const [PaymentList, setPaymentList] = useState([]);
  const [showData, setShowData] = useState([]);
  const [data, setData] = useState([]);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);

  const openUpdate = (TransactionID, ProductTypeID, ProductType) => {
    var list = [];
    list.push({
      TransactionID: TransactionID,
      ProductTypeID: ProductTypeID,
      ProductType: ProductType
    });
    setData(list);
    setShowUpdate(true);
  };

  async function SearchTransaction(e) {
    const { value } = e.target;
    if(value){
      await axios.get(`/SearchTransaction/${value}`).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
    } else {
      setShowData([]);
    }
  };

  async function getAllPaymentType() {
    axios
      .get("/getAllPaymentType")
      .then((response) => {
        let Data = response?.data;
        setPaymentList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getAllPaymentType();
  }, []);

  return (
    <PaymentTypeContext.Provider value={{PaymentList, setPaymentList, data, setShowData}}>
      <div className="formContent">
         <UpdatePaymentType show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຍົກເລີກບິນ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາເລກບິນ" onChange={(e) => SearchTransaction(e)} />
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ຍອດລວມ</th>
                <th>ສ່ວນຫລຸດ</th>
                <th>ຍອດທີ່ຕ້ອງຈ່າຍ</th>
                <th>ຍອດຈ່າຍ</th>
                <th>ຈ່າຍເພີ່ມ</th>
                <th>ປະເພດຈ່າຍ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.TransactionID}</td>
                        <td >{x.SubTotal}</td>
                        <td >{x.Discount}</td>
                        <td >{x.NetTotal}</td>
                        <td >{x.PaidAmount}</td>
                        <td >{x.PaidMoreAmount}</td>
                        <td >{x.PaymentType}</td>
                        <td >
                          <button className="btn btn-primary" value={x.TransactionID} type="button" onClick={() => openUpdate(x.TransactionID, x.PaymentTypeID, x.PaymentType)}>ແກ້ໄຂປະເພດຈ່າຍບິນ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </PaymentTypeContext.Provider>
  );
};
export {PaymentTypeContext};
export default EditBillPayment;
