import React, { useState, useEffect, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateSizeType from "./create/createSizeType";
import UpdateSizeType from "./update/updateSizeType";
import { API } from "../../../services/api";
import axios from "axios";
axios.defaults.baseURL = API;

const SizeTypeContext = createContext();

function SizeType() {

  const [dataList, setDataList] = useState([]);
  const [ShowData, setShowData] = useState([]);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { value } = event.target;
    if(value){
      var results = [];
      results = dataList.filter((data) => {
          return data.SizeType.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
      setShowData(results);
      else 
      setShowData(dataList);
    } else 
      setShowData(dataList);
  }

  const openUpdate = (SizeTypeID, SizeType) => {
    var list = [];
    list.push({
      SizeTypeID: SizeTypeID,
      SizeType: SizeType
    });
    setData(list);
    setShowUpdate(true);
  };

  async function getAllSizeType() {
    axios
      .get("/getAllSizeType")
      .then((response) => {
        let Data = response?.data;
        setDataList(Data);
        setShowData(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(() => {
    getAllSizeType();
  }, []);

  return (
    <SizeTypeContext.Provider value={{dataList, setDataList, data, setShowData}}>
      <div className="formContent">
        <CreateSizeType show={show} onClick={handleClose}/>
        <UpdateSizeType show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ປະເພດຂະໜາດ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ປະເພດຂະໜາດ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                ShowData && ShowData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.SizeType}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.SizeTypeID} type="button" onClick={() => openUpdate(x.SizeTypeID, x.SizeType)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </SizeTypeContext.Provider>
  );
};

export {SizeTypeContext};
export default SizeType;
