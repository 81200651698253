import { useState, useEffect } from 'react';

function UseForm (callback, schema) {

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors]);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setErrors(schema(values));
        setIsSubmitting(true);

    };

    const handleChange = (key, value) => {
        // event.persist();
        // setValues(values => ({ ...values, [event.target.name]: event.target.value }));
        setValues(values => ({ ...values, [key]: value }));
    };

    return {
        handleChange,
        handleSubmit,
        values,
        errors,
    }
};

export default UseForm;