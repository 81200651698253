import React, { useState, useEffect, useRef, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../../css/form.css';
import '../../../../css/MemberTransactionReport.css';
import '../../../../css/datepicker.css';
import DatePicker from "react-datepicker";
import axios from "axios";
import { API } from "../../../../services/api";
import { useReactToPrint } from 'react-to-print';
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';

axios.defaults.baseURL = API;

class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      const today = Date.now();
      let SubTotal = 0;
      let Discount = 0;
      let NetTotal = 0;
      let PaidMoreAmount = 0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.StartedDate));
      const EndDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.EndedDate));
      return (
          <div className="MemberTransactionReportContent">
              <div className="ReportHeader">
                    <img className="profile-img" src={logo} />
                  <div className="ReportTitle">
                      <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>**********</span>
                      </div>
                      <div className="titleHeader3">
                          <span>ລາຍງານ ສະຫລຸບການຂາຍ</span>
                      </div>
                      
                  </div>
                  <div className="PrintDate">
                      <span>ແຕ່ວັນທີ # {StartDate}</span>
                  </div>
                  <div className="PrintDate">
                      <span>ຫາວັນທີ: {EndDate}</span> 
                  </div>
                  <div className="ReportLocation">
                      <span>ສາຂາ: {billDetails.LocationName}</span>
                  </div>
                  <div className="ReportLocation">
                      <span>ລະຫັດບັດ: {billDetails.MemberNO}</span>
                  </div>
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                  <span className="CountNumber">NO.</span>
                      <span className="BillNo">ເລກບິນ</span>
                      <span className="PaymentType">ປະເພດຈ່າຍ</span>
                      <span className="SubTotal">ຍອດລວມ</span>
                      <span className="Discount">ສ່ວນຫລຸດ</span>
                      <span className="NetTotal">ຍອດທີ່ຈ່າຍ</span>
                      <span className="PaidMoreAmount">ຍອດຈ່າຍເພີ່ມ</span>
                  </div>
                  <div className="HeadLine">
                    <div className="footer-line"/>
                  </div>
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                            SubTotal = Number(x.SubTotal) +  Number(SubTotal);
                            Discount =  Number(x.Discount) +  Number(Discount);
                            NetTotal =  Number(x.NetTotal) +  Number(NetTotal);
                            PaidMoreAmount = Number(x.PaidMoreAmount) +  Number(PaidMoreAmount);
                            return (
                                <div className="Items">
                                    <div className="Detail">
                                        <div className="mds">
                                            <span className="NO">{(y + 1) }</span>
                                            <span className="BillNo">{x.TransactionID}</span>
                                            <span className="PaymentType">{x.PaymentType}</span>
                                            <span className="SubTotal">{currencyFormat(x.SubTotal)}</span>
                                            <span className="Discount">{currencyFormat(x.Discount)}</span>
                                            <span className="NetTotal">{currencyFormat(x.NetTotal)}</span>
                                            <span className="PaidMoreAmount">{currencyFormat(x.PaidMoreAmount)}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                      }
                  </div>
                   <div className="HeadLine">
                    <div className="footer-line"></div>
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="SubTotal">{currencyFormat(SubTotal)}</span>
                      <span className="Discount">{currencyFormat(Discount)}</span>
                      <span className="PaidAmount">{currencyFormat(NetTotal)}</span>
                      <span className="PaidMore">{currencyFormat(PaidMoreAmount)}</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ກວດກາ</span>
                      <span className="StockAdmin"></span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}


function MemberTransactionReport() {
  function getDate(gdate) {
    const today = gdate;
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    return `${year}-${month}-${date} ${time}`;
  }
  const [StartedDate, setStartedDate] = useState(null); 
  const [EndedDate, setEndedDate] = useState(null); 
  const [LocationList, setLocationList] = useState(null); 
  const [LocationID, setLocationID] = useState(""); 
  const [LocationName, setLocationName] = useState("");
  const [MemberNO, setMemberNO] = useState("");
  const componentRef = useRef(); 

  const [showData, setShowData] = useState([]);
  const [TransactionDetail, setTransactionDetail] = useState([]);

  const filterPassedTime = time => {
    const selectedDate = new Date(time);
    return selectedDate.getTime();
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    if(value){
      setMemberNO(value);
    } else {
      setMemberNO("");
    }
  };

  const handleChangeStartedDate = event => {
    if (event)
        setStartedDate(event);
    else 
        setStartedDate(null);
}

const handleChangeEndedDate = event => {
    if (event)
        setEndedDate(event);
    else 
        setEndedDate(null);
}

const handleSelectLocation = (e) => {
  var data = LocationList.filter(function (item) {
      return item.LocationName == e.target.value
  })
  if (data.length > 0) {
      setLocationName(data[0].LocationName);
      setLocationID(data[0].LocationID);
  } else {
      setLocationName("");
      setLocationID("");
  }
};

  async function handleShowTransactionDetail (TransactionID) {
    let token = localStorage.getItem("token");
    await axios.get(`/getTransactionDetail/${TransactionID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      let Data = response?.data;
      if(Data.length > 0){
        setTransactionDetail(Data);
      } 
    }).catch((error) => {
      console.log(error);
    });
  }

  async function SearchTransaction() {
    let token = localStorage.getItem("token");
      var StatusID = 1;
      await axios.get(`/MemberTransactionReport/${getDate(StartedDate)}/${getDate(EndedDate)}/${LocationID}/${StatusID}/${MemberNO}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let userData = localStorage.getItem("locationData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setLocationList(userRole);
    }
  }, []);

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ລາຍງານໃຊ້ບັດ</span>
          </div>
          <div className="txtSearch">
            <div className="txtMemberNO">
              <label>MemberNO: </label>
              <input placeholder="MemberNO" name="MemberNO" onChange={(e) => handleInputChange(e)} value={MemberNO || ''} />
            </div>
            <div className="StartedDate">
                <label>ແຕ່ວັນທີ: </label>
                <DatePicker className="date"
                    selected={StartedDate}
                    onChange={(e) => handleChangeStartedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="EndedDate">
                <label >ຫາວັນທີ: </label>
                <DatePicker className="date"
                    selected={EndedDate}
                    onChange={(e) => handleChangeEndedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="Location">
                <label>ສາຂາ: </label>
                <select name="LocationID" onChange={(e) => handleSelectLocation(e)}
                    className={`input form-control`}>
                    <option
                        key={""}
                        value={""}
                    >
                        {'ເລືອກສາຂາ'}
                    </option>,
                    {LocationList && LocationList.map(item => (
                        <option
                            key={item.LocationID}
                            value={item.LocationName}
                        >
                            {item.LocationName}
                        </option>
                    ))}
                </select>
            </div>
            <button className="btnSearch btn btn-primary" onClick={() => SearchTransaction()}>
                ຄົ້ນຫາ
            </button>
            <button className="btnSearch btn btn-success" onClick={() => handlePrint()}>
                ພິມລາຍງານ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ຍອດລວມ</th>
                <th>ສ່ວນຫລຸດ</th>
                <th>ຍອດທີ່ຕ້ອງຈ່າຍ</th>
                <th>ຍອດຈ່າຍ</th>
                <th>ຈ່າຍເພີ່ມ</th>
                <th>ປະເພດຈ່າຍ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.TransactionID}</td>
                        <td >{x.SubTotal}</td>
                        <td >{x.Discount}</td>
                        <td >{x.NetTotal}</td>
                        <td >{x.PaidAmount}</td>
                        <td >{x.PaidMoreAmount}</td>
                        <td >{x.PaymentType}</td>
                        <td >
                          <button className="btn btn-danger" value={x.TransactionID} type="button" onClick={() => handleShowTransactionDetail(x.TransactionID,)}>ລາຍລະອຽດບິນ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
                    BillDetails={{"StartedDate": StartedDate, "EndedDate": EndedDate , "LocationName": LocationName, "MemberNO": MemberNO}} 
            />
        </div>
      </div>
  );
};

export default MemberTransactionReport;
