import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/member/giftcardValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";

import { GiftCardDataContext } from "../giftCard";

axios.defaults.baseURL = API;

function UpdateGiftCard(props) {

    const {dataList, setDataList, Data } = useContext(GiftCardDataContext);

    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
    };

    async function updateLocation() {
        let token = localStorage.getItem("token");
        var data = {
            GiftCardNO: values.GiftCardNO,
            Price: values.Price,
            StatusID: 1
        }
        await axios.put(`/updateGiftCard/${values.GiftCardID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {

            for (var i=0; i < dataList.length; i++) {
                if (dataList[i].GiftCardID == values.GiftCardID) {
                    dataList[i].GiftCardNO = values.GiftCardNO;
                    dataList[i].Price = values.Price;
                    localStorage.setItem("giftCardData", JSON.stringify(dataList));
                    break;
                }
            }  
            setDataList(dataList);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("GiftCardID", "");
        handleChange("GiftCardNO", "");
        handleChange("Price", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(updateLocation, validate);

    useEffect(() => {
        Data.forEach(value => {
            handleChange("GiftCardID", value.GiftCardID);
            handleChange("GiftCardNO", value.GiftCardNO);
            handleChange("Price", value.Price);
            handleChange("CheckGiftCardNO", "");
        });
    }, [Data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂຂໍ້ມູນບັດ GiftCard</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>GiftCardNO:</span>
                                <input className={`input form-control ${errors.GiftCardNO && 'is-invalid'}`} type="text" name="GiftCardNO"  value={values.GiftCardNO || ''} disabled />
                                {errors.GiftCardNO && (
                                    <p className="invalid-feedback">{errors.GiftCardNO}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>Price:</span>
                                <input className={`input form-control ${errors.Price && 'is-invalid'}`} type="text" name="Price" onChange={(e) => handleInputChange(e)} value={values.Price || ''} required />
                                {errors.Price && (
                                    <p className="invalid-feedback">{errors.Price}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateGiftCard;
