import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/supplierValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { SupplierContext } from "../supplier";

axios.defaults.baseURL = API;

function CreateSupplier(props) {

    const {dataList, setDataList} = useContext(SupplierContext);

    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
    };

    const handleInputSupplierNameChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.SupplierName.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckSupplierName", results[0].SupplierName);
        else
            handleChange("CheckSupplierName", "");
    }

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            SupplierName: values.SupplierName,
            Tell: values.Tell,
            Address: values.Address,
        }
        await axios.post("/createSupplier", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            var obj = {
                SupplierID: response?.data?.insertId,
                SupplierName: values.SupplierName,
                Tell: values.Tell,
                Address: values.Address,
            }
            dataList.push(obj);
            setDataList(dataList);
            localStorage.setItem("supplierData", JSON.stringify(dataList));
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("SupplierName", "");
        handleChange("Tell", "");
        handleChange("Address", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງຜູ້ສະໜອງ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ຊື່ຜູ້ສະໜອງ</span>
                                <input className={`input form-control ${errors.SupplierName && 'is-invalid'}`} type="text" name="SupplierName" onChange={(e) => handleInputSupplierNameChange(e)} value={values.SupplierName || ''} required />
                                {errors.SupplierName && (
                                    <p className="invalid-feedback">{errors.SupplierName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ເບີໂທ</span>
                                <input className={`input form-control ${errors.Tell && 'is-invalid'}`} type="text" name="Tell" onChange={(e) => handleInputChange(e)} value={values.Tell || ''} required />
                                {errors.Tell && (
                                    <p className="invalid-feedback">{errors.Tell}</p>
                                )}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ທີ່ຢູ່</span>
                                <textarea className={`input form-control ${errors.Address && 'is-invalid'}`} type="text" name="Address" onChange={(e) => handleInputChange(e)} value={values.Address || ''} required />
                                {errors.Address && (
                                    <p className="invalid-feedback">{errors.Address}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateSupplier;
