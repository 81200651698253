import React, { useContext, useState } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/product/subMenuValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { subMenuContext } from "../subMenu";

axios.defaults.baseURL = API;

function CreateSubMenu(props) {

    const {MenuList, SubMenuList, setSubMenuList, setShowData, CategoryList, setShowAlert, setErrorMessage} = useContext(subMenuContext);
    const [CategoryData, setCategoryData] = useState([]);
    
    const handleSelectProductType = (e) => {
        const { value } = e.target;
        if(value){
            getCategoryByProductTypeID(value);
            handleChange("ProductTypeID", value);
            handleChange("MenuID", e.target.children[e.target.selectedIndex].getAttribute('data-id'));
        } else {
            handleChange("ProductTypeID", "");
            handleChange("MenuID", "");
            setCategoryData("");
        }
        
    };

    async function getCategoryByProductTypeID(value) {
        let token = localStorage.getItem("token");
        await axios.get(`/getCategoryByProductTypeID/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setCategoryData(temOrder);
          } else {
            setCategoryData([]);
          }
        }).catch((err) => {
            setCategoryData([]);
            console.log(err);
        });
    }

    async function handleSelectCategory(e)  {
        const { value } = e.target;
        if(value){
            handleChange("CategoryID",value);
            let token = localStorage.getItem("token");
            await axios.get(`/checkSubmenuByCategoryID/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let temOrder = response?.data;
            if(temOrder.length > 0){
                handleChange("CheckCategory", value);
            } else {
                handleChange("CheckCategory", "");
            }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            handleChange("CheckCategory","");
            handleChange("CategoryID", "");
        } 
    };

    async function saveTutorial() {
        if(values.CheckCategory == ""){
            let token = localStorage.getItem("token");
            var data = {
                MenuID: values.MenuID,
                ProductTypeID: values.ProductTypeID,
                CategoryID: values.CategoryID,
                StatusID: 1
            }
            await axios.post("/CreateSubMenu", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                getMenu();
                ClearData();
                props.onClick(false);
            }).catch((err) => {
                console.log(err);
            });
        } else {
            setErrorMessage("ໝວດສິນຄ້າແມ່ນມີຢູ່ແລ້ວ");
            setShowAlert(true);
        }
    };

    async function getMenu() {
        axios
          .get("/getsubmenu")
          .then((response) => {
            let Data = response?.data;
            setSubMenuList(Data);
            setShowData(Data);
            let DataString = JSON.stringify(Data);
            localStorage.setItem("subMenuData", DataString);
          })
          .catch((error) => {
            console.log(error);
        });
      }

    const ClearData = () => {
        handleChange("CategoryID", "");
        handleChange("CheckCategory", "");
        handleChange("MenuID", "");
        handleChange("ProductTypeID","");
    }
        
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງ ເມນູຍ່ອຍ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <label>ເມນູ: </label>
                                <select name="MenuID" onChange={(e) => handleSelectProductType(e)}
                                    className={`input form-control ${errors.MenuID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດສິນຄ້າ'}
                                    </option>
                                    {   MenuList && MenuList.map(item => (
                                        <option
                                            key={item.MenuID}
                                            data-id={item.MenuID}
                                            value={item.ProductTypeID}
                                        >
                                            {item.ProductType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ເມນູຍອຍ: </label>
                                <select name="CategoryID" onChange={(e) => handleSelectCategory(e)}
                                    className={`input form-control ${errors.CategoryID && 'is-invalid'}`} disabled={!values.ProductTypeID}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກໝວດສິນຄ້າ'}
                                    </option>
                                    {   CategoryData && CategoryData.map(item => (
                                        <option
                                            key={item.CategoryID}
                                            value={item.CategoryID}
                                        >
                                            {item.Category}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateSubMenu;
