import React, { useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/roleValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { RoleContext } from "../role";

axios.defaults.baseURL = API;

function CreateRole(props) {

    const {RoleList, setRoleList} = useContext(RoleContext);

    const handleInputRoleChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = RoleList.filter((data) => {
            return data.Role.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckRole", results[0].Role);
        else
            handleChange("CheckRole", "");
    }

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            Role: values.Role,
        }
        await axios.post("/createRole", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            var obj = {
                RoleID: response?.data?.insertId,
                Role: values.Role,
            }
            RoleList.push(obj);
            setRoleList(RoleList);
            localStorage.setItem("roleData", JSON.stringify(RoleList));
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => 
        handleChange("Role", "");

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງສິດນຳໃຊ້</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ສິດນຳໃຊ້</span>
                                <input className={`input form-control ${errors.Role && 'is-invalid'}`} type="text" name="Role" onChange={(e) => handleInputRoleChange(e)} value={values.Role || ''} required />
                                {errors.Role && (
                                    <p className="invalid-feedback">{errors.Role}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateRole;
