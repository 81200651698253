import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/tablenumberValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { tableNumberContext } from "../tableNumber";

axios.defaults.baseURL = API;

function UpdateTableNumber(props) {

    const {LocationID, TableNumberList, setTableNumberList, TableNumberData, setShowTableNumber} = useContext(tableNumberContext);

    const handleInputTableNumberChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = TableNumberList.filter((data) => {
            return data.TableNumber.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckTableNumber", results[0].TableNumber);
        else
            handleChange("CheckTableNumber", "");
    }

    async function updateTableNumber() {
        let token = localStorage.getItem("token");
        var data = {
            TableNumber: values.TableNumber, 
            StatusID: 1,
        }
        await axios.put(`/updateTableNumber/${values.TableID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllTableNumber();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllTableNumber() {
        let token = localStorage.getItem("token");
        await axios.get(`/getAlltableNumberForPos/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setTableNumberList(temOrder);
            setShowTableNumber(temOrder);
          } else {
            setTableNumberList([]);
            setShowTableNumber([]);
          }
        }).catch((err) => {
          setTableNumberList([]);
          setShowTableNumber([]);
            console.log(err);
        });
      }

    const ClearData = () => {
        handleChange("TableID", "");
        handleChange("TableNumber", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(updateTableNumber, validate);

    useEffect(() => {
        TableNumberData.forEach(value => {
            handleChange("TableID", value.TableID);
            handleChange("TableNumber", value.TableNumber);
            handleChange("CheckTableNumber", "");
        });
    }, [TableNumberData]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂເລກໂຕະ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ເລກໂຕະ</span>
                                <input className={`input form-control ${errors.TableNumber && 'is-invalid'}`} type="text" name="TableNumber" onChange={(e) => handleInputTableNumberChange(e)} value={values.TableNumber || ''} required />
                                {errors.TableNumber && (
                                    <p className="invalid-feedback">{errors.TableNumber}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateTableNumber;
