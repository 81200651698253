import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/sizeTypeValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { SizeTypeContext } from "../sizeType";

axios.defaults.baseURL = API;

function CreateSizeType(props) {

    const {dataList, setDataList,} = useContext(SizeTypeContext);

    const handleInputRoleChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.SizeType.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckSizeType", results[0].SizeType);
        else
            handleChange("CheckSizeType", "");
    }

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            SizeType: values.SizeType,
        }
        await axios.post("/createSizeType", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            var obj = {
                SizeTypeID: response?.data?.insertId,
                SizeType: values.SizeType,
            }
            dataList.push(obj);
            setDataList(dataList);
            localStorage.setItem("sizeTypeData", JSON.stringify(dataList));
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => 
        handleChange("SizeType", "");

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງ ປະເພດຂະໜາດ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ປະເພດຂະໜາດ</span>
                                <input className={`input form-control ${errors.SizeType && 'is-invalid'}`} type="text" name="SizeType" onChange={(e) => handleInputRoleChange(e)} value={values.SizeType || ''} required />
                                {errors.SizeType && (
                                    <p className="invalid-feedback">{errors.SizeType}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateSizeType;
