import React, { useState, useEffect, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateStatus from "./create/createStatus";
import UpdateStatus from "./update/updateStatus";

const StatusContext = createContext();

function Status() {

  const [StatusList, setStatusList] = useState([]);
  const [ShowStatus, setShowStatus] = useState([]);
  const [StatusData, setStatusData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { name, value } = event.target;
    if(value){
      var results = [];
      results = StatusList.filter((data) => {
          return data.Status.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
      setShowStatus(results);
      else 
      setShowStatus(StatusList);
    } else 
      setShowStatus(StatusList);
  }

  const openUpdate = (StatusID, Status) => {
    var list = [];
    list.push({
      StatusID: StatusID,
      Status: Status
    });
    setStatusData(list);
    setShowUpdate(true);
  };
  
  useEffect(() => {
    let userData = localStorage.getItem("statusData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setStatusList(userRole);
      setShowStatus(userRole);
    }
  }, []);

  return (
    <StatusContext.Provider value={{StatusList, setStatusList, StatusData}}>
      <div className="formContent">
        <CreateStatus show={show} onClick={handleClose}/>
        <UpdateStatus show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ສະຖານະ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ສະຖານະ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                ShowStatus && ShowStatus.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.Status}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.StatusID} type="button" onClick={() => openUpdate(x.StatusID, x.Status)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </StatusContext.Provider>
  );
};

export {StatusContext};
export default Status;
