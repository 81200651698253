import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";
import PurchaseOrderDetail from "./update/PurchaseOrderDetail";
import CreateReceiveProduct from "./create/createReceiveProduct";
import { format } from "date-fns";
axios.defaults.baseURL = API;

const PendingBillContent = createContext();

function StoreOrderBill() {
  function getDate(gdate) {
    var date = new Date(gdate);
    return format(date, "dd-MM-yyyy HH:mm");
  }
  const {LocationID} = useContext(UserContext);
  const [dataList, setDataList] = useState([]);
  const [StorePurchaseOrderDetails, setStorePurchaseOrderDetail] = useState([]);
  const [StoreOrderID, setStoreOrderID] = useState();
  const [EmployeeList, setEmployeeList] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showReceive, setShowReceive] = useState(false);
  const handleCloseReceive = () => setShowReceive(false);
  const handleShowReceive = () => setShowReceive(true);

  async function getPendingBill() {
    var StatusID = 5;
    await axios.get(`/getStorePurchaseOrderBill/${LocationID}/${StatusID}`).then((response) => {
        let Data = response?.data;
        setDataList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function openProductDetail(StoreOrderID) {
    axios
      .get(`/getStorePurchaseOrderProduct/${StoreOrderID}`)
      .then((response) => {
        let Data = response?.data;
        setStorePurchaseOrderDetail(Data);
        handleShow();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function createReceiveProduct(StoreOrderID) {
    axios
      .get(`/getStorePurchaseOrderProduct/${StoreOrderID}`)
      .then((response) => {
        let Data = response?.data;
        setStorePurchaseOrderDetail(Data);
        setStoreOrderID(StoreOrderID)
        handleShowReceive();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getUserByLocationID() {
    let token = localStorage.getItem("token");
    await axios.get(`/getUserByLocationID/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      let temOrder = response?.data;
      console.log(temOrder)
      if(temOrder.length > 0){
        setEmployeeList(temOrder);
      } else {
        setEmployeeList([]);
      }
    }).catch((err) => {
        console.log(err);
    });
}

  useEffect(() => {
    getUserByLocationID();
    getPendingBill();
  }, []);

  return (
    <PendingBillContent.Provider value={{ setDataList, StorePurchaseOrderDetails, EmployeeList, StoreOrderID}}>
      <div className="formContent">
        <PurchaseOrderDetail show={show} onClick={handleClose}/>
        <CreateReceiveProduct show={showReceive} onClick={handleCloseReceive}/>
        <div className="Header">
          <div className="title">
              <span>ບິນລໍຖ້າຮັບເຄື່ອງ</span>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ມື້ສ້າງ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                dataList && dataList.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.StoreOrderID}</td>
                        <td >{getDate(x.CreatedDate)}</td>
                        <td >
                          <button className="btn btn-warning" type="button" onClick={() => openProductDetail(x.StoreOrderID)}>ລາຍລະອຽດ</button>
                          &nbsp;&nbsp;&nbsp;
                          <button className="btn btn-primary" type="button" onClick={() => createReceiveProduct(x.StoreOrderID)}>ຮັບເຄື່ອງເຂົ້າສາງ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </PendingBillContent.Provider>
  );
};

export {PendingBillContent};
export default StoreOrderBill;
