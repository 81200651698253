import React, { useState, useEffect, useContext, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateGiftCard from "./create/createGiftCard";
import UpdateGiftCard from "./update/updateGiftCard";
import { UserContext } from "../../../App";

const GiftCardDataContext = createContext();

function GiftCard() {

  const {userID} = useContext(UserContext);


  const [dataList, setDataList] = useState([]);
  const [ShowData, setShowData] = useState([]);
  const [Data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { name, value } = event.target;
    if(value){
      var results = [];
      results = dataList.filter((data) => {
          return data.GiftCardNO.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
        setShowData(results);
      else 
        setShowData(dataList);
    } else 
      setShowData(dataList);
  }

  const openUpdate = (GiftCardID, GiftCardNO, Price) => {
    var list = [];
    list.push({
      GiftCardID: GiftCardID,
      GiftCardNO: GiftCardNO,
      Price: Price,
    });
    setData(list);
    setShowUpdate(true);
  };
  
  useEffect(() => {
    let GiftCardData = localStorage.getItem("giftCardData");
    if (GiftCardData) {
      let GiftCard = JSON.parse(GiftCardData);
      setDataList(GiftCard);
      setShowData(GiftCard);
    }
  }, []);

  return (
    <GiftCardDataContext.Provider value={{dataList, setDataList, userID, Data, setData}}>
      <div className="formContent">
        <CreateGiftCard show={show} onClick={handleClose}/>
        <UpdateGiftCard show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນບັດ GiftCardNO</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>GiftCardNO</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                ShowData && ShowData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y+1}</td>
                        <td >{x.GiftCardNO}</td>
                        <td >{x.Price}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.GiftCardID} type="button" onClick={() => openUpdate(x.GiftCardID, x.GiftCardNO, x.Price)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </GiftCardDataContext.Provider>
  );
};

export {GiftCardDataContext};
export default GiftCard;
