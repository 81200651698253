import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/pos/paymentTypeValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { PaymentTypeContext } from "../EditBillPayment";

axios.defaults.baseURL = API;

function UpdatePaymentType(props) {

    const {PaymentList, data, setShowData} = useContext(PaymentTypeContext);


    const handleSelectPaymentType = (e) => {
        var data = PaymentList.filter(function (item) {
            return item.PaymentType == e.target.value
        })
        if (data.length > 0) {
            handleChange("PaymentType", data[0].PaymentType);
            handleChange("PaymentTypeID", data[0].PaymentTypeID);
        } else {
            handleChange("PaymentType", "");
            handleChange("PaymentTypeID", "");
        }
    };

    async function update () {
        let token = localStorage.getItem("token");
        await axios.put(`/EditPaymenTypeBill/${values.TransactionID}/${values.PaymentTypeID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            SearchTransaction();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
      }

      async function SearchTransaction() {
          await axios.get(`/SearchTransaction/${values.TransactionID}`).then((response) => {
            let Data = response?.data;
            setShowData(Data);
          }).catch((error) => {
            console.log(error);
          });
      };

    const ClearData = () => {
        handleChange("TransactionID", "");
        handleChange("PaymentTypeID", "");
        handleChange("PaymentType", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(update, validate);

    useEffect(() => {
        data.forEach(value => {
            handleChange("TransactionID", value.TransactionID);
            handleChange("PaymentTypeID", value.PaymentTypeID);
            handleChange("PaymentType", value.PaymentType);
        });
    }, [data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂປະເພດຈ່າຍ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ປະເພດຈ່າຍ</span>
                                <select name="PaymentTypeID" onChange={(e) => handleSelectPaymentType(e)}
                                    className={`input form-control ${errors.PaymentTypeID && 'is-invalid'}`}>
                                    { PaymentList && PaymentList.map((x, y) => {
                                        if (x.PaymentType == values.PaymentType) {
                                            return (
                                                <option key={x.PaymentTypeID} value={x.PaymentType} selected>
                                                    {x.PaymentType}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.PaymentTypeID} value={x.PaymentType}>
                                                    {x.PaymentType}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdatePaymentType;
