import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/stock/receiveProductValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { PendingBillContent } from "../store_orderBill";
import { UserContext } from "../../../../App";

axios.defaults.baseURL = API;

function CreateReceiveProduct(props) {
    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
    }

    const {StorePurchaseOrderDetails, EmployeeList, StoreOrderID, setDataList} = useContext(PendingBillContent);
    const {userID, LocationID} = useContext(UserContext);

    const handleSelectEmployee = (e) => {
        var data = EmployeeList.filter(function (item) {
            return item.Name == e.target.value
        })
        if (data.length > 0) {
            handleChange("Name", data[0].Name);
            handleChange("EmployeeID", data[0].EmployeeID);
        } else {
            handleChange("Name", "");
            handleChange("EmployeeID", "");
        }
    };

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            EmployeeID: values.EmployeeID,
            StoreOrderID: StoreOrderID,
            CreatedDate: getDate(),
            CreatedByID: userID,
            LocationID: LocationID,
        }
        await axios.post("/CreateStoreReceiveProduct", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            CreateInventory();
            UpdatePurchaseOrderStatus(StoreOrderID);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function CreateInventory() {
        if(StorePurchaseOrderDetails.length > 0) {
            for (var i=0; i < StorePurchaseOrderDetails.length; i++) {
                var list;
                list ={
                    ProductID: StorePurchaseOrderDetails[i].ProductID,
                    Quantity: StorePurchaseOrderDetails[i].Quantity,
                    LocationID: LocationID,
                };
                await axios.get(`/getStoreInventory/${LocationID}/${StorePurchaseOrderDetails[i].ProductID}`).then((response) => {
                    let Data = response?.data;
                    if(Data.length > 0){
                        var qty = Number(StorePurchaseOrderDetails[i].Quantity) + Number(Data[0].Quantity);
                        UpdateInventory(Data[0].StoreInventoryID, qty);
                    } else {
                        CreateNewInventory(list);
                    }
                })
                  .catch((error) => {
                    console.log(error);
                });
            } 
        }
    }

    async function UpdateInventory(StoreInventoryID, qty) {
        let token = localStorage.getItem("token");
        var data = {
            Quantity: qty
        }
        await axios.put(`/updateStoreInventory/${StoreInventoryID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    }

    async function CreateNewInventory(list) {
        let token = localStorage.getItem("token");
        await axios.post("/CreateStoreInventory", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    }

    async function UpdatePurchaseOrderStatus(StoreOrderID) {
        let token = localStorage.getItem("token");
        var data = {
          StatusID: 6,
        }
        await axios.put(`/updateStorePurchaseOrderStatus/${StoreOrderID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          getPendingBill();
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getPendingBill() {
        var StatusID = 5;
        await axios.get(`/getStorePurchaseOrderBill/${LocationID}/${StatusID}`).then((response) => {
            let Data = response?.data;
            setDataList(Data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

    const ClearData = () => {
        handleChange("Name", "");
        handleChange("EmployeeID", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງໃບສັ່ງຊື້</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <label>ພະນັກງານ: </label>
                                <select name="EmployeeID" onChange={(e) => handleSelectEmployee(e)}
                                    className={`input form-control ${errors.EmployeeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກພະນັກງານ'}
                                    </option>
                                    {   EmployeeList && EmployeeList.map(item => (
                                        <option
                                            key={item.EmployeeID}
                                            value={item.Name}
                                        >
                                            {item.Name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateReceiveProduct;
