import React, { useContext, useEffect } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/stock/createPurchaseOrderValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { PendingBillContent } from "../store_pendingBill";

axios.defaults.baseURL = API;

function UpdatePurchaseOrder(props) {

    const {dataList, setDataList, data, SupplierList, PaymentTypeList} = useContext(PendingBillContent);

    const handleSelectSuppler = (e) => {
        var data = SupplierList.filter(function (item) {
            return item.SupplierName == e.target.value
        })
        if (data.length > 0) {
            handleChange("SupplierName", data[0].SupplierName);
            handleChange("SupplierID", data[0].SupplierID);
        } else {
            handleChange("SupplierName", "");
            handleChange("SupplierID", "");
        }
    };

    const handleSelectPaymentType = (e) => {
        var data = PaymentTypeList.filter(function (item) {
            return item.PaymentType == e.target.value
        })
        if (data.length > 0) {
            handleChange("PaymentType", data[0].PaymentType);
            handleChange("PaymentTypeID", data[0].PaymentTypeID);
        } else {
            handleChange("PaymentType", "");
            handleChange("PaymentTypeID", "");
        }
    };

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            PaymentTypeID: values.PaymentTypeID,
            SupplierID: values.SupplierID,
        }
        await axios.put(`/updatePurchaseOrder/${values.PurchaseOrderID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            for (var i=0; i < dataList.length; i++) {
                if (dataList[i].PurchaseOrderID == values.PurchaseOrderID) {
                    dataList[i].PaymentTypeID = values.PaymentTypeID;
                    dataList[i].PaymentType = values.PaymentType;
                    dataList[i].SupplierID = values.SupplierID;
                    dataList[i].SupplierName = values.SupplierName;
                    break;
                }
            }  
            setDataList(dataList);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("PaymentType", "");
        handleChange("PaymentTypeID", "");
        handleChange("SupplierName", "");
        handleChange("SupplierID", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    useEffect(() => {
        data.forEach(value => {
            handleChange("PurchaseOrderID", value.PurchaseOrderID);
            handleChange("SupplierID", value.SupplierID);
            handleChange("PaymentTypeID", value.PaymentTypeID);
        });
    }, [data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງໃບສັ່ງຊື້</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <label>ຜູ້ສະໜອງ: </label>
                                <select name="SupplierID" onChange={(e) => handleSelectSuppler(e)}
                                    className={`input form-control ${errors.SupplierID && 'is-invalid'}`}>
                                    { SupplierList && SupplierList.map((x, y) => {
                                        if (x.SupplierID == values.SupplierID) {
                                            return (
                                                <option key={x.SupplierID} value={x.SupplierName} selected>
                                                    {x.SupplierName}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.SupplierID} value={x.SupplierName}>
                                                    {x.SupplierName}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ປະເພດຈ່າຍ: </label>
                                <select name="PaymentTypeID" onChange={(e) => handleSelectPaymentType(e)}
                                    className={`input form-control ${errors.PaymentTypeID && 'is-invalid'}`}>
                                    { PaymentTypeList && PaymentTypeList.map((x, y) => {
                                        if (x.PaymentTypeID == values.PaymentTypeID) {
                                            return (
                                                <option key={x.PaymentTypeID} value={x.PaymentType} selected>
                                                    {x.PaymentType}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.PaymentTypeID} value={x.PaymentType}>
                                                    {x.PaymentType}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdatePurchaseOrder;
