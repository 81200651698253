
function SubMenuValidate(values) {

    let errors = {};
    
    if (!values.CategoryID)
        errors.CategoryID = 'CategoryID is required';

    if (!values.MenuID)
        errors.MenuID = 'MenuID is required';

    
    return errors;
};

export default SubMenuValidate;
