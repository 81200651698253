function UploadImageSchema(values) {
    let errors = {};

    if (!values.ProductID)
        errors.ProductID = 'ProductID is required';

    if (!values.Image)
        errors.Image = 'Image is required';

    return errors;
};

export default UploadImageSchema;
