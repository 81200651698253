import {useContext} from 'react';
import { ProgressBarContext } from '../App';
import '../css/ProgressBar.css';
const ProgressBar = ({bgcolor,progress,height}) => {
    const {ShowProgressBar, Progress} = useContext(ProgressBarContext);
    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: 'whitesmoke',
        borderRadius: 40,
        marginTop: 20
      }
      
      const Childdiv = {
        height: '100%',
        width: `${Progress}%`,
        backgroundColor: bgcolor,
       borderRadius:40,
        textAlign: 'right'
      }
      
      const progresstext = {
        padding: 10,
        color: 'black',
        fontWeight: 900
      }
    return (
        <div style={{ display: ShowProgressBar ? 'flex' : 'none' }} className="fromProgressBar">
            <div className="formProgressBarContent">
                <div className="alert-header">
                    <span>Uploading...</span>
                </div>
                <div className="progressBody">
                    <div style={Parentdiv}>
                        <div style={Childdiv}>
                            <span style={progresstext}>{`${Progress}%`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProgressBar;
