import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/roleValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { RoleContext } from "../role";

axios.defaults.baseURL = API;

function UpdateRole(props) {

    const {RoleList, setRoleList, RoleData} = useContext(RoleContext);

    const handleInputRoleChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = RoleList.filter((data) => {
            return data.Role.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckRole", results[0].Role);
        else
            handleChange("CheckRole", "");
    }

    async function updateRole() {
        let token = localStorage.getItem("token");
        var data = {
            Role: values.Role
        }
        await axios.put(`/updateRole/${values.RoleID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {

            for (var i=0; i < RoleList.length; i++) {
                if (RoleList[i].RoleID == values.RoleID) {
                    RoleList[i].Role = values.Role;
                    localStorage.setItem("roleData", JSON.stringify(RoleList));
                    break;
                }
            }  
            setRoleList(RoleList);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("RoleID", "");
        handleChange("Role", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(updateRole, validate);

    useEffect(() => {
        RoleData.forEach(value => {
            handleChange("RoleID", value.RoleID);
            handleChange("Role", value.Role);
            handleChange("CheckRole", "");
        });
    }, [RoleData]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂສິດນຳໃຊ້</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ຊື່ສາຂາ</span>
                                <input className={`input form-control ${errors.Role && 'is-invalid'}`} type="text" name="Role" onChange={(e) => handleInputRoleChange(e)} value={values.Role || ''} required />
                                {errors.Role && (
                                    <p className="invalid-feedback">{errors.Role}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateRole;
