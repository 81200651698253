
function roleValidate(values) {

    let errors = {};
    if (!values.Role)
        errors.Role = 'Role is required';
    else if (values.CheckRole != "")
        errors.Role = 'Role Already Exit';

    return errors;
};

export default roleValidate;
