import React from "react";
import useForm from "../services/UseForm";
import validate from '../validations/admin/loginValidate';
import logo from '../img/logo/ManeeCafeLogo.jpg';
import '../css/login.css';
import { API } from "../services/api";
import axios from "axios";
axios.defaults.baseURL = API;

export default function Login({setLogin, setUserID, setLocationID, setLocationName, setUserName, setRole}) {
  

  const handleInputChange = event => {
    const { name, value } = event.target;
    handleChange(name, value)
  };

  const handleLogin = () => {
    let data = { UserName: values.UserName, Password: values.Password };
    axios
      .post("/login-user", data).then((response) => {
        let userData = response?.data?.data;
        let token = response?.data?.token;
        let userDataString = JSON.stringify(userData);
        localStorage.setItem("data", userDataString);
        localStorage.setItem("token", token);
        setUserID(userData.EmployeeID);
        setLocationID(userData.LocationID);
        setLocationName(userData.LocationName);
        setUserName(userData.Name);
        setLogin(true);
        setRole(userData.Role);
        getAllPaymentType();
        if(userData.LocationName === "BIC")
        {
          getLocationForBIC();
        } else {
          getAllLocation();
        }
        getAllStatus();
        getAllUser();
        getAllRole();
      })
      .catch((error) => {
        console.log(error);
      });
  };


  async function getAllPaymentType() {
    axios
      .get("/getAllPaymentType")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("paymentTypeData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllLocation() {
    axios
      .get("/getAllLocation")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("locationData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getLocationForBIC() {
    axios
      .get("/getLocationForBic")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("locationData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllStatus() {
    axios
      .get("/getAllStatus")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("statusData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllUser() {
    axios
      .get("/getUsers")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("userData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllRole() {
    axios
      .get("/getAllRole")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("roleData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(handleLogin, validate);

  return (
    <div className="LoginContainer">
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
      <section>
      <div className="container">
          <div className="form-container sign-in-container">
              <form onSubmit={handleSubmit} noValidate>
                  <h1>Sign in</h1>
                  <label>
                    <input autoComplete="off" placeholder="User Name" className={`input form-control ${errors.UserName && 'is-invalid'}`} name="UserName" onChange={(e) => handleInputChange(e)} value={values.UserName || ''} required />
                    {errors.UserName && (<p className="invalid-feedback" >{errors.UserName}</p>)}
                  </label>
                  <label>
                      <input className={`input form-control ${errors.Password && 'is-invalid'}`} placeholder="Password" type="Password" name="Password" onChange={(e) => handleInputChange(e)} value={values.Password || ''} required />
                      {errors.Password && (
                          <p className="invalid-feedback">{errors.Password}</p>
                      )}
                  </label>
                  <button type="submit" value="Login">Sign In</button>
              </form>
          </div>
          <div className="overlay-container">
              <div className="overlay">
                  <div className="overlay-panel overlay-right">
                      <h1>Manee Cafe</h1>
                      <p>Healthy tasty</p>
                  </div>
              </div>
          </div>
      </div>
    </section>
    </div>
  );
}

