
function CurrencyValidate(values) {

    let errors = {};
    if (!values.Currency)
        errors.Currency = 'Currency is required';
    else if (values.CheckCurrency !== "")
        errors.Currency = 'Currency Already Exit';

    return errors;
};

export default CurrencyValidate;
